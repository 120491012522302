import React from 'react';

const IconMedium = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="17"
      viewBox="0 0 21 17">
      <path
        d="M2.488 3.402a.788.788 0 0 0-.262-.682L.263.357V0h6.09l4.703 10.322L15.194 0H21v.357l-1.68 1.606a.499.499
        0 0 0-.179.473v11.802a.499.499 0 0 0 .179.473l1.638
        1.606v.357h-8.232v-.357l1.69-1.649c.168-.157.168-.21.168-.461V4.662L9.87 16.632h-.63L3.748
        4.662v8.022c-.073.336.064.672.305.903l2.205 2.698v.347H0v-.347l2.205-2.698a.988.988 0 0 0 .283-.903V3.402z"
      />
    </svg>
  );
};

export default IconMedium;
