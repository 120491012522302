import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Overlay = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path fill="#13293F" fillOpacity=".03" d="M61,24 L243,24 C251.284271,24 258,30.7157288 258,
        39 L258,155 C258,163.284271 251.284271,170 243,170 L61,170 C52.7157288,170 46,163.284271 46,155 L46,
        39 C46,30.7157288 52.7157288,24 61,24 Z"/>
      <rect width="200" height="132" x="52" y="30" fill="#13293F" fillOpacity=".03" rx="12"/>
      <rect width="192" height="124" x="56" y="34" className={styles.whiteBlack} fill="currentColor" rx="8"/>
    </g>
  );
};

export default Overlay;
