import React from 'react';
import {
  DataTable,
  DataTableHeader,
  DataTableBody,
  DataTableRow,
  DataTableCodeBlock,
  DataTableValueBlock,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Icon } from 'hudl-uniform-ui-components';

const IconDataTableCode = ({ data, platform }) => {
  return (
    <div>
      {data.allIconsYaml.edges.map(list => {
        return (
          <Section key={list.node.title}>
            <SectionSubhead>{list.node.title}</SectionSubhead>
            <DataTable>
              <DataTableHeader>
                <DataTableRow layout="halves">
                  <th>Icon</th>
                  <th>Variable</th>
                </DataTableRow>
              </DataTableHeader>
              <DataTableBody>
                {list.node.icons.map(item => {
                  let platformData;
                  switch (platform) {
                    case 'web':
                      platformData = item.react_name;
                      break;
                    case 'reactnative':
                      platformData = item.rn_name;
                      break;
                    case 'android':
                      platformData = item.android_name;
                      break;
                    case 'apple':
                      platformData = item.apple_name;
                      break;
                    default:
                      platformData = item.react_name;
                  }

                  if (
                    item.react_name === 'uiexpandregionvertical' ||
                    item.react_name === 'uicollapseregionvertical'
                  ) {
                    return null;
                  }

                  return (
                    <DataTableRow layout="halves" key={item.react_name}>
                      <DataTableValueBlock>
                        <Icon type={item.react_name} size="medium" />
                      </DataTableValueBlock>
                      <DataTableCodeBlock>{platformData}</DataTableCodeBlock>
                    </DataTableRow>
                  );
                })}
              </DataTableBody>
            </DataTable>
          </Section>
        );
      })}
    </div>
  );
};

export default IconDataTableCode;
