import React from 'react';
import { Link } from 'gatsby';
import subNavstyles from './SubNav.module.scss';

const SubNavVersion = () => {
  return (
    <Link className={subNavstyles.versionLink} to="/release-notes">
      February 2020 Release
    </Link>
  );
};

export default SubNavVersion;
