import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSashMedium = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M72.5349127,116.780565 L231.998403,24.7142759 M231.998403,29.3359567 L72.5349127,121.402246
      M72.5349127,126.022489 L231.998403,33.9561993 M231.998403,38.57788 L72.5349127,130.644169 M72.5349127,135.264412
      L231.998403,43.1981226 M231.998403,47.8198034 L72.5349127,139.886093 M72.5349127,144.506335
      L231.998403,52.4400459 M231.998403,57.0617267 L72.5349127,149.128016 M72.5349127,153.748258
      L231.998403,61.6819692 M231.998403,66.30365 L72.5349127,158.369939 M72.5349127,162.990182 L231.998403,70.9238926
      M231.998403,75.3480417 L195.365917,96.6953494 L72.5349127,167.611863"
    />
  );
};

export default HatchSashMedium;
