import React from 'react';
import styles from './ReleaseNotes.module.scss';

const Added = ({ children }) => {
  return (
    <div className={styles.listItemWrapper}>
      <div className={styles.listItem + ' ' + styles.listItemAdded}>ADDED</div>
      <div className="uni-text">{children}</div>
    </div>
  );
};

export default Added;
