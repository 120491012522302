import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchTriangle = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M153.986671,9.68258831 L148.274277,12.9806404 M144.772621,19.622565 L155.85408,13.2246816
      M157.722071,16.7678775 L141.269875,26.266557 M137.768219,32.9084815 L159.58948,20.3099708 M161.457471,23.8531667
      L134.265473,39.5524735 M130.763817,46.194398 L163.32488,27.3952599 M165.19287,30.9384558 L127.261072,52.8383901
      M123.759416,59.4803146 L167.06028,34.4805491 M168.92827,38.023745 L120.25667,66.1243066 M116.755014,72.7662311
      L170.79568,41.5658383 M172.66367,45.1090342 L113.252268,79.4102232 M109.750612,86.0521477 L174.53108,48.6511275
      M176.39907,52.1943233 L106.247866,92.6961397 M102.746211,99.3380642 L178.26648,55.7364166 M180.13447,59.2796125
      L99.2434647,105.982056 M95.7418089,112.623981 L182.001879,62.8217058 M183.86987,66.3649017 L92.239063,119.267973
      M88.7374071,125.909897 L185.737279,69.906995 M187.60527,73.4501908 L85.2346613,132.553889 M81.7330054,139.195814
      L189.472679,76.9922841 M191.34067,80.53548 L78.2302595,145.839806 M74.7286037,152.48173 L193.208079,84.0775733
      M195.07607,87.6207692 L71.2258578,159.125722 M67.724202,165.767647 L196.943479,91.1628625 M198.81147,94.7060584
      L71.862639,168 M79.8651338,168 L200.678879,98.2481516 M202.54687,101.791348 L87.8701197,168 M95.8726146,168
      L204.414279,105.333441 M206.28227,108.876637 L103.8776,168 M111.880095,168 L208.149679,112.41873
      M210.01767,115.961926 L119.885081,168 M127.887576,168 L211.885079,119.504019 M213.753069,123.047215
      L135.892562,168 M143.895057,168 L215.620479,126.589308 M217.488469,130.132504 L151.900043,168 M159.902538,168
      L219.355879,133.674598 M221.223869,137.217793 L167.907524,168 M175.910018,168 L223.091279,140.759887
      M224.959269,144.303083 L183.915004,168 M191.917499,168 L226.826679,147.845176 M228.694669,151.388372
      L199.922485,168 M207.92498,168 L230.562079,154.930465 M232.430069,158.473661 L215.929966,168 M223.932461,168
      L234.297478,162.015754 M236.165469,165.55895 L231.937447,168"
    />
  );
};

export default HatchTriangle;
