import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSashRight = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M140,111.516366 L266.533316,38.4623218 M266.533316,43.0840026 L140,116.138047 M140,120.758289
      L266.533316,47.7042452 M266.533316,52.3259259 L140,125.37997 M140,130.000213 L266.533316,56.9461685
      M266.533316,61.5678493 L140,134.621893 M140,139.242136 L266.533316,66.1880918 M266.533316,70.8097726
      L140,143.863817 M266.533316,75.4300151 L140,148.274199"
    />
  );
};

export default HatchSashRight;
