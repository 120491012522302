import React from 'react';
import {
  Text,
  FormModifier,
  RadioGroup,
  Radio,
  Environment,
  Level,
} from 'hudl-uniform-ui-components';
import styles from './ComponentPreview.module.scss';

export default class ComponentPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      env: 'light',
      propValue: '',
      footerTitle: '',
      footerText: '',
      passsedProps: '',
    };
  }

  componentWillMount() {
    this.setState({ env: 'light' });
    if (this.props.previewData) {
      const firstItem = this.props.previewData[0];
      this.setState({ propValue: firstItem.value });
      this.setState({
        footerTitle: firstItem.footerTitle
          ? firstItem.footerTitle
          : firstItem.label,
      });
      this.setState({ footerText: firstItem.footerText });
      this.setState({ passedProps: firstItem.propsToChange });
    }
  }

  handleRadioClick(selectedValue) {
    this.setState({ propValue: selectedValue });

    const selectedItem = this.props.previewData.find(
      item => item.value === selectedValue
    );
    this.setState({
      footerTitle: selectedItem.footerTitle
        ? selectedItem.footerTitle
        : selectedItem.label,
    });
    this.setState({ footerText: selectedItem.footerText });
    this.setState({ passedProps: selectedItem.propsToChange });

    if (this.props.onChange) {
      this.props.onChange(selectedValue);
    }
  }

  handleEnvironmentSwitch() {
    this.setState({ env: this.state.env === 'light' ? 'dark' : 'light' });
  }

  createComponent(component, passedProps) {
    if (!component) return null;

    let componentItem;
    if (component.props.ignoreThisComponent) {
      componentItem = component;
    } else {
      componentItem = (
        <div className={styles.previewItemWrapper}>
          {React.cloneElement(component, passedProps)}
        </div>
      );
    }

    return componentItem;
  }

  previewClassName() {
    if (this.props.layout === 'split') {
      return styles.split;
    } else if (this.props.layout === 'row') {
      return styles.row;
    } else if (this.props.layout === 'halfWidth') {
      return styles.halfWidth;
    } else if (this.props.layout === 'fullWidth') {
      return styles.fullWidth;
    } else if (this.props.layout === 'fullBleed') {
      return styles.fullBleed;
    }

    return styles.default;
  }

  render() {
    const {
      name,
      previewData,
      footerTitle,
      footerText,
      hideEnvironmentToggle,
      children,
    } = this.props;

    const { env, propValue, passedProps } = this.state;

    return (
      <div className={styles.container}>
        {previewData ? (
          <div className={styles.header}>
            <FormModifier size="small">
              <RadioGroup
                name={name}
                hideHeader
                valueChecked={propValue}
                onChange={this.handleRadioClick.bind(this)}>
                {previewData.map((item, key) => (
                  <Radio {...item} key={key} />
                ))}
              </RadioGroup>
            </FormModifier>

            {!hideEnvironmentToggle && (
              <div
                className={styles.environmentSelector}
                title="Switch Environment"
                name="previewEnvironmentToggle"
                onClick={this.handleEnvironmentSwitch.bind(this)}>
                <Environment env={env}>
                  <Level level="0">
                    <div className={styles.environmentSelectorButton} />
                  </Level>
                </Environment>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.envHeader}>
            <div
              className={styles.environmentSelector}
              title="Switch Environment"
              name="previewEnvironmentToggle"
              onClick={this.handleEnvironmentSwitch.bind(this)}>
              <Environment env={env}>
                <Level level="0">
                  <div className={styles.environmentSelectorButton} />
                </Level>
              </Environment>
            </div>
          </div>
        )}

        <div className={styles.preview}>
          <Environment env={env}>
            <Level level="0">
              <div className={this.previewClassName()}>
                {previewData
                  ? React.Children.map(children, child => {
                      return this.createComponent(child, passedProps);
                    })
                  : children}
              </div>
            </Level>
          </Environment>
        </div>

        <div className={styles.footer}>
          {(footerText || this.state.footerText) && (
            <Text level="small" className="uni-pad--one--top">
              <strong>
                {footerTitle ? footerTitle : this.state.footerTitle}:{' '}
              </strong>
              {footerText ? footerText : this.state.footerText}
            </Text>
          )}
        </div>
      </div>
    );
  }
}
