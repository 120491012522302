import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const SingleSelect = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral3}
        width="202"
        height="122"
        x="51"
        y="36"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.level0}
        width="198"
        height="118"
        x="53"
        y="38"
        fill="currentColor"
        rx="10"
      />
      <rect
        className={styles.neutral2}
        width="118"
        height="10"
        x="63"
        y="50"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral3}
        width="88"
        height="10"
        x="63"
        y="74"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral3}
        width="138"
        height="10"
        x="63"
        y="94"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral3}
        width="108"
        height="10"
        x="63"
        y="114"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral3}
        width="98"
        height="10"
        x="63"
        y="134"
        fill="currentColor"
        rx="5"
      />
      <path
        className={styles.neutral5}
        fill="currentColor"
        d="M233,54.9061637 L237.720663,50.3753453 C238.242092,49.8748849 239.087498,49.8748849 239.608928,50.3753453
        C240.130357,50.8758057 240.130357,51.6872123 239.608928,52.1876726 L233.944133,57.6246547
        C233.422703,58.1251151 232.577297,58.1251151 232.055867,57.6246547 L226.391072,52.1876726
        C225.869643,51.6872123 225.869643,50.8758057 226.391072,50.3753453 C226.912502,49.8748849 227.757908,49.8748849
        228.279337,50.3753453 L233,54.9061637 Z"
      />
    </g>
  );
};

export default SingleSelect;
