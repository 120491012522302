import React from 'react';

const GroupAvatar = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <circle cx="152" cy="96" r="64" fill="#009CE3" />
      <circle
        cx="152"
        cy="96"
        r="62.5"
        stroke="#E6F2FF"
        strokeOpacity=".06"
        strokeWidth="3"
      />
      <rect
        width="51.2"
        height="80"
        x="126.4"
        y="56"
        fill="#80CDF1"
        rx="25.6"
      />
    </g>
  );
};

export default GroupAvatar;
