import React from 'react';
import { copyTextToClipboard } from '../../utility/js/clipboard';
import styles from './DataTable.module.scss';

const DataTableCodeBlock = ({ children }) => {
  const handleOnClick = e => {
    const el = e.currentTarget;
    copyTextToClipboard(children, el);

    const oldInnerHTML = el.innerHTML.replace(' - Copied', '');
    el.innerHTML = oldInnerHTML + ' - Copied';
    el.classList.add(styles.dataTableCodeBlockCopied);

    setTimeout(() => {
      el.innerHTML = oldInnerHTML;
      el.classList.remove(styles.dataTableCodeBlockCopied);
    }, 2500);
  };

  return (
    <td className={styles.dataTableCodeBlock} onClick={handleOnClick}>
      <code>{children}</code>
    </td>
  );
};

export default DataTableCodeBlock;
