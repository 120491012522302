import React from 'react';
import classNames from 'classnames';
import { doesHashMatchName } from '../../utility/js/location';
import { CopyUrlButton } from 'docComponents';
import { Headline } from 'hudl-uniform-ui-components';
import styles from './SectionHeadline.module.scss';

const SectionHeadline = ({ children, url }) => {
  const headerClasses = classNames('section-headline', styles.sectionHeadline, {
    [styles.sectionHeadlineActivate]: doesHashMatchName(url),
  });

  return (
    <div className={styles.wrapper}>
      <div className={headerClasses}>
        <Headline level="2" className="uni-margin--one--btm">
          {children}
        </Headline>
        <CopyUrlButton url={url} />
      </div>
    </div>
  );
};

export default SectionHeadline;
