import React from 'react';
import { Link } from 'gatsby';
import {
  DesignCodeLink,
  LocationProvider,
  ShowHideComponent,
  SubNavTrigger,
} from 'docComponents';
import styles from './SubNav.module.scss';

const createSubnavRoute = (tier, base, path, tierTwo) => {
  let subnavRoute = '';

  if (tier === 'tierTwo') {
    // uniform.hudl.com/components/icon/
    subnavRoute = `/${base}/${path}`;
  } else if (tier === 'tierThree' && path === '__index__') {
    // uniform.hudl.com/components/buttons(overview)
    subnavRoute = `/${base}/${tierTwo}`;
  } else {
    // uniform.hudl.com/components/buttons/button
    subnavRoute = `/${base}/${tierTwo}/${path}`;
  }

  return subnavRoute;
};

const getLinkComp = designCode => (designCode ? DesignCodeLink : Link);

const SubNavSection = ({ navData, baseRoute }) => {
  const TierTwoLinkComp = getLinkComp(navData.designCode);

  const addCloseClass = (toggle, opened, e) => {
    const el = e.currentTarget;

    if (opened) {
      el.nextSibling.addEventListener('webkitAnimationEnd', () => {
        toggle();
        el.classList.remove(styles.subNavTierThreeItems_close);
        el.classList.remove(styles.subNavTierThreeItems_open);
      });
      el.nextSibling.addEventListener('animationend', () => {
        toggle();
        el.classList.remove(styles.subNavTierThreeItems_close);
        el.classList.remove(styles.subNavTierThreeItems_open);
      });

      el.classList.add(styles.subNavTierThreeItems_close);
    } else {
      toggle();
      el.classList.add(styles.subNavTierThreeItems_open);
    }
  };

  return (
    <LocationProvider>
      {location => {
        const tierThreeDefaultOpen =
          location && location.pathname.indexOf(navData.path) > -1;

        return (
          <ShowHideComponent defaultOpen={tierThreeDefaultOpen}>
            {({ isOpen, toggle }) => {
              return (
                <div className={styles.subNavSection}>
                  {navData.tierThree ? (
                    <SubNavTrigger
                      triggerText={navData.name}
                      onClick={addCloseClass.bind(this, toggle, isOpen)}
                      isOpen={isOpen}
                    />
                  ) : (
                      <TierTwoLinkComp
                        to={createSubnavRoute('tierTwo', baseRoute, navData.path)}
                        className={styles.subNavTierTwo}
                        activeClassName={styles.subNavLinkActive}>
                        {navData.name}
                      </TierTwoLinkComp>
                    )}

                  {navData.tierThree &&
                    isOpen && (
                      <nav
                        className={
                          'subnav__tierThreeItems ' +
                          styles.subNavTierThreeItems
                        }>
                        {navData.tierThree.map((tierThree, i) => {
                          const TierThreeLinkComp = getLinkComp(
                            tierThree.designCode
                          );
                          const tierThreePath = createSubnavRoute(
                            'tierThree',
                            baseRoute,
                            tierThree.path,
                            navData.path
                          );

                          return (
                            <div className={styles.subNavTierThreeContainer} key={i}>
                              <TierThreeLinkComp
                                className={styles.subNavTierThree}
                                activeClassName={styles.subNavLinkActive}
                                to={tierThreePath}
                                key={tierThreePath}>
                                {tierThree.name}
                              </TierThreeLinkComp>
                              {tierThree.isMobileOnly && (
                                <div
                                  className={styles.subNavTierThreeMobileBadge}>
                                  MOBILE
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </nav>
                    )}
                </div>
              );
            }}
          </ShowHideComponent>
        );
      }}
    </LocationProvider>
  );
};

export default SubNavSection;
