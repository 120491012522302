import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ContentSelection = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.selection}
        width="240"
        height="48"
        x="32"
        y="42"
        fill="currentColor"
        rx="10"
      />
      <circle
        className={styles.action}
        cx="56"
        cy="66"
        r="16"
        fill="currentColor"
      />
      <path
        className={styles.white}
        fill="currentColor"
        fillRule="nonzero"
        d="M54.4073523,68.5292676 L62.1371784,60.7994414 C62.5727973,60.3638226 63.279075,60.3638226
        63.7146939,60.7994414 L64.9767063,62.0614539 C65.4123252,62.4970728 65.4123252,63.2033505 64.9767063,63.6389694
        L54.4073523,74.2083235 C53.9717334,74.6439424 53.2654556,74.6439424 52.8298367,74.2083235
        L47.3085323,68.6870191 C46.8729135,68.2514002 46.8729135,67.5451225 47.3085323,67.1095036
        L48.5705448,65.8474911 C49.0061637,65.4118723 49.7124414,65.4118723 50.1480603,65.8474911
        L52.8298367,68.5292676 C53.2654556,68.9648864 53.9717334,68.9648864 54.4073523,68.5292676 Z"
        transform="rotate(-5 56.143 67.504)"
      />
      <rect
        className={styles.neutral8}
        width="152"
        height="12"
        x="82"
        y="60"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.selection}
        width="240"
        height="48"
        x="32"
        y="98"
        fill="currentColor"
        rx="10"
      />
      <circle
        className={styles.action}
        cx="56"
        cy="122"
        r="16"
        fill="currentColor"
      />
      <path
        className={styles.white}
        fill="currentColor"
        fillRule="nonzero"
        d="M54.4073523,124.529268 L62.1371784,116.799441 C62.5727973,116.363823 63.279075,116.363823
        63.7146939,116.799441 L64.9767063,118.061454 C65.4123252,118.497073 65.4123252,119.203351 64.9767063,119.638969
        L54.4073523,130.208323 C53.9717334,130.643942 53.2654556,130.643942 52.8298367,130.208323
        L47.3085323,124.687019 C46.8729135,124.2514 46.8729135,123.545122 47.3085323,123.109504 L48.5705448,121.847491
        C49.0061637,121.411872 49.7124414,121.411872 50.1480603,121.847491 L52.8298367,124.529268
        C53.2654556,124.964886 53.9717334,124.964886 54.4073523,124.529268 Z"
        transform="rotate(-5 56.143 123.504)"
      />
      <rect
        className={styles.neutral8}
        width="112"
        height="12"
        x="82"
        y="116"
        fill="currentColor"
        rx="6"
      />
    </g>
  );
};

export default ContentSelection;
