import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Selects = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral3}
        width="226"
        height="40"
        x="39"
        y="104"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.level0}
        width="222"
        height="36"
        x="41"
        y="106"
        fill="currentColor"
        rx="10"
      />
      <rect
        className={styles.level0}
        width="226"
        height="40"
        x="39"
        y="48"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.neutral3}
        width="158"
        height="12"
        x="51"
        y="118"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.neutral3}
        width="226"
        height="40"
        x="39"
        y="48"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.level0}
        width="222"
        height="36"
        x="41"
        y="50"
        fill="currentColor"
        rx="10"
      />
      <rect
        className={styles.neutral3}
        width="118"
        height="12"
        x="51"
        y="62"
        fill="currentColor"
        rx="6"
      />
      <path
        className={styles.neutral5}
        fill="currentColor"
        d="M240.863961 68.7426407L246.167262 63.4393398C246.753048 62.8535534 247.702796 62.8535534 248.288582
        63.4393398 248.874369 64.0251263 248.874369 64.9748737 248.288582 65.5606602L241.924621 71.9246212C241.338835
        72.5104076 240.389087 72.5104076 239.803301 71.9246212L233.43934 65.5606602C232.853553 64.9748737 232.853553
        64.0251263 233.43934 63.4393398 234.025126 62.8535534 234.974874 62.8535534 235.56066 63.4393398L240.863961
        68.7426407zM240.863961 124.742641L246.167262 119.43934C246.753048 118.853553 247.702796 118.853553 248.288582
        119.43934 248.874369 120.025126 248.874369 120.974874 248.288582 121.56066L241.924621 127.924621C241.338835
        128.510408 240.389087 128.510408 239.803301 127.924621L233.43934 121.56066C232.853553 120.974874 232.853553
        120.025126 233.43934 119.43934 234.025126 118.853553 234.974874 118.853553 235.56066 119.43934L240.863961
        124.742641z"
      />
    </g>
  );
};

export default Selects;
