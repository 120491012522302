import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Forms = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral5}
        fill="currentColor"
        fillRule="nonzero"
        d="M170.5,141 C169.119288,141 168,142.119288 168,143.5 L168,154.5 C168,155.880712 169.119288,157 170.5,157
        L181.5,157 C182.880712,157 184,155.880712 184,154.5 L184,143.5 C184,142.119288 182.880712,141 181.5,141
        L170.5,141 Z M170.5,138 L181.5,138 C184.537566,138 187,140.462434 187,143.5 L187,154.5 C187,157.537566
        184.537566,160 181.5,160 L170.5,160 C167.462434,160 165,157.537566 165,154.5 L165,143.5 C165,140.462434
        167.462434,138 170.5,138 Z"
      />
      <path
        className={styles.neutral9}
        fill="currentColor"
        fillRule="nonzero"
        d="M170.5,106 L181.5,106 C184.537566,106 187,108.462434 187,111.5 L187,122.5 C187,125.537566 184.537566,128
        181.5,128 L170.5,128 C167.462434,128 165,125.537566 165,122.5 L165,111.5 C165,108.462434 167.462434,106
        170.5,106 Z M174.723495,118.44433 C174.368493,118.867404 173.737738,118.922588 173.314663,118.567587
        L170.710112,116.382109 C170.287037,116.027107 169.656282,116.082291 169.30128,116.505366 L168.27282,117.731037
        C167.917818,118.154111 167.973002,118.784867 168.396077,119.139869 L173.758388,123.639382
        C174.181462,123.994384 174.812218,123.9392 175.16722,123.516125 L183.780573,113.25113 C184.135575,112.828055
        184.080391,112.1973 183.657317,111.842298 L182.431646,110.813838 C182.008571,110.458836 181.377815,110.51402
        181.022813,110.937094 L174.723495,118.44433 Z"
      />
      <rect
        className={styles.level0}
        width="226"
        height="40"
        x="39"
        y="50"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.neutral3}
        width="226"
        height="40"
        x="39"
        y="50"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.level0}
        width="222"
        height="36"
        x="41"
        y="52"
        fill="currentColor"
        rx="10"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M50,128 C43.9248678,128 39,123.075132 39,117 C39,110.924868 43.9248678,106 50,106 C56.0751322,106
        61,110.924868 61,117 C61,123.075132 56.0751322,128 50,128 Z M50,125 C54.418278,125 58,121.418278 58,117
        C58,112.581722 54.418278,109 50,109 C45.581722,109 42,112.581722 42,117 C42,121.418278 45.581722,125 50,125 Z"
      />
      <path
        className={styles.neutral9}
        fill="currentColor"
        d="M50,160 C43.9248678,160 39,155.075132 39,149 C39,142.924868 43.9248678,138 50,138 C56.0751322,138
        61,142.924868 61,149 C61,155.075132 56.0751322,160 50,160 Z M50,157 C54.418278,157 58,153.418278 58,149
        C58,144.581722 54.418278,141 50,141 C45.581722,141 42,144.581722 42,149 C42,153.418278 45.581722,157 50,157 Z
        M50,154 C47.2385763,154 45,151.761424 45,149 C45,146.238576 47.2385763,144 50,144 C52.7614237,144 55,146.238576
        55,149 C55,151.761424 52.7614237,154 50,154 Z"
      />
      <rect
        className={styles.neutral5}
        width="58"
        height="12"
        x="67"
        y="111"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.neutral5}
        width="78"
        height="12"
        x="39"
        y="32"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.neutral10}
        width="2"
        height="20"
        x="51"
        y="60"
        fill="currentColor"
      />
      <rect
        className={styles.neutral4}
        width="68"
        height="12"
        x="67"
        y="143"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.neutral4}
        width="68"
        height="12"
        x="193"
        y="111"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.neutral4}
        width="53"
        height="12"
        x="193"
        y="143"
        fill="currentColor"
        rx="6"
      />
    </g>
  );
};

export default Forms;
