import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Tooltip = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral10}
        fill="currentColor"
        d="M162.307246,142 C162.2703,142.041235 162.232014,142.081694 162.192388,142.12132 L155.12132,149.192388 C153.949747,150.363961 152.050253,150.363961 150.87868,149.192388 L143.807612,142.12132 C143.767986,142.081694 143.7297,142.041235 143.692754,142 L81,142 C72.163444,142 65,134.836556 65,126 L65,57 C65,48.163444 72.163444,41 81,41 L224,41 C232.836556,41 240,48.163444 240,57 L240,126 C240,134.836556 232.836556,142 224,142 L162.307246,142 Z"
      />
      <path
        className={styles.neutral2}
        fill="currentColor"
        fillOpacity=".4"
        d="M91,61 L200,61 C203.313708,61 206,63.6862915 206,67 L206,68 C206,71.3137085 203.313708,74 200,74 L91,74 C87.6862915,74 85,71.3137085 85,68 L85,67 C85,63.6862915 87.6862915,61 91,61 Z M91,85 L214,85 C217.313708,85 220,87.6862915 220,91 L220,92 C220,95.3137085 217.313708,98 214,98 L91,98 C87.6862915,98 85,95.3137085 85,92 L85,91 C85,87.6862915 87.6862915,85 91,85 Z M91,109 L148,109 C151.313708,109 154,111.686292 154,115 L154,116 C154,119.313708 151.313708,122 148,122 L91,122 C87.6862915,122 85,119.313708 85,116 L85,115 C85,111.686292 87.6862915,109 91,109 Z"
      />
    </g>
  );
};

export default Tooltip;
