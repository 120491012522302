import React from 'react';
import classNames from 'classnames';
import { Icon, Note } from 'hudl-uniform-ui-components';
import { IconUniformLogo, Link } from 'docComponents';
import styles from './GlobalNav.module.scss';

export default class GlobalNav extends React.Component {
  state = {
    isMobileNavOpen: false,
  };

  componentDidMount() {
    /* eslint-disable */
    if (typeof window !== 'undefined') {
      const location = window.location;
      if (location.pathname === '/') {
        this.setState({
          navStyling: 'home',
          isHomePage: true,
        });
      } else {
        this.setState({
          navStyling: 'normal',
        });
      }
    }
    /* eslint-enable */
  }

  hideMobileNav = () => this.setState(() => ({ isMobileNavOpen: false }));

  showMobileNav = () => this.setState(() => ({ isMobileNavOpen: true }));

  render() {
    const headerClasses = classNames('uni-env--dark', styles.header, {
      [styles.homeGlobal]: this.state.navStyling === 'home',
      [styles.normalGlobal]: this.state.navStyling === 'normal',
      'global-nav--mobile-nav-open': this.state.isMobileNavOpen,
    });

    return (
      <header className={headerClasses}>
        <div className={styles.vThreeNotice}>
          <Note type="warning">
            Hey there! This is the Uniform version 3 documentation site. If
            you’re looking for version 4, go{' '}
            <Link href="https://uniform.hudl.com">here</Link> instead.
          </Note>
        </div>
        <div className={'uni-bg--level2 ' + styles.headerInner}>
          <div className={styles.globalNavMain}>
            <div className={styles.hudlAngle}>
              {this.state.isHomePage ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="112"
                  height="72"
                  viewBox="0 0 112 72">
                  <polygon
                    fill="#FF6300"
                    fillRule="evenodd"
                    points="0 0 112 0 0 72"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="160"
                  viewBox="0 0 160 160">
                  <polygon
                    fill="#FF6300"
                    fillRule="evenodd"
                    points="160 0 0 160 0 0"
                  />
                </svg>
              )}
            </div>
            <div className={styles.hudlLogo}>
              <Link href="/" type="wrapper">
                <Icon size="large" type="logomark" />
              </Link>
            </div>

            {!this.state.isHomePage && (
              <h1 className={styles.uniformDesktop}>
                <Link className={styles.uniformLink} href="/">
                  <IconUniformLogo className={styles.uniformLogoDesktop} />
                </Link>
              </h1>
            )}
          </div>

          <div className={styles.globalNavSecondary}>
            <h1 className={styles.uniformMobile}>
              <Link className={styles.uniformLink} href="/">
                <IconUniformLogo className={styles.uniformLogoMobile} />
              </Link>
            </h1>

            <nav className={styles.nav} role="navigation">
              <ul className={styles.navItems}>
                <li className={styles.navItem} onClick={this.hideMobileNav}>
                  <Link
                    className={styles.navLink}
                    href="/guidelines"
                    activeClassName={styles.navLinkActive}>
                    Visual Guidelines
                  </Link>
                </li>

                <li className={styles.navItem} onClick={this.hideMobileNav}>
                  <Link
                    className={styles.navLink}
                    href="/components"
                    activeClassName={styles.navLinkActive}>
                    Components
                  </Link>
                </li>

                <li className={styles.navItem} onClick={this.hideMobileNav}>
                  <Link
                    className={styles.navLink}
                    href="/patterns"
                    activeClassName={styles.navLinkActive}>
                    Patterns
                  </Link>
                </li>

                <li className={styles.navItem} onClick={this.hideMobileNav}>
                  <Link
                    className={styles.navLink}
                    href="/words/"
                    activeClassName={styles.navLinkActive}>
                    Words
                  </Link>
                </li>

                <li className={styles.navItem} onClick={this.hideMobileNav}>
                  <Link
                    className={styles.navLink}
                    href="/resources/"
                    activeClassName={styles.navLinkActive}>
                    Resources
                  </Link>
                </li>
              </ul>
            </nav>
          </div>

          <div className={styles.navControls}>
            <button className={styles.menu} onClick={this.showMobileNav}>
              <Icon size="large" type="menu" />
            </button>

            <button className={styles.dismiss} onClick={this.hideMobileNav}>
              <Icon size="large" type="uidismiss" />
            </button>
          </div>
        </div>
      </header>
    );
  }
}
