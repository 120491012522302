import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const SearchInput = () => {
  return (
    <g className={'illustrationLayer'} fillRule="evenodd">
      <rect
        x="39"
        y="70"
        width="226"
        height="52"
        rx="16"
        className={styles.neutral3}
        fill="currentColor"
      />
      <rect
        x="41"
        y="72"
        width="222"
        height="48"
        rx="14"
        className={styles.whiteBlack}
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        d="M77.522 107.4L77.7697 107.153L69.7901 99.1741L69.856 99.0723C70.8014 97.6113 71.35
        95.8693 71.35 94C71.35 88.8357 67.1643 84.65 62 84.65C56.8357 84.65 52.65 88.8357
        52.65 94C52.65 99.1643 56.8357 103.35 62 103.35C63.8693 103.35 65.6113 102.801
        67.0723 101.856L67.1741 101.79L75.2057 109.823C75.9283 110.544 77.1001 110.544
        77.8228 109.822C78.5264 109.117 78.5437 107.986 77.8747 107.26L77.628 107.506L77.522
        107.4ZM56.35 94C56.35 97.1207 58.8793 99.65 62 99.65C65.1207 99.65 67.65 97.1207 67.65
        94C67.65 90.8793 65.1207 88.35 62 88.35C58.8793 88.35 56.35 90.8793 56.35 94Z"
        className={styles.neutral8}
        fill="currentColor"
      />
    </g>
  );
};

export default SearchInput;
