import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const LookupMultiSelect = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        width="202"
        height="122"
        x="51"
        y="36"
        className={styles.neutral3}
        fill="currentColor"
        rx="12"
      />
      <rect
        width="198"
        height="118"
        x="53"
        y="38"
        className={styles.white}
        fill="currentColor"
        rx="10"
      />
      <rect
        width="118"
        height="10"
        x="63"
        y="74"
        className={styles.neutral3}
        fill="currentColor"
        rx="5"
      />
      <rect
        width="108"
        height="10"
        x="63"
        y="94"
        className={styles.neutral3}
        fill="currentColor"
        rx="5"
      />
      <rect
        width="148"
        height="10"
        x="63"
        y="114"
        className={styles.neutral3}
        fill="currentColor"
        rx="5"
      />
      <rect
        width="126"
        height="10"
        x="63"
        y="134"
        className={styles.neutral3}
        fill="currentColor"
        rx="5"
      />
      <path
        className={styles.neutral5}
        fill="currentColor"
        d="M233,54.9061637 L237.720663,50.3753453 C238.242092,49.8748849 239.087498,49.8748849 239.608928,50.3753453 C240.130357,50.8758057 240.130357,51.6872123 239.608928,52.1876726 L233.944133,57.6246547 C233.422703,58.1251151 232.577297,58.1251151 232.055867,57.6246547 L226.391072,52.1876726 C225.869643,51.6872123 225.869643,50.8758057 226.391072,50.3753453 C226.912502,49.8748849 227.757908,49.8748849 228.279337,50.3753453 L233,54.9061637 Z"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M136,49 L149.436115,49 C150.327634,49 150.65092,49.0928256 150.976846,49.2671327 C151.302772,49.4414398 151.55856,49.6972284 151.732867,50.0231543 C151.907174,50.3490802 152,50.6723665 152,51.5638852 L152,56.4361148 C152,57.3276335 151.907174,57.6509198 151.732867,57.9768457 C151.55856,58.3027716 151.302772,58.5585602 150.976846,58.7328673 C150.65092,58.9071744 150.327634,59 149.436115,59 L136,59 C133.238576,59 131,56.7614237 131,54 L131,54 C131,51.2385763 133.238576,49 136,49 Z"
      />
      <rect
        width="61"
        height="20"
        x="64"
        y="44"
        className={styles.neutral9}
        fill="currentColor"
        rx="6"
      />
      <path
        className={styles.white}
        fill="currentColor"
        fillOpacity=".8"
        d="M116.040001,52.5610882 L117.760188,50.8409016 C118.167344,50.4337447 118.827476,50.4337447 119.234633,50.8409016 C119.64179,51.2480585 119.64179,51.9081904 119.234633,52.3153473 L117.514447,54.0355339 L119.234633,55.7557205 C119.64179,56.1628775 119.64179,56.8230093 119.234633,57.2301662 C118.827476,57.6373231 118.167344,57.6373231 117.760188,57.2301662 L116.040001,55.5099796 L114.319814,57.2301662 C113.912657,57.6373231 113.252526,57.6373231 112.845369,57.2301662 C112.438212,56.8230093 112.438212,56.1628775 112.845369,55.7557205 L114.565555,54.0355339 L112.845369,52.3153473 C112.438212,51.9081904 112.438212,51.2480585 112.845369,50.8409016 C113.252526,50.4337447 113.912657,50.4337447 114.319814,50.8409016 L116.040001,52.5610882 Z"
      />
      <rect
        width="34"
        height="6"
        x="70"
        y="51"
        className={styles.white}
        fill="currentColor"
        rx="3"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M68 74L81.4361148 74C82.3276335 74 82.6509198 74.0928256 82.9768457 74.2671327 83.3027716 74.4414398 83.5585602 74.6972284 83.7328673 75.0231543 83.9071744 75.3490802 84 75.6723665 84 76.5638852L84 81.4361148C84 82.3276335 83.9071744 82.6509198 83.7328673 82.9768457 83.5585602 83.3027716 83.3027716 83.5585602 82.9768457 83.7328673 82.6509198 83.9071744 82.3276335 84 81.4361148 84L68 84C65.2385763 84 63 81.7614237 63 79L63 79C63 76.2385763 65.2385763 74 68 74zM68 94L81.4361148 94C82.3276335 94 82.6509198 94.0928256 82.9768457 94.2671327 83.3027716 94.4414398 83.5585602 94.6972284 83.7328673 95.0231543 83.9071744 95.3490802 84 95.6723665 84 96.5638852L84 101.436115C84 102.327634 83.9071744 102.65092 83.7328673 102.976846 83.5585602 103.302772 83.3027716 103.55856 82.9768457 103.732867 82.6509198 103.907174 82.3276335 104 81.4361148 104L68 104C65.2385763 104 63 101.761424 63 99L63 99C63 96.2385763 65.2385763 94 68 94zM68 114L81.4361148 114C82.3276335 114 82.6509198 114.092826 82.9768457 114.267133 83.3027716 114.44144 83.5585602 114.697228 83.7328673 115.023154 83.9071744 115.34908 84 115.672366 84 116.563885L84 121.436115C84 122.327634 83.9071744 122.65092 83.7328673 122.976846 83.5585602 123.302772 83.3027716 123.55856 82.9768457 123.732867 82.6509198 123.907174 82.3276335 124 81.4361148 124L68 124C65.2385763 124 63 121.761424 63 119L63 119C63 116.238576 65.2385763 114 68 114zM68 134L81.4361148 134C82.3276335 134 82.6509198 134.092826 82.9768457 134.267133 83.3027716 134.44144 83.5585602 134.697228 83.7328673 135.023154 83.9071744 135.34908 84 135.672366 84 136.563885L84 141.436115C84 142.327634 83.9071744 142.65092 83.7328673 142.976846 83.5585602 143.302772 83.3027716 143.55856 82.9768457 143.732867 82.6509198 143.907174 82.3276335 144 81.4361148 144L68 144C65.2385763 144 63 141.761424 63 139L63 139C63 136.238576 65.2385763 134 68 134z"
      />
      <rect
        width="2"
        height="20"
        x="153"
        y="44"
        className={styles.neutral10}
        fill="currentColor"
      />
    </g>
  );
};

export default LookupMultiSelect;
