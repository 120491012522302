import React from 'react';

const Notification = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fill="#DFE5EB"
        d="M80,71 L261.25,71 C268.291631,71 274,76.7083694 274,83.75 L274,108.25 C274,115.291631 268.291631,121 261.25,121 L80,121 L80,71 Z"
      />
      <path
        fill="#FFF"
        d="M80,72 L261,72 C267.627417,72 273,77.372583 273,84 L273,108 C273,114.627417 267.627417,120 261,120 L80,120 L80,72 Z"
      />
      <rect
        width="34.5"
        height="15.152"
        x="88.5"
        y="99.788"
        fill="#DFE5EB"
        rx="4.5"
      />
      <rect
        width="19.5"
        height="4.545"
        x="96"
        y="105.091"
        fill="#717D8A"
        rx="2.273"
      />
      <path
        fill="#45525E"
        d="M42,71 L80.25,71 L80.25,121 L42,121 C35.372583,121 30,115.627417 30,109 L30,83 C30,76.372583 35.372583,71 42,71 Z"
      />
      <path
        fill="#FFF"
        d="M43.5,96 C43.5,89.3160173 48.8837143,83.8787879 55.5,83.8787879 C62.1171429,83.8787879 67.5,89.3160173 67.5,96 C67.5,102.683117 62.1171429,108.121212 55.5,108.121212 C48.8837143,108.121212 43.5,102.683117 43.5,96 Z M53.7857143,89.5064935 C53.7857143,90.4658009 54.5502857,91.2380952 55.5,91.2380952 C56.4497143,91.2380952 57.2142857,90.4658009 57.2142857,89.5064935 C57.2142857,88.5471861 56.4497143,87.7748918 55.5,87.7748918 C54.5502857,87.7748918 53.7857143,88.5471861 53.7857143,89.5064935 Z M54.1071429,102.92381 C54.1071429,103.397403 54.4885714,103.792208 54.9582857,103.792208 L56.0417143,103.792208 C56.5054286,103.792208 56.8928571,103.403463 56.8928571,102.92381 L56.8928571,94.2701299 C56.8928571,93.7974026 56.5114286,93.4025974 56.0417143,93.4025974 L54.9582857,93.4025974 C54.4945714,93.4025974 54.1071429,93.791342 54.1071429,94.2701299 L54.1071429,102.92381 Z"
      />
      <rect
        width="81.75"
        height="7.576"
        x="88.5"
        y="79.333"
        fill="#455666"
        rx="3.788"
      />
      <rect
        width="149.5"
        height="6.061"
        x="88.5"
        y="89.939"
        fill="#B7BFC7"
        rx="3.03"
      />
      <path
        fill="#DFE5EB"
        d="M260.815,80.2077375 L262.690004,78.3328332 C263.133805,77.8890556 263.853348,77.8890556 264.297149,78.3328332 C264.74095,78.7766108 264.74095,79.4961165 264.297149,79.9398941 L262.422146,81.8147984 L264.297149,83.6897028 C264.74095,84.1334804 264.74095,84.852986 264.297149,85.2967636 C263.853348,85.7405412 263.133805,85.7405412 262.690004,85.2967636 L260.815,83.4218593 L258.939997,85.2967636 C258.496196,85.7405412 257.776652,85.7405412 257.332851,85.2967636 C256.88905,84.852986 256.88905,84.1334804 257.332851,83.6897028 L259.207854,81.8147984 L257.332851,79.9398941 C256.88905,79.4961165 256.88905,78.7766108 257.332851,78.3328332 C257.776652,77.8890556 258.496196,77.8890556 258.939997,78.3328332 L260.815,80.2077375 Z"
      />
    </g>
  );
};

export default Notification;
