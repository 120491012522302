const LOCAL_STORAGE_KEY = '__hudlUniformPlatformTabPreference__';

export const setPlatformTabPreference = preference => {
  if (!window) return;
  window.localStorage.setItem(LOCAL_STORAGE_KEY, preference);
};

export const getPlatformTabPreference = () => {
  if (!window) return 'web';

  const currentPreference = window.localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!currentPreference) {
    setPlatformTabPreference('web');
  }

  return currentPreference || 'web';
};
