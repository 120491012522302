import React from 'react';

const IconDesign = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        d="M30.04 17.138C26.922 21.851 21.795 26.5 16 26.5c-5.796 0-10.923-4.65-14.04-9.362l-.33-.528a1.19 1.19 0 0
        1-.04-.047l-.157-.254a1.354 1.354 0 0 1-.006-1.107l.175-.283.03-.034.282-.452C5.026 9.696 10.174 5 16 5c5.825
        0 10.974 4.696 14.086 9.433l.283.452.03.034.174.283c.156.348.153.761-.006 1.107l-.158.254a1.19 1.19 0 0
        1-.04.047l-.33.528zM16 23.25a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15zm0-4.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconDesign;
