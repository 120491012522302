import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Spinner = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutralTransparent}
        fill="currentColor"
        fillRule="nonzero"
        d="M151.538462,157.076923 C117.551708,157.076923 90,129.525215 90,95.5384615 C90,61.5517077 117.551708,34 151.538462,34 C185.525215,34 213.076923,61.5517077 213.076923,95.5384615 C213.076923,129.525215 185.525215,157.076923 151.538462,157.076923 Z M151.538462,142.307692 C177.368394,142.307692 198.307692,121.368394 198.307692,95.5384615 C198.307692,69.7085286 177.368394,48.7692308 151.538462,48.7692308 C125.708529,48.7692308 104.769231,69.7085286 104.769231,95.5384615 C104.769231,121.368394 125.708529,142.307692 151.538462,142.307692 Z"
      />
      <path
        className={styles.contentDefault}
        fill="currentColor"
        fillRule="nonzero"
        d="M151.538462,157.076923 C147.460051,157.076923 144.153846,153.770718 144.153846,149.692308 C144.153846,145.613897 147.460051,142.307692 151.538462,142.307692 C177.368394,142.307692 198.307692,121.368394 198.307692,95.5384615 C198.307692,69.7085286 177.368394,48.7692308 151.538462,48.7692308 C125.708529,48.7692308 104.769231,69.7085286 104.769231,95.5384615 C104.769231,99.616872 101.463026,102.923077 97.3846154,102.923077 C93.3062049,102.923077 90,99.616872 90,95.5384615 C90,61.5517077 117.551708,34 151.538462,34 C185.525215,34 213.076923,61.5517077 213.076923,95.5384615 C213.076923,129.525215 185.525215,157.076923 151.538462,157.076923 Z"
      />
    </g>
  );
};

export default Spinner;
