import React, { Component } from 'react';
import { Link } from 'gatsby';
import { getDesignCodePreference } from '../../utility/js/designCodePreference';

export default class DesignCodeLink extends Component {
  componentDidMount() {
    const preference = getDesignCodePreference();
    this.setPreference(preference);
  }

  setPreference(preference) {
    this.setState(() => ({
      preference,
    }));
  }

  render() {
    const { children, to, ...restProps } = this.props;
    const preference = this.state ? this.state.preference : 'design';
    const navTo = `${to}/${preference}`;

    return (
      <Link to={navTo} {...restProps}>
        {children}
      </Link>
    );
  }
}
