import React from 'react';

const Modal = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        width="212"
        height="146"
        x="46"
        y="24"
        fill="#13293F"
        fillOpacity=".03"
        rx="15"
      />
      <rect
        width="200"
        height="132"
        x="52"
        y="30"
        fill="#13293F"
        fillOpacity=".03"
        rx="12"
      />
      <rect width="192" height="124" x="56" y="34" fill="#FFF" rx="8" />
      <rect width="66" height="9" x="70" y="49" fill="#8C96A0" rx="4.5" />
      <rect width="156" height="6" x="70" y="71" fill="#B7BFC7" rx="3" />
      <rect width="36" height="6" x="70" y="100" fill="#B7BFC7" rx="3" />
      <rect width="162" height="6" x="70" y="85" fill="#B7BFC7" rx="3" />
      <rect width="21" height="5" x="164" y="138" fill="#DFE5EB" rx="2.5" />
      <rect width="46" height="20" x="194" y="130" fill="#009CE3" rx="6" />
      <rect width="26" height="6" x="204" y="137" fill="#FFF" rx="3" />
    </g>
  );
};

export default Modal;
