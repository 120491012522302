import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Buttons = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.action}
        width="120"
        height="60"
        x="160"
        y="66"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.neutral2}
        width="120"
        height="60"
        x="24"
        y="66"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.neutral5}
        width="62"
        height="14"
        x="53"
        y="89"
        fill="currentColor"
        rx="7"
      />
      <rect
        className={styles.white}
        width="62"
        height="14"
        x="189"
        y="89"
        fill="currentColor"
        rx="7"
      />
    </g>
  );
};

export default Buttons;
