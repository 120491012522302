import React from 'react';

const IconTwitter = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="20"
      viewBox="0 0 25 20">
      <path
        fillRule="evenodd"
        d="M22.484 4.984c.008.216.012.437.012.653C22.496 12.312 17.626 20 8.333 20 5.482 20 2.234 18.934 0
        17.5c.396.049 1.176.31 1.585.31 2.365 0 4.187-.62
        5.915-1.977-2.21-.04-3.717-1.687-4.359-3.692.31.06.625.09.952.09.462 0 .911.269 1.744-.564C3.525 11.2 1.373
        9.547 1.373 7.1v-.062c.682.38 1.968.437 2.798.462-1.356-.907-2.758-2.283-2.758-4.036 0-.927-.18-1.883.258-2.63
        2.492 3.055 6.634 5.62 10.833 5.833-.086-.372-.127-1.222-.127-1.618A5.05 5.05 0 0 1 17.431 0c1.454 0 2.487.686
        3.406 1.667C21.99 1.442 23.194.58 24.171 0c-.376 1.18-1.026 2.537-2.067 3.162 1.021-.123 1.993-.262
        2.9-.662-.678 1.013-1.532 1.773-2.52 2.484z"
      />
    </svg>
  );
};

export default IconTwitter;
