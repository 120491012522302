import React from 'react';

const Toast = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        width="254"
        height="104"
        x="25"
        y="44"
        fill="#13293F"
        fillOpacity=".03"
        rx="25"
      />
      <rect
        width="238"
        height="88"
        x="33"
        y="52"
        fill="#13293F"
        fillOpacity=".03"
        rx="20"
      />
      <path
        fill="#FFF"
        d="M117,56 L251,56 C259.836556,56 267,63.163444 267,72 L267,120 C267,128.836556 259.836556,136 251,136 L117,136
        L117,56 Z"
      />
      <path
        fill="#45525E"
        d="M53,56 L117,56 L117,136 L53,136 C44.163444,136 37,128.836556 37,120 L37,72 C37,63.163444 44.163444,56 53,56
        Z"
      />
      <path
        fill="#FFF"
        d="M54,96 C54,83.3171429 64.3187857,73 77,73 C89.6828571,73 100,83.3171429 100,96 C100,108.681214
        89.6828571,119 77,119 C64.3187857,119 54,108.681214 54,96 Z M73.7142857,83.6785714 C73.7142857,85.4988571
        75.1797143,86.9642857 77,86.9642857 C78.8202857,86.9642857 80.2857143,85.4988571 80.2857143,83.6785714
        C80.2857143,81.8582857 78.8202857,80.3928571 77,80.3928571 C75.1797143,80.3928571 73.7142857,81.8582857
        73.7142857,83.6785714 Z M74.3303571,109.137929 C74.3303571,110.036571 75.0614286,110.785714
        75.9617143,110.785714 L78.0382857,110.785714 C78.9270714,110.785714 79.6696429,110.048071 79.6696429,109.137929
        L79.6696429,92.7175714 C79.6696429,91.8205714 78.9385714,91.0714286 78.0382857,91.0714286
        L75.9617143,91.0714286 C75.0729286,91.0714286 74.3303571,91.8090714 74.3303571,92.7175714
        L74.3303571,109.137929 Z"
      />
      <rect width="109" height="12" x="133" y="81" fill="#B7BFC7" rx="6" />
      <rect width="69" height="12" x="133" y="101" fill="#B7BFC7" rx="6" />
      <path
        fill="#F2F6FA"
        d="M252,66.1715729 L254.474874,63.6966991 C255.06066,63.1109127 256.010408,63.1109127 256.596194,63.6966991
        C257.181981,64.2824856 257.181981,65.232233 256.596194,65.8180195 L254.12132,68.2928932 L256.596194,70.767767
        C257.181981,71.3535534 257.181981,72.3033009 256.596194,72.8890873 C256.010408,73.4748737 255.06066,73.4748737
        254.474874,72.8890873 L252,70.4142136 L249.525126,72.8890873 C248.93934,73.4748737 247.989592,73.4748737
        247.403806,72.8890873 C246.818019,72.3033009 246.818019,71.3535534 247.403806,70.767767 L249.87868,68.2928932
        L247.403806,65.8180195 C246.818019,65.232233 246.818019,64.2824856 247.403806,63.6966991 C247.989592,63.1109127
        248.93934,63.1109127 249.525126,63.6966991 L252,66.1715729 Z"
      />
    </g>
  );
};

export default Toast;
