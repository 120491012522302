import React from 'react';

const IconApple = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32">
      <path
        d="M24.963 24.623c-1.105 1.674-2.277 3.308-4.06 3.335-1.784.04-2.357-1.067-4.38-1.067-2.037 0-2.663
        1.04-4.353 1.107-1.744.068-3.062-1.782-4.18-3.415-2.277-3.334-4.02-9.476-1.678-13.607 1.159-2.052 3.235-3.348
        5.485-3.388 1.704-.027 3.328 1.174 4.38 1.174 1.038 0 3.008-1.444 5.072-1.228.865.04 3.288.35 4.845
        2.672-.12.081-2.888 1.728-2.862 5.144.04 4.076 3.528 5.44 3.568 5.453-.04.095-.56 1.944-1.837 3.82zM17.362
        3.025c.971-1.12 2.582-1.971 3.913-2.025.173 1.58-.452 3.172-1.384 4.306-.919 1.148-2.436 2.039-3.927
        1.917-.2-1.552.546-3.172 1.398-4.198z"
      />
    </svg>
  );
};

export default IconApple;
