import React from 'react';
import classNames from 'classnames';
import styles from './DataTable.module.scss';

const DataTableRow = ({ children, layout }) => {
  const dataTableRowClasses = classNames(styles.dataTableRow, {
    [styles.dataTableRow__halves]: layout === 'halves',
    [styles.dataTableRow__colorHalves]: layout === 'colorHalves',
    [styles.dataTableRow__thirds]: layout === 'thirds',
    [styles.dataTableRow__colorThirds]: layout === 'colorThirds',
    [styles.dataTableRow__quarters]: layout === 'quarters',
  });

  return <tr className={dataTableRowClasses}>{children}</tr>;
};

export default DataTableRow;
