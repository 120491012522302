export const copyTextToClipboard = (text, target) => {
  if (!window) return;

  if (document) {
    const textField = document.createElement('textarea');
    textField.innerText = text.replace(/  /g, ' ').replace(/  /g, ' ');
    textField.style.cssText +=
      ';position:absolute;padding:0;width:1px;overflow:hidden;height:1px;margin:-1px;border:0;clip:rect(0 0 0 0);';
    target.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  }
};
