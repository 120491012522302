import React from 'react';

const Avatars = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <circle cx="224" cy="96" r="40" fill="#009CE3" />
      <rect width="32" height="50" x="208" y="71" fill="#80CDF1" rx="16" />
      <circle
        cx="224"
        cy="96"
        r="39"
        stroke="#E6F2FF"
        strokeOpacity=".06"
        strokeWidth="2"
      />
      <g transform="translate(136 56)">
        <circle cx="40" cy="40" r="40" fill="#353F4A" />
        <rect width="52" height="32" x="14" y="24" fill="#7B8692" rx="16" />
        <circle
          cx="40"
          cy="40"
          r="39"
          stroke="#E6F2FF"
          strokeOpacity=".06"
          strokeWidth="2"
        />
      </g>
      <circle cx="128" cy="96" r="40" fill="#009CE3" />
      <circle
        cx="128"
        cy="96"
        r="39"
        stroke="#E6F2FF"
        strokeOpacity=".06"
        strokeWidth="2"
      />
      <path
        fill="#80CDF1"
        d="M128,75.6875 C116.781658,75.6875 107.6875,84.7816585 107.6875,96 C107.6875,107.218342 116.781658,116.3125
        128,116.3125 C139.218342,116.3125 148.3125,107.218342 148.3125,96 C148.3125,84.7816585 139.218342,75.6875
        128,75.6875 L128,75.6875 Z M145.776757,96 C145.776757,96.8231209 145.717014,97.6263276 145.610805,98.4162582
        C144.176981,97.3674428 140.419833,94.4997958 138.481516,93.603656 C138.680658,92.0901757 139.151961,83.8191381
        130.701695,78.4356618 C139.231618,79.7300858 145.776757,87.0983456 145.776757,96 L145.776757,96 Z
        M128,78.2232435 L128.073019,78.2232435 C128.1394,78.2630719 128.212418,78.3095384 128.278799,78.3493668
        C126.094873,79.0662786 117.691074,82.5977328 110.893689,91.1674837 C112.997958,83.7062908 119.855086,78.2232435
        128,78.2232435 L128,78.2232435 Z M110.296262,94.4466912 C112.745711,91.0944649 120.784416,82.0202206
        129.951593,79.5110294 C131.458435,80.672692 132.99183,82.2193627 134.239788,84.2041462 C131.783701,85.0272672
        120.963644,89.1827002 111.988971,103.733354 C110.860498,101.396752 110.223243,98.7747141 110.223243,96
        C110.223243,95.4755923 110.256434,94.9578227 110.296262,94.4466912 L110.296262,94.4466912 Z
        M112.805453,105.220282 C114.956189,101.648999 123.240502,89.7469363 134.903595,85.3591708
        C135.998877,87.4568015 136.742341,89.9859069 136.841912,92.9929534 C126.493158,97.9582312 119.888276,107.490502
        118.095997,110.756434 C115.958538,109.32261 114.146344,107.43076 112.805453,105.220282 L112.805453,105.220282 Z
        M140.526042,108.612337 C138.806781,110.318321 136.735703,111.679126 134.432292,112.568627
        C134.405739,112.548713 134.379187,112.522161 134.345997,112.502247 C130.349877,110.856005 126.499796,106.687296
        124.853554,104.549837 C126.413501,102.823938 127.993362,101.323734 129.486928,100.035948 C132.175347,102.830576
        137.837623,107.317913 140.526042,108.612337 L140.526042,108.612337 Z M119.596201,111.66585
        C120.824244,109.581495 122.198325,107.683007 123.618873,105.977022 C125.636846,108.804841 129.493566,111.898182
        131.9231,113.332006 C130.661867,113.617443 129.347529,113.770118 128,113.770118 C124.959763,113.776757
        122.098754,113.00674 119.596201,111.66585 L119.596201,111.66585 Z M141.714257,107.304636 C138.813419,105.970384
        132.95864,100.779412 130.894199,98.8676471 C133.675551,96.6372549 135.965686,95.2565359 136.888378,94.7254902
        C138.269097,95.4357639 142.935662,98.3233252 145.199244,100.487337 C144.542075,103.029718 143.333946,105.346405
        141.714257,107.304636 L141.714257,107.304636 Z"
      />
      <circle cx="80" cy="96" r="40" fill="#353F4A" />
      <path
        fill="#7B8692"
        d="M50.5575422,123.07691 C53.3452116,118.7672 57.8731663,115.351639 63.48613,113.111279 C68.1564704,116.473763
        73.8857834,118.450892 80.0739844,118.450892 C86.2594702,118.450892 91.9860678,116.473763 96.6591236,113.111279
        C102.230281,115.335823 106.734336,118.718421 109.527172,122.984554 C102.213291,130.983139 91.6925209,136 80,136
        C68.3525465,136 57.8678284,131.021738 50.5575422,123.07691 Z M58.3514709,89.816602 C58.3514709,77.7683835
        68.075011,68 80.0739844,68 C92.0702426,68 101.793783,77.7683835 101.793783,89.816602 C101.793783,101.867547
        92.0702426,111.633204 80.0739844,111.633204 C68.075011,111.633204 58.3514709,101.867547 58.3514709,89.816602 Z"
      />
      <circle
        cx="80"
        cy="96"
        r="39"
        stroke="#E6F2FF"
        strokeOpacity=".06"
        strokeWidth="2"
      />
    </g>
  );
};

export default Avatars;
