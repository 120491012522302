import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSashThick = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M45,132.677854 L258.533316,9.39433695 M258.533316,14.0160177 L45,137.299535 M45,141.919778
      L258.533316,18.6362603 M258.533316,23.257941 L45,146.541459 M45,151.161701 L258.533316,27.8781836
      M258.533316,32.4998644 L45,155.783382 M45,160.403624 L258.533316,37.1201069 M258.533316,41.7417877 L45,165.025305
      M45,169.645548 L258.533316,46.3620302 M258.533316,50.983711 L45,174.267229 M45,178.887471 L258.533316,55.6039536
      M258.533316,60.2256343 L45,183.509152"
    />
  );
};

export default HatchSashThick;
