import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Hexagon = () => {
  return (
    <path
      className={'shapeLayer ' + styles.shapeBg}
      fill="currentColor"
      fillRule="evenodd"
      d="M154.978185,11.1339746 L224.456369,51.2472233 C226.003375,52.1403872 226.956369,53.7910223
      226.956369,55.5773503 L226.956369,135.803848 C226.956369,137.590176 226.003375,139.240811 224.456369,140.133975
      L154.978185,180.247223 C153.431179,181.140387 151.52519,181.140387 149.978185,180.247223 L80.5,140.133975
      C78.9529946,139.240811 78,137.590176 78,135.803848 L78,55.5773503 C78,53.7910223 78.9529946,52.1403872
      80.5,51.2472233 L149.978185,11.1339746 C151.52519,10.2408106 153.431179,10.2408106 154.978185,11.1339746 Z"
    />
  );
};

export default Hexagon;
