import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Space = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral10}
        width="4"
        height="64"
        x="24"
        y="64"
        fill="currentColor"
      />
      <rect
        className={styles.neutral10}
        width="4"
        height="64"
        x="278"
        y="64"
        fill="currentColor"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M264,94 L264,89.9021238 C264,89.808414 264.026335,89.7165902 264.076001,89.6371244 C264.222356,89.4029561
        264.530831,89.3317695 264.764999,89.4781247 L275.321601,96.0760008 C275.385986,96.1162412 275.440361,96.170616
        275.480601,96.2350005 C275.626956,96.4691688 275.55577,96.777644 275.321601,96.9239992 L264.764999,103.521875
        C264.685534,103.571541 264.59371,103.597876 264.5,103.597876 C264.223858,103.597876 264,103.374019
        264,103.097876 L264,98 L42,98 L42,103.097876 C42,103.374019 41.7761424,103.597876 41.5,103.597876
        C41.4062901,103.597876 41.3144663,103.571541 41.2350005,103.521875 L30.6783986,96.9239992 C30.4442304,96.777644
        30.3730438,96.4691688 30.519399,96.2350005 C30.5596393,96.170616 30.6140141,96.1162412 30.6783986,96.0760008
        L41.2350005,89.4781247 C41.4691688,89.3317695 41.777644,89.4029561 41.9239992,89.6371244 C41.9736653,89.7165902
        42,89.808414 42,89.9021238 L42,94 L264,94 Z"
      />
    </g>
  );
};

export default Space;
