import React, { Component } from 'react';
import classNames from 'classnames';
import {
  getDesignCodePreference,
  setDesignCodePreference,
} from '../../utility/js/designCodePreference';
import { IconCode, IconDesign } from 'docComponents';
import styles from './DesignCodeSwitcher.module.scss';

export default class DesignCodeSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preference: '',
      showSwitcherHelp: false,
    };
  }

  componentDidMount() {
    const initialPreference = getDesignCodePreference();

    if (typeof window !== 'undefined') {
      const locationHref = window.location.href;
      if (locationHref.indexOf('get-started') > -1) {
        this.setState({ showSwitcherHelp: true });
      }

      if (locationHref.indexOf('design') > -1) {
        this.setPreference('design');
      } else if (locationHref.indexOf('code') > -1) {
        this.setPreference('code');
      } else {
        this.setPreference(initialPreference);
      }
    }
  }

  handleSwitch(newPref) {
    // We need to grab the current preference, set up the page to unmount
    // save the new preference to localStorage, and pass the pref up the
    // chain in order to run the page transition
    const currentPref = this.state.preference.replace('bounce', '');

    if (newPref !== currentPref) {
      this.setPreference('unmount' + newPref);
      setDesignCodePreference(newPref);
      this.props.onSwitch(currentPref, newPref);
    } else {
      this.setPreference('bounce' + newPref);
    }
  }

  handleBackgroundAnimationEnd() {
    if (this.state.preference === 'bouncedesign') {
      this.setPreference('design');
    } else {
      this.setPreference('code');
    }
  }

  setPreference(preference) {
    this.setState(() => ({
      preference,
    }));
  }

  render() {
    const SwitchClasses = classNames(styles.switchContainer, {
      [styles.switchHelper]: this.state.showSwitcherHelp,
      [styles.switchDesignActive]: this.state.preference === 'design',
      [styles.switchCodeActive]: this.state.preference === 'code',
      [styles.switchDesignUnmount]: this.state.preference === 'unmountdesign',
      [styles.switchCodeUnmount]: this.state.preference === 'unmountcode',
      [styles.switchDesignBounce]: this.state.preference === 'bouncedesign',
      [styles.switchCodeBounce]: this.state.preference === 'bouncecode',
    });

    return (
      <div className={SwitchClasses}>
        <div
          className={styles.switchDesign}
          id="designSideToggle"
          onClick={this.handleSwitch.bind(this, 'design')}>
          <span className={styles.switchContent}>
            <span className={styles.switchIcon}>
              <IconDesign />
            </span>
            <span className={styles.switchText}>Design</span>
          </span>
        </div>

        <div
          className={styles.switchCode}
          id="codeSideToggle"
          onClick={this.handleSwitch.bind(this, 'code')}>
          <span className={styles.switchContent}>
            <span className={styles.switchIcon}>
              <IconCode />
            </span>
            <span className={styles.switchText}>Code</span>
          </span>
        </div>

        <div
          className={styles.switchBackground}
          onAnimationEnd={this.handleBackgroundAnimationEnd.bind(this)}
        />
      </div>
    );
  }
}
