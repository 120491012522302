import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Radio = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral4}
        width="112"
        height="14"
        x="100"
        y="50"
        fill="currentColor"
        rx="7"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M76 110C68.2680135 110 62 103.731986 62 96 62 88.2680135 68.2680135 82 76 82 83.7319865 82 90 88.2680135 90
        96 90 103.731986 83.7319865 110 76 110zM76 106.181818C81.6232629 106.181818 86.1818182 101.623263 86.1818182 96
        86.1818182 90.3767371 81.6232629 85.8181818 76 85.8181818 70.3767371 85.8181818 65.8181818 90.3767371
        65.8181818 96 65.8181818 101.623263 70.3767371 106.181818 76 106.181818zM76 70C68.2680135 70 62 63.7319865 62
        56 62 48.2680135 68.2680135 42 76 42 83.7319865 42 90 48.2680135 90 56 90 63.7319865 83.7319865 70 76 70zM76
        66.1818182C81.6232629 66.1818182 86.1818182 61.6232629 86.1818182 56 86.1818182 50.3767371 81.6232629
        45.8181818 76 45.8181818 70.3767371 45.8181818 65.8181818 50.3767371 65.8181818 56 65.8181818 61.6232629
        70.3767371 66.1818182 76 66.1818182z"
      />
      <path
        className={styles.neutral9}
        fill="currentColor"
        d="M76,150 C68.2680135,150 62,143.731986 62,136 C62,128.268014 68.2680135,122 76,122 C83.7319865,122
        90,128.268014 90,136 C90,143.731986 83.7319865,150 76,150 Z M76,146.181818 C81.6232629,146.181818
        86.1818182,141.623263 86.1818182,136 C86.1818182,130.376737 81.6232629,125.818182 76,125.818182
        C70.3767371,125.818182 65.8181818,130.376737 65.8181818,136 C65.8181818,141.623263 70.3767371,146.181818
        76,146.181818 Z M76,142.363636 C72.4854607,142.363636 69.6363636,139.514539 69.6363636,136
        C69.6363636,132.485461 72.4854607,129.636364 76,129.636364 C79.5145393,129.636364 82.3636364,132.485461
        82.3636364,136 C82.3636364,139.514539 79.5145393,142.363636 76,142.363636 Z"
      />
      <rect
        className={styles.neutral4}
        width="136"
        height="14"
        x="100"
        y="130"
        fill="currentColor"
        rx="7"
      />
      <rect
        className={styles.neutral4}
        width="152"
        height="14"
        x="100"
        y="90"
        fill="currentColor"
        rx="7"
      />
    </g>
  );
};

export default Radio;
