import React from 'react';

export default class ShowHideComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChildOpen: props.defaultOpen || false,
    };
  }

  componentWillReceiveProps(nextProps) {
    nextProps.defaultOpen &&
      this.setState(() => ({ isChildOpen: nextProps.defaultOpen }));
  }

  toggle = () =>
    this.setState(() => ({ isChildOpen: !this.state.isChildOpen }));

  open = () => this.setState(() => ({ isChildOpen: true }));

  close = () => this.setState(() => ({ isChildOpen: false }));

  render() {
    const isOpen = this.state.isChildOpen;

    return this.props.children({
      isOpen,
      open: this.open,
      close: this.close,
      toggle: this.toggle,
    });
  }
}
