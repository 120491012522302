import React from 'react';
import classNames from 'classnames';
import styles from './List.module.scss';

const List = ({ children, type, orderType, className }) => {
  const listClasses = classNames(styles.list, className);

  return type === 'ordered' ? (
    <ol type={orderType} className={listClasses}>
      {children}
    </ol>
  ) : (
    <ul className={listClasses}>{children}</ul>
  );
};

export default List;
