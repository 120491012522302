import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ColorsUtility = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect width="64" height="64" x="82" y="26" fill="#009CE3" rx="16" />
      <rect width="64" height="64" x="82" y="101" fill="#F2B600" rx="16" />
      <rect width="64" height="64" x="158" y="101" fill="#78A100" rx="16" />
      <rect width="64" height="64" x="158" y="26" fill="#E81C00" rx="16" />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="82.5"
        y="26.5"
        fill="none"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="82.5"
        y="101.5"
        fill="none"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="158.5"
        y="101.5"
        fill="none"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="158.5"
        y="26.5"
        fill="none"
        stroke="currentColor"
        rx="16"
      />
    </g>
  );
};

export default ColorsUtility;
