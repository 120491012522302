import React from 'react';
import { copyUrl } from '../../utility/js/location';
import { IconCopy } from 'docComponents';
import styles from './CopyUrlButton.module.scss';

const CopyUrlButton = ({ url, includePlatform }) => {
  const handleOnClick = e => {
    const el = e.currentTarget;

    copyUrl(url, includePlatform, el);

    const oldInnerHTML = el.innerHTML;
    el.innerHTML = 'URL Copied';
    el.classList.add(styles.copyUrlButtonCopied);

    setTimeout(() => {
      el.innerHTML = oldInnerHTML;
      el.classList.remove(styles.copyUrlButtonCopied);
    }, 2500);
  };

  return (
    <button className={styles.copyUrlButton} onClick={handleOnClick}>
      <IconCopy />
      Copy URL
    </button>
  );
};

export default CopyUrlButton;
