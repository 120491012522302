import React from 'react';

export default class LocationProvider extends React.Component {
  state = {
    location: null,
  };

  componentDidMount() {
    this.setLocation();
  }

  setLocation() {
    this.setState(() => ({ location: window.location }));
  }

  render() {
    return this.props.children(this.state.location);
  }
}
