import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Hexagon = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M78,56.5964054 L155.860712,11.6435021 M159.863205,13.9543425 L78,61.2180861 M78,65.8383287
      L163.864453,16.2644638 M167.866946,18.5753042 L78,70.4600094 M78,75.080252 L171.868193,20.8854255
      M175.870686,23.1962658 L78,79.7019328 M78,84.3221753 L179.871933,25.5063871 M183.874426,27.8172275 L78,88.9438561
      M78,93.5640987 L187.875674,30.1273488 M191.878167,32.4381892 L78,98.1857794 M78,102.806022 L195.879414,34.7483105
      M199.881907,37.0591508 L78,107.427703 M78,112.047945 L203.883155,39.3692721 M207.885648,41.6801125 L78,116.669626
      M78,121.289869 L211.886895,43.9902338 M215.889388,46.3010742 L78,125.911549 M78,130.531792 L219.890635,48.6111954
      M223.893128,50.9220358 L78,135.153473 M78.9380063,139.232157 L226.956369,53.7737153 M226.956369,58.395396
      L82.9404992,141.542997 M86.9417467,143.853119 L226.956369,63.0156386 M226.956369,67.6373194
      L90.9442396,146.163959 M94.945487,148.47408 L226.956369,72.2575619 M226.956369,76.8792427 L98.94798,150.784921
      M102.949227,153.095042 L226.956369,81.4994852 M226.956369,86.121166 L106.95172,155.405882 M110.952968,157.716004
      L226.956369,90.7414086 M226.956369,95.3630893 L114.955461,160.026844 M118.956708,162.336965
      L226.956369,99.9833319 M226.956369,104.605013 L122.959201,164.647806 M126.960449,166.957927
      L226.956369,109.225255 M226.956369,113.846936 L130.962941,169.268767 M134.964189,171.578889
      L226.956369,118.467179 M226.956369,123.088859 L138.966682,173.889729 M142.967929,176.19985 L226.956369,127.709102
      M226.956369,132.330783 L146.970422,178.510691 M150.97167,180.820812 L226.956369,136.951025"
    />
  );
};

export default Hexagon;
