import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchTriangleSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M155.75136,37.6314378 L144.949288,43.8680168 M148.469187,37.2141216 L153.878502,34.091052
      M159.496493,44.7111077 L137.910586,57.1737367 M141.430485,50.5198415 L157.623635,41.1707219
      M163.241625,51.7907776 L130.871884,70.4794567 M134.391783,63.8255614 L161.368768,48.2503918
      M166.986758,58.8704475 L123.833182,83.7851766 M127.35308,77.1312813 L165.1139,55.3300617 M170.731891,65.9501174
      L116.794479,97.0908965 M120.314378,90.4370012 L168.859033,62.4097316 M174.477024,73.0297873
      L109.755777,110.396616 M113.275676,103.742721 L172.604166,69.4894015 M106.236974,117.048441
      L176.349299,76.5690714 M178.222157,80.1094572 L102.717075,123.702336 M181.967289,87.1891271 L95.678373,137.008056
      M99.1982717,130.354161 L180.094432,83.6487413 M185.712422,94.268797 L99.5754519,144 M92.1595696,143.659881
      L183.839564,90.7284112 M189.457555,101.348467 L115.582933,144 M107.577947,144 L187.584697,97.8080811
      M193.202688,108.428137 L131.590413,144 M123.585428,144 L191.32983,104.887751 M196.947821,115.507807
      L147.597894,144 M139.592908,144 L195.074963,111.967421 M200.692953,122.587477 L163.605375,144 M155.600389,144
      L198.820095,119.047091 M204.438086,129.667147 L179.612856,144 M171.60787,144 L202.565228,126.126761
      M208.183219,136.746816 L195.620336,144 M187.615351,144 L206.310361,133.206431 M203.622831,144
      L210.055494,140.286101"
    />
  );
};

export default HatchTriangleSmall;
