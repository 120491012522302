import React from 'react';

const Alert = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        width="212"
        height="120"
        x="46"
        y="36"
        fill="#13293F"
        fillOpacity=".03"
        rx="15"
      />
      <rect
        width="200"
        height="106"
        x="52"
        y="42"
        fill="#13293F"
        fillOpacity=".03"
        rx="12"
      />
      <rect width="192" height="98" x="56" y="46" fill="#FFF" rx="8" />
      <rect width="100" height="8" x="70" y="82" fill="#B7BFC7" rx="4" />
      <rect width="162" height="8" x="70" y="64" fill="#B7BFC7" rx="4" />
      <rect width="81" height="26" x="67" y="107" fill="#8C96A0" rx="7.8" />
      <rect width="33.913" height="7.8" x="91" y="116" fill="#FFF" rx="3.9" />
      <rect width="81" height="26" x="156" y="107" fill="#009CE3" rx="7.8" />
      <rect width="33.913" height="7.8" x="180" y="116" fill="#FFF" rx="3.9" />
    </g>
  );
};

export default Alert;
