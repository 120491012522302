import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Division = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <polygon
        className={styles.neutral10}
        fill="currentColor"
        points="51 64 55 64 55 128 51 128"
      />
      <polygon
        className={styles.neutral10}
        fill="currentColor"
        points="175 64 179 64 179 128 175 128"
      />
      <polygon
        className={styles.action}
        fill="currentColor"
        points="62 95 112 95 112 99 62 99"
      />
      <polygon
        className={styles.action}
        fill="currentColor"
        points="137 95 162 95 162 99 137 99"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M132.678399,96.0760008 L143.235001,89.4781247 C143.469169,89.3317695 143.777644,
        89.4029561 143.923999,89.6371244 C143.973665,89.7165902 144,89.808414 144,89.9021238
        L144,103.097876 C144,103.374019 143.776142,103.597876 143.5,103.597876 C143.40629,
        103.597876 143.314466,103.571541 143.235001,103.521875 L132.678399,96.9239992
        C132.44423,96.777644 132.373044,96.4691688 132.519399,96.2350005 C132.559639,
        96.170616 132.614014,96.1162412 132.678399,96.0760008 Z"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M162.678399,96.0760008 L173.235001,89.4781247 C173.469169,89.3317695 173.777644,
        89.4029561 173.923999,89.6371244 C173.973665,89.7165902 174,89.808414 174,89.9021238
        L174,103.097876 C174,103.374019 173.776142,103.597876 173.5,103.597876 C173.40629,
        103.597876 173.314466,103.571541 173.235001,103.521875 L162.678399,96.9239992
        C162.44423,96.777644 162.373044,96.4691688 162.519399,96.2350005 C162.559639,
        96.170616 162.614014,96.1162412 162.678399,96.0760008 Z"
        transform="matrix(-1 0 0 1 336 0)"
      />
      <polygon
        className={styles.action}
        fill="currentColor"
        points="186 95 236 95 236 99 186 99"
      />
      <polygon
        className={styles.neutral10}
        fill="currentColor"
        points="126 64 130 64 130 128 126 128"
      />
      <polygon
        className={styles.neutral10}
        fill="currentColor"
        points="250 64 254 64 254 128 250 128"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M57.6783986 96.0760008L68.2350005 89.4781247C68.4691688 89.3317695 68.777644
        89.4029561 68.9239992 89.6371244 68.9736653 89.7165902 69 89.808414 69 89.9021238L69
        103.097876C69 103.374019 68.7761424 103.597876 68.5 103.597876 68.4062901 103.597876
        68.3144663 103.571541 68.2350005 103.521875L57.6783986 96.9239992C57.4442304 96.777644
        57.3730438 96.4691688 57.519399 96.2350005 57.5596393 96.170616 57.6140141 96.1162412
        57.6783986 96.0760008zM181.678399 96.0760008L192.235001 89.4781247C192.469169
        89.3317695 192.777644 89.4029561 192.923999 89.6371244 192.973665 89.7165902
        193 89.808414 193 89.9021238L193 103.097876C193 103.374019 192.776142 103.597876
        192.5 103.597876 192.40629 103.597876 192.314466 103.571541 192.235001
        103.521875L181.678399 96.9239992C181.44423 96.777644 181.373044 96.4691688
        181.519399 96.2350005 181.559639 96.170616 181.614014 96.1162412 181.678399 96.0760008z"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M112.678399,96.0760008 L123.235001,89.4781247 C123.469169,89.3317695 123.777644,
        89.4029561 123.923999,89.6371244 C123.973665,89.7165902 124,89.808414 124,89.9021238
        L124,103.097876 C124,103.374019 123.776142,103.597876 123.5,103.597876 C123.40629,
        103.597876 123.314466,103.571541 123.235001,103.521875 L112.678399,96.9239992 C112.44423,
        96.777644 112.373044,96.4691688 112.519399,96.2350005 C112.559639,96.170616 112.614014,
        96.1162412 112.678399,96.0760008 Z"
        transform="matrix(-1 0 0 1 236 0)"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M236.678399,96.0760008 L247.235001,89.4781247 C247.469169,89.3317695 247.777644,
        89.4029561 247.923999,89.6371244 C247.973665,89.7165902 248,89.808414 248,89.9021238
        L248,103.097876 C248,103.374019 247.776142,103.597876 247.5,103.597876 C247.40629,
        103.597876 247.314466,103.571541 247.235001,103.521875 L236.678399,96.9239992
        C236.44423,96.777644 236.373044,96.4691688 236.519399,96.2350005 C236.559639,
        96.170616 236.614014,96.1162412 236.678399,96.0760008 Z"
        transform="matrix(-1 0 0 1 484 0)"
      />
    </g>
  );
};

export default Division;
