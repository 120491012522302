import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Layering = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral9}
        width="212"
        height="146"
        x="46"
        y="24"
        fill="currentColor"
        fillOpacity=".03"
        rx="73"
      />
      <rect
        className={styles.neutral9}
        width="200"
        height="132"
        x="52"
        y="30"
        fill="currentColor"
        fillOpacity=".03"
        rx="66"
      />
      <rect
        className={styles.white}
        width="192"
        height="124"
        x="56"
        y="34"
        fill="currentColor"
        rx="62"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M154.644114,105.131357 C153.462354,106.289548 151.546342,106.289548 150.364581,105.131357
        L144.709226,99.5887957 L97.026087,99.5887957 C95.3548253,99.5887957 94,98.2609918 94,96.6230627 L94,66.965733
        C94,65.3278039 95.3548253,64 97.026087,64 L206.973913,64 C208.645175,64 210,65.3278039 210,66.965733
        L210,96.6230627 C210,98.2609918 208.645175,99.5887957 206.973913,99.5887957 L160.299469,99.5887957
        L154.644114,105.131357 Z"
      />
      <circle
        className={styles.neutral2}
        cx="153"
        cy="121"
        r="9"
        fill="#EDF0F2"
      />
    </g>
  );
};

export default Layering;
