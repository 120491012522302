import React from 'react';
import {
  IconAndroid,
  IconApple,
  IconReactNative,
  IconWeb,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import styles from './PlatformExceptionList.module.scss';

const PlatformExceptionItem = ({ platform, children }) => {
  let platformIcon;

  switch (platform) {
    case 'web':
      platformIcon = <IconWeb className={styles.iconWeb} />;
      break;
    case 'reactnative':
      platformIcon = <IconReactNative className={styles.iconReactNative} />;
      break;
    case 'android':
      platformIcon = <IconAndroid className={styles.iconAndroid} />;
      break;
    case 'apple':
      platformIcon = <IconApple className={styles.iconApple} />;
      break;
    case 'other':
      platformIcon = 'Other';
      break;
    default:
      platformIcon = <IconWeb className={styles.iconWeb} />;
  }

  return (
    <li className={styles.platformExceptionItem}>
      {platformIcon}
      <Text>{children}</Text>
    </li>
  );
};

export default PlatformExceptionItem;
