import React from 'react';

const Environment = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M54,47 L136,47 C140.418278,47 144,50.581722 144,55 L144,137 C144,141.418278 140.418278,145 136,145 L54,145
        C49.581722,145 46,141.418278 46,137 L46,55 C46,50.581722 49.581722,47 54,47 Z"
      />
      <path
        fill="#13293F"
        fillOpacity=".1"
        fillRule="nonzero"
        d="M54,48 C50.1340068,48 47,51.1340068 47,55 L47,137 C47,140.865993 50.1340068,144 54,144 L136,144
        C139.865993,144 143,140.865993 143,137 L143,55 C143,51.1340068 139.865993,48 136,48 L54,48 Z M54,47 L136,47
        C140.418278,47 144,50.581722 144,55 L144,137 C144,141.418278 140.418278,145 136,145 L54,145 C49.581722,145
        46,141.418278 46,137 L46,55 C46,50.581722 49.581722,47 54,47 Z"
      />
      <path
        fill="#F9FAFB"
        d="M54,48 L77,48 L77,144 L54,144 C50.1340068,144 47,140.865993 47,137 L47,55 C47,51.1340068 50.1340068,48 54,48
        Z"
      />
      <rect width="37" height="6" x="83" y="65" fill="#717D8A" rx="3" />
      <rect width="19" height="4" x="52" y="57" fill="#717D8A" rx="2" />
      <rect width="4" height="4" x="54" y="65" fill="#DFE5EB" rx="2" />
      <rect width="12" height="4" x="60" y="65" fill="#DFE5EB" rx="2" />
      <rect width="4" height="4" x="54" y="73" fill="#DFE5EB" rx="2" />
      <rect width="12" height="4" x="60" y="73" fill="#DFE5EB" rx="2" />
      <rect width="4" height="4" x="54" y="81" fill="#DFE5EB" rx="2" />
      <rect width="12" height="4" x="60" y="81" fill="#DFE5EB" rx="2" />
      <rect width="53" height="4" x="83" y="75" fill="#B7BFC7" rx="2" />
      <rect width="18" height="4" x="83" y="82" fill="#B7BFC7" rx="2" />
      <rect width="41" height="6" x="83" y="103" fill="#717D8A" rx="3" />
      <rect width="50" height="4" x="83" y="113" fill="#B7BFC7" rx="2" />
      <rect width="36" height="4" x="83" y="120" fill="#B7BFC7" rx="2" />
      <path
        fill="#101417"
        d="M168,47 L250,47 C254.418278,47 258,50.581722 258,55 L258,137 C258,141.418278 254.418278,145 250,145 L168,145
        C163.581722,145 160,141.418278 160,137 L160,55 C160,50.581722 163.581722,47 168,47 Z"
      />
      <path
        fill="#E6F2FF"
        fillOpacity=".1"
        fillRule="nonzero"
        d="M168,48 C164.134007,48 161,51.1340068 161,55 L161,137 C161,140.865993 164.134007,144 168,144 L250,144
        C253.865993,144 257,140.865993 257,137 L257,55 C257,51.1340068 253.865993,48 250,48 L168,48 Z M168,47 L250,47
        C254.418278,47 258,50.581722 258,55 L258,137 C258,141.418278 254.418278,145 250,145 L168,145 C163.581722,145
        160,141.418278 160,137 L160,55 C160,50.581722 163.581722,47 168,47 Z"
      />
      <path
        fill="#191F24"
        d="M168,48 L191,48 L191,144 L168,144 C164.134007,144 161,140.865993 161,137 L161,55 C161,51.1340068
        164.134007,48 168,48 Z"
      />
      <rect width="37" height="6" x="197" y="65" fill="#8D97A1" rx="3" />
      <rect width="19" height="4" x="166" y="57" fill="#8D97A1" rx="2" />
      <rect width="4" height="4" x="168" y="65" fill="#353F4A" rx="2" />
      <rect width="12" height="4" x="174" y="65" fill="#353F4A" rx="2" />
      <rect width="4" height="4" x="168" y="73" fill="#353F4A" rx="2" />
      <rect width="12" height="4" x="174" y="73" fill="#353F4A" rx="2" />
      <rect width="4" height="4" x="168" y="81" fill="#353F4A" rx="2" />
      <rect width="12" height="4" x="174" y="81" fill="#353F4A" rx="2" />
      <rect width="53" height="4" x="197" y="75" fill="#404D59" rx="2" />
      <rect width="18" height="4" x="197" y="82" fill="#404D59" rx="2" />
      <rect width="41" height="6" x="197" y="103" fill="#8D97A1" rx="3" />
      <rect width="50" height="4" x="197" y="113" fill="#404D59" rx="2" />
      <rect width="36" height="4" x="197" y="120" fill="#404D59" rx="2" />
    </g>
  );
};

export default Environment;
