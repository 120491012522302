import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const TextInput = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral3}
        width="226"
        height="52"
        x="39"
        y="78"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.level0}
        width="222"
        height="48"
        x="41"
        y="80"
        fill="currentColor"
        rx="14"
      />
      <rect
        className={styles.neutral5}
        width="78"
        height="16"
        x="39"
        y="54"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral10}
        width="3"
        height="30"
        x="53"
        y="89"
        fill="currentColor"
      />
    </g>
  );
};

export default TextInput;
