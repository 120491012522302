import React from 'react';
import Helmet from 'react-helmet';
import { Headline, Icon, KeyboardShortcut } from 'hudl-uniform-ui-components';
import {
  DesignCodeSwitcher,
  IconSubNavMenu,
  ShowHideComponent,
  SubNav,
  SubNavVersion,
} from 'docComponents';
import styles from './PageTitle.module.scss';
import subNavstyles from '../subnav/SubNav.module.scss';

const pageSections = [];
const platformTabs = [];
let currentSection = 0;
let currentTab = 0;

export default class PageTitle extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keydown', this.handleKeyDown);
      const pageNav = document.getElementById('pageNavigation');
      if (pageNav) {
        pageNav.childNodes.forEach(child =>
          pageSections.push(child.getAttribute('href'))
        );
      }

      const platformTabsEl = document.getElementById('platformTabs');
      if (platformTabsEl) {
        platformTabsEl.childNodes.forEach(child =>
          platformTabs.push(child.getAttribute('id'))
        );
      }
    }
  }

  handleKeyDown(event) {
    const codeEditors = document.getElementsByClassName('prism-code');
    let isCodeEditorActive = false;
    if (codeEditors.length > 0) {
      for (const editor of codeEditors) {
        if (editor === document.activeElement) {
          isCodeEditorActive = true;
        }
      }
    }

    if (isCodeEditorActive) {
      event.stopPropagation();
    } else {
      switch (event.key) {
        case 'c': {
          if (!event.metaKey && !event.ctrlKey) {
            document.getElementById('codeSideToggle').click();
          }
          break;
        }
        case 'd': {
          document.getElementById('designSideToggle').click();
          break;
        }
        case '/': {
          const toggles = document.getElementsByName(
            'previewEnvironmentToggle'
          );
          toggles.forEach(toggle => toggle.click());
          break;
        }
        case ',': {
          if (currentSection !== 0) {
            currentSection = currentSection - 1;
            document
              .getElementById(pageSections[currentSection].replace('#', ''))
              .scrollIntoView(true);
          }
          break;
        }
        case '.': {
          if (currentSection !== pageSections.length - 1) {
            currentSection = currentSection + 1;
            document
              .getElementById(pageSections[currentSection].replace('#', ''))
              .scrollIntoView(true);
          }
          break;
        }
        case '[': {
          if (currentTab !== 0) {
            currentTab = currentTab - 1;
            document.getElementById(platformTabs[currentTab]).click();
          }
          break;
        }
        case ']': {
          if (currentTab !== platformTabs.length - 1) {
            currentTab = currentTab + 1;
            document.getElementById(platformTabs[currentTab]).click();
          }
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  addCloseClass = (toggle, opened) => {
    if (opened) {
      const el = document.getElementsByClassName('subnav--mobile')[0];

      el.addEventListener('webkitAnimationEnd', () => {
        toggle();
      });
      el.addEventListener('animationend', () => {
        toggle();
      });
      el.classList.add(subNavstyles.subNavMobile_close);
    } else {
      toggle();
    }
  };

  render() {
    const {
      pageTitle,
      designCode,
      showMobileSubnav,
      isMobileOnly,
      onSwitch,
    } = this.props;

    return (
      <div className={styles.pageTitleContainer}>
        <Helmet title={pageTitle + ' | Hudl Uniform'} />

        {showMobileSubnav && (
          <ShowHideComponent>
            {({ isOpen, toggle }) => (
              <div className={subNavstyles.subNavContainerMobile}>
                <button
                  className={subNavstyles.subNavTriggerMobile}
                  onClick={this.addCloseClass.bind(this, toggle, isOpen)}>
                  <IconSubNavMenu className={subNavstyles.subNavMenuIcon} />
                </button>
                <Headline level="2" className="uni-pad--half--horiz">
                  /
                </Headline>

                {isOpen && (
                  <div
                    className={'subnav--mobile ' + subNavstyles.subNavMobile}>
                    <SubNav />
                    <div
                      className={
                        'uni-bg--level2 ' + subNavstyles.subNavMobileBottom
                      }>
                      <SubNavVersion />
                    </div>
                  </div>
                )}
              </div>
            )}
          </ShowHideComponent>
        )}

        <div className={subNavstyles.subNavTierThreeContainer}>
          <Headline level="2">{pageTitle}</Headline>
          {isMobileOnly && (
            <div
              className={subNavstyles.subNavTierThreeMobileBadge}
              style={{ marginLeft: 16 }}>
              MOBILE-ONLY
            </div>
          )}
        </div>

        {designCode && (
          <div className={styles.pageTitleSwitcher}>
            <DesignCodeSwitcher onSwitch={onSwitch} />
          </div>
        )}

        <div className={styles.keyIconContainer}>
          <Icon size="large" type="keyboardshortcuts" />
          <div className={'uni-bg--level1 ' + styles.keyContainer}>
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">d</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Design</div>
            </div>
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">c</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Code</div>
            </div>
            <div className={styles.keyDivider} />
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">,</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Previous Section</div>
            </div>
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">.</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Next Section</div>
            </div>
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">/</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>
                Toggle Preview Environment
              </div>
            </div>
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">[</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Previous Platform</div>
            </div>
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">]</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Next Platform</div>
            </div>
            <div className={styles.keyDivider} />
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">command</KeyboardShortcut> +{' '}
                <KeyboardShortcut qaId="shortcut">up</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Scroll to Top</div>
            </div>
            <div className={styles.keyRow}>
              <div className={styles.key}>
                <KeyboardShortcut qaId="shortcut">command</KeyboardShortcut> +{' '}
                <KeyboardShortcut qaId="shortcut">down</KeyboardShortcut>
              </div>
              <div className={styles.keyDescription}>Scroll to Bottom</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
