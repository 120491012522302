import React from 'react';
import {
  getPlatformTabPreference,
  setPlatformTabPreference,
} from '../../utility/js/platformTabPreference';
import {
  IconAndroid,
  IconApple,
  IconReactNative,
  IconWeb,
} from 'docComponents';
import styles from './PlatformTabs.module.scss';

export default class PlatformTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '',
    };
  }

  componentWillMount() {
    let pref = 'web';

    // If the user is coming from a url with a platform string at the end ('?web')
    // then we should navigate to that tab. Otherwise, we should use whatever
    // they have stored in localstorage.
    if (typeof window !== 'undefined') {
      if (window.location && window.location.search) {
        pref = window.location.search.replace('?', '');
      } else {
        pref = getPlatformTabPreference();
      }
    }

    pref = pref === 'ios' ? 'apple' : pref;

    this.setState({ activeTab: pref });
  }

  handlePlatformSelection(selection) {
    this.setState({ activeTab: selection });
    setPlatformTabPreference(selection);

    // Let's update the url so that people can copy paste the url to share and
    // it goes to the right tab. history.pushstate won't reload the page.
    if (typeof window !== 'undefined') {
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        selection;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  determineTabToShow(activeTab, tabs) {
    return tabs.find(tab => tab.props.type === activeTab);
  }

  render() {
    const { activeTab } = this.state;

    return (
      <div className={styles.platformTabs}>
        <div id="platformTabs" className={styles.tabContainer}>
          <div
            id="webTab"
            className={`${styles.webTab} ${
              activeTab === 'web' ? styles.activeTab : null
            }`}
            onClick={this.handlePlatformSelection.bind(this, 'web')}>
            <IconWeb />
            Web
          </div>

          <div
            id="reactNativeTab"
            className={`${styles.reactNativeTab} ${
              activeTab === 'reactnative' ? styles.activeTab : null
            }`}
            onClick={this.handlePlatformSelection.bind(this, 'reactnative')}>
            <IconReactNative />
            React Native
          </div>

          <div
            id="androidTab"
            className={`${styles.androidTab} ${
              activeTab === 'android' ? styles.activeTab : null
            }`}
            onClick={this.handlePlatformSelection.bind(this, 'android')}>
            <IconAndroid />
            Android
          </div>

          <div
            id="appleTab"
            className={`${styles.appleTab} ${
              activeTab === 'apple' ? styles.activeTab : null
            }`}
            onClick={this.handlePlatformSelection.bind(this, 'apple')}>
            <IconApple />
            Apple
          </div>

          <div
            id="otherTab"
            className={`${styles.otherTab} ${
              activeTab === 'other' ? styles.activeTab : null
            }`}
            onClick={this.handlePlatformSelection.bind(this, 'other')}>
            Other
          </div>
        </div>
        {this.determineTabToShow(activeTab, this.props.children)}
      </div>
    );
  }
}
