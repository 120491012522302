import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ColorsIdentity = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect width="32" height="32" x="216" y="120" fill="#1F4615" rx="6" />
      <rect width="32" height="32" x="176" y="120" fill="#2B7834" rx="6" />
      <rect width="32" height="32" x="136" y="120" fill="#332064" rx="6" />
      <rect width="32" height="32" x="96" y="120" fill="#0F2A4B" rx="6" />
      <rect width="32" height="32" x="56" y="120" fill="#1652AE" rx="6" />
      <rect width="32" height="32" x="216" y="80" fill="#68B2D8" rx="6" />
      <rect width="32" height="32" x="176" y="80" fill="#232525" rx="6" />
      <rect width="32" height="32" x="136" y="80" fill="#7A1700" rx="6" />
      <rect width="32" height="32" x="96" y="80" fill="#C20000" rx="6" />
      <rect width="32" height="32" x="56" y="80" fill="#F30000" rx="6" />
      <rect width="32" height="32" x="216" y="40" fill="#F87620" rx="6" />
      <rect width="32" height="32" x="176" y="40" fill="#C5B358" rx="6" />
      <rect width="32" height="32" x="136" y="40" fill="#FD0" rx="6" />
      <rect width="32" height="32" x="96" y="40" fill="#E9E9E9" rx="6" />
      <rect width="32" height="32" x="56" y="40" fill="#232A31" rx="6" />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="216.5"
        y="120.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="176.5"
        y="120.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="136.5"
        y="120.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="96.5"
        y="120.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="56.5"
        y="120.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="216.5"
        y="80.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="176.5"
        y="80.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="136.5"
        y="80.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="96.5"
        y="80.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="56.5"
        y="80.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="216.5"
        y="40.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="176.5"
        y="40.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="136.5"
        y="40.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="96.5"
        y="40.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
      <rect
        className={styles.outline}
        width="31"
        height="31"
        x="56.5"
        y="40.5"
        fill="none"
        stroke="currentColor"
        rx="6"
      />
    </g>
  );
};

export default ColorsIdentity;
