import React from 'react';
import { Link } from 'gatsby';
import styles from './ItemsList.module.scss';

const ItemNonVisual = ({ text, description, path }) => {
  let descriptionText;

  // If the description contains links, we need to split them out into the
  // appropriate Link react component
  if (RegExp(/(\[[^)]+\])(\([^)]+\))/g).test(description)) {
    let i = 0; // holds the iteration of the link
    const linksArray = []; // contains all of the links in the description

    const linksReplacedString = description.replace(
      /\[(.*?)\]\((.*?)\)/g,
      (p1, p2) => {
        const linkDescription = p2;

        linksArray.push(linkDescription);

        // This gives us a unique string we can replace with our link
        return '!' + i++ + '!';
      }
    );

    // We need to split the string up by the Links above
    const splitDescription = linksReplacedString.split(/(!\d!)/g);
    descriptionText = [];

    for (let j = 0; j < splitDescription.length; j++) {
      // Starts with a ! means it's a Link that needs replacing.
      if (splitDescription[j].indexOf('!') === 0) {
        // Pull out the number because that's the index of the link in the linksArray
        const number = splitDescription[j].replace('!', '').replace('!', '');
        descriptionText.push(linksArray[number]);
      } else {
        // Just text, so push it on to the description
        descriptionText.push(splitDescription[j]);
      }
    }
  } else {
    descriptionText = description;
  }

  return (
    <Link
      className={styles.itemLink + ' ' + styles.itemContainerNonVisual}
      to={path}>
      <h4 className={styles.itemTitle}>{text}</h4>
      <p className={styles.itemDescription}>{descriptionText}</p>
    </Link>
  );
};

export default ItemNonVisual;
