import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchOval = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M53.1388603,63.285418 L89.6126217,42.227282 M106.186229,37.2801864 L44.8735023,72.6791055
      M40.7810415,79.6621313 L118.581121,34.7442346 M129.151344,33.263194 L38.5126011,85.5934968 M37.3630016,90.8774609
      L138.596911,32.4300358 M147.254059,32.0535102 L37.0011713,95.7080445 M37.2424018,100.189013
      L155.304355,32.0259119 M162.865877,32.2819459 L37.9783102,104.385816 M39.1351536,108.338155
      L170.005868,32.7799129 M176.781449,33.4897101 L40.6598837,112.079533 M42.5110154,115.631024
      L183.228312,34.3878547 M189.38133,35.4570891 L44.6584848,119.012862 M47.076594,122.237009 L195.260841,36.6827943
      M200.889602,38.0547081 L49.7476618,125.316548 M52.65489,128.258302 L206.279221,39.563253 M211.444964,41.2024905
      L55.788193,131.070969 M59.1364088,133.758118 L216.392873,42.9660564 M221.133031,44.8510058 L62.6946815,136.325429
      M66.4558504,138.77416 L225.667507,46.8532671 M230.002356,48.9722216 L70.4191072,141.107653 M74.5805159,143.325306
      L234.136434,51.2056532 M238.072247,53.5549916 L78.9428209,145.428408 M83.505087,147.414625 L241.805649,56.0197532
      M245.335578,58.6034287 L88.2736227,149.283191 M93.2507389,151.029894 L248.65463,61.3074158 M251.757638,64.1375741
      L98.4468277,152.651611 M103.868249,154.141795 L254.633118,67.0976574 M257.270557,70.1966117
      L109.530692,155.494263 M115.446238,156.699163 L259.652678,73.4415363 M261.761141,76.8458953
      L121.638289,157.745862 M128.127966,158.619287 L263.569448,80.4221116 M265.046397,84.1910752 L134.95125,159.301543
      M142.145296,159.768302 L266.148992,88.1747346 M266.821916,92.4079024 L149.769258,159.988286 M157.894565,159.91738
      L266.988048,96.9322282 M266.531277,101.817626 L166.647098,159.485784 M176.222222,158.577826
      L265.252564,107.176134 M262.789549,113.219837 L186.987463,156.984192 M199.739526,154.242027
      L258.340361,120.408819 M248.898229,130.481918 L217.490351,148.615265"
    />
  );
};

export default HatchOval;
