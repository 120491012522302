import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Buttons = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral9}
        fill="currentColor"
        fillOpacity=".03"
        d="M125.638852,44 L178.361148,44 C187.276335,44 190.509198,44.9282561 193.768457,46.671327
        C197.027716,48.414398 199.585602,50.9722837 201.328673,54.231543 C203.071744,57.4908022
        204,60.7236646 204,69.638852 L204,122.361148 C204,131.276335 203.071744,134.509198
        201.328673,137.768457 C199.585602,141.027716 197.027716,143.585602 193.768457,145.328673
        C190.509198,147.071744 187.276335,148 178.361148,148 L125.638852,148 C116.723665,148
        113.490802,147.071744 110.231543,145.328673 C106.972284,143.585602 104.414398,141.027716
        102.671327,137.768457 C100.928256,134.509198 100,131.276335 100,122.361148 L100,69.638852
        C100,60.7236646 100.928256,57.4908022 102.671327,54.231543 C104.414398,50.9722837 106.972284,
        48.414398 110.231543,46.671327 C113.490802,44.9282561 116.723665,44 125.638852,44 Z"
      />
      <path
        className={styles.neutral9}
        fill="currentColor"
        fillOpacity=".03"
        d="M119.638852,38 L184.361148,38 C193.276335,38 196.509198,38.9282561 199.768457,40.671327
        C203.027716,42.414398 205.585602,44.9722837 207.328673,48.231543 C209.071744,51.4908022
        210,54.7236646 210,63.638852 L210,128.361148 C210,137.276335 209.071744,140.509198
        207.328673,143.768457 C205.585602,147.027716 203.027716,149.585602 199.768457,151.328673
        C196.509198,153.071744 193.276335,154 184.361148,154 L119.638852,154 C110.723665,154
        107.490802,153.071744 104.231543,151.328673 C100.972284,149.585602 98.414398,147.027716
        96.671327,143.768457 C94.9282561,140.509198 94,137.276335 94,128.361148 L94,63.638852
        C94,54.7236646 94.9282561,51.4908022 96.671327,48.231543 C98.414398,44.9722837
        100.972284,42.414398 104.231543,40.671327 C107.490802,38.9282561 110.723665,38 119.638852,38 Z"
      />
      <path
        fill="#009CE3"
        d="M122.383311,51 L181.616689,51 C186.965801,51 188.905519,51.5569537 190.861074,
        52.6027962 C192.81663,53.6486388 194.351361,55.1833702 195.397204,57.1389258
        C196.443046,59.0944813 197,61.0341988 197,66.3833112 L197,125.616689 C197,
        130.965801 196.443046,132.905519 195.397204,134.861074 C194.351361,136.81663
        192.81663,138.351361 190.861074,139.397204 C188.905519,140.443046 186.965801,
        141 181.616689,141 L122.383311,141 C117.034199,141 115.094481,140.443046 113.138926,
        139.397204 C111.18337,138.351361 109.648639,136.81663 108.602796,134.861074 C107.556954,
        132.905519 107,130.965801 107,125.616689 L107,66.3833112 C107,61.0341988 107.556954,
        59.0944813 108.602796,57.1389258 C109.648639,55.1833702 111.18337,53.6486388 113.138926,
        52.6027962 C115.094481,51.5569537 117.034199,51 122.383311,51 Z"
      />
      <path
        fill="#FFF"
        d="M158,90 L174,90 C175.104569,90 176,90.8954305 176,92 L176,100 C176,101.104569 175.104569,
        102 174,102 L158,102 L158,118 C158,119.104569 157.104569,120 156,120 L148,120 C146.895431,
        120 146,119.104569 146,118 L146,102 L130,102 C128.895431,102 128,101.104569 128,100 L128,
        92 C128,90.8954305 128.895431,90 130,90 L146,90 L146,74 C146,72.8954305 146.895431,72 148,
        72 L156,72 C157.104569,72 158,72.8954305 158,74 L158,90 Z"
      />
    </g>
  );
};

export default Buttons;
