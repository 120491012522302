import React from 'react';
import { Icon } from 'hudl-uniform-ui-components';
import { IconDribbble, IconMedium, IconTwitter, Link } from 'docComponents';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footer__linkContent}>
        <div className={styles.footer__leftSide}>
          <Icon type="logomark" className={'uni-icon ' + styles.footer__logo} />
          <table className={styles.footer__linkTable}>
            <thead>
              <tr>
                <th colSpan="2">Uniform</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link type="implied" href="/get-started">
                    Get Started
                  </Link>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <Link type="implied" href="/guidelines">
                    Visual Guidelines
                  </Link>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <Link type="implied" href="/components">
                    Components
                  </Link>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <Link type="implied" href="/words/">
                    Words
                  </Link>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <Link type="implied" href="/resources/">
                    Resources
                  </Link>
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <Link type="implied" href="http://www.hudl.com/jobs">
                    Apply @ Hudl
                  </Link>
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.footer__rightSide}>
          <Link type="wrapper" target="_blank" href="https://twitter.com/hudl">
            <IconTwitter className={styles.footer__icon} />
          </Link>
          <Link type="wrapper" target="_blank" href="https://dribbble.com/hudl">
            <IconDribbble className={styles.footer__icon} />
          </Link>
          <Link
            type="wrapper"
            target="_blank"
            href="https://medium.com/in-the-hudl">
            <IconMedium className={styles.footer__icon} />
          </Link>
        </div>
      </div>

      <div className={styles.footer__copyright}>
        &copy; Agile Sports Technologies, Inc. dba{' '}
        <Link type="implied" target="_blank" href="https://www.hudl.com">
          Hudl
        </Link>{' '}
        2016-2018
      </div>
    </footer>
  );
};

export default Footer;
