import React from 'react';
import { SectionHeadline } from 'docComponents';
import styles from './Section.module.scss';

const Section = ({ children, title }) => {
  return (
    <section className={styles.section}>
      <span id={title} />
      {title && <SectionHeadline url={title}>{title}</SectionHeadline>}
      {children}
    </section>
  );
};

export default Section;
