import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ColorsContent = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.contentContrast}
        width="64"
        height="64"
        x="82"
        y="26"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.contentDefault}
        width="64"
        height="64"
        x="158"
        y="26"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.contentSubtle}
        width="64"
        height="64"
        x="82"
        y="102"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.contentNonessential}
        width="64"
        height="64"
        x="158"
        y="102"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="82.5"
        y="26.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="82.5"
        y="102.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="158.5"
        y="102.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="158.5"
        y="26.5"
        stroke="currentColor"
        rx="16"
      />
    </g>
  );
};

export default ColorsContent;
