import React from 'react';
import styles from './DataTable.module.scss';

const DataTableColorBlock = ({ value }) => {
  return (
    <td className={styles.dataTableColorBlock}>
      <div className={styles.dataTableColorBlock__block} style={{ backgroundColor: 'rgba(' + value + ')' }} />
    </td>
  );
};

export default DataTableColorBlock;
