import React from 'react';

const UserAvatar = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <circle cx="152" cy="96" r="64" fill="#353F4A" />
      <path
        fill="#7B8692"
        d="M104.892068,139.323056 C109.352339,132.427519 116.597066,126.962622 125.577808,123.378046
        C133.050353,128.75802 142.217253,131.921427 152.118375,131.921427 C162.015152,131.921427 171.177709,128.75802
        178.654598,123.378046 C187.568449,126.937317 194.774937,132.349474 199.243475,139.175287 C187.541268,151.973021
        170.708035,160 152,160 C133.364074,160 116.588525,152.034781 104.892068,139.323056 Z M117.362353,86.1065632
        C117.362353,66.8294137 132.920018,51.2 152.118375,51.2 C171.312388,51.2 186.870052,66.8294137
        186.870052,86.1065632 C186.870052,105.388076 171.312388,121.013126 152.118375,121.013126 C132.920018,121.013126
        117.362353,105.388076 117.362353,86.1065632 Z"
      />
      <circle
        cx="152"
        cy="96"
        r="62.5"
        stroke="#E6F2FF"
        strokeOpacity=".06"
        strokeWidth="3"
      />
    </g>
  );
};

export default UserAvatar;
