import React from 'react';
import styles from '../../SvgIllustration.module.scss';
/* eslint-disable */
const ActionBar = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral9}
        width="270"
        height="64"
        x="17"
        y="64"
        fill="currentColor"
        fillOpacity=".03"
        rx="20"
      />
      <rect
        className={styles.neutral9}
        width="255"
        height="54"
        x="25"
        y="69"
        fill="currentColor"
        fillOpacity=".03"
        rx="16"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M40.8,72 L264.2,72 C271.269245,72 277,77.7307552 277,84.8 L277,107.2 C277,114.269245 271.269245,120 264.2,120 L40.8,120 C33.7307552,120 28,114.269245 28,107.2 L28,84.8 C28,77.7307552 33.7307552,72 40.8,72 Z"
      />
      <path
        className={styles.white}
        fill="currentColor"
        fillOpacity=".8"
        d="M256.035534,94.9142136 L258.510408,92.4393398 C259.096194,91.8535534 260.045942,91.8535534 260.631728,92.4393398 C261.217514,93.0251263 261.217514,93.9748737 260.631728,94.5606602 L258.156854,97.0355339 L260.631728,99.5104076 C261.217514,100.096194 261.217514,101.045942 260.631728,101.631728 C260.045942,102.217514 259.096194,102.217514 258.510408,101.631728 L256.035534,99.1568542 L253.56066,101.631728 C252.974874,102.217514 252.025126,102.217514 251.43934,101.631728 C250.853553,101.045942 250.853553,100.096194 251.43934,99.5104076 L253.914214,97.0355339 L251.43934,94.5606602 C250.853553,93.9748737 250.853553,93.0251263 251.43934,92.4393398 C252.025126,91.8535534 252.974874,91.8535534 253.56066,92.4393398 L256.035534,94.9142136 Z"
      />
      <rect
        className={styles.white}
        width="54"
        height="12"
        x="40"
        y="90"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.white}
        width="37"
        height="12"
        x="181"
        y="90"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.white}
        width="37"
        height="12"
        x="132"
        y="90"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.white}
        width="2"
        height="48"
        x="234"
        y="72"
        fill="currentColor"
        fillOpacity=".2"
      />
    </g>
  );
};

export default ActionBar;
