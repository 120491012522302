import React from 'react';
import styles from '../../SvgIllustration.module.scss';
/* eslint-disable */
const ActionList = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <g transform="translate(102 58)">
        <path
          className={styles.neutral9}
          fill="currentColor"
          fillOpacity=".03"
          d="M16.0527344,0.753326854 L85.4472656,0.753326854 C94.2838216,0.753326854 101.447266,7.91677086 101.447266,16.7533269 L101.447266,99.2466731 C101.447266,108.083229 94.2838216,115.246673 85.4472656,115.246673 L16.0527344,115.246673 C7.21617838,115.246673 0.052734375,108.083229 0.052734375,99.2466731 L0.052734375,16.7533269 C0.052734375,7.91677086 7.21617838,0.753326854 16.0527344,0.753326854 Z"
        />
        <rect
          width="93"
          height="106"
          x="4"
          y="5"
          fill="currentColor"
          className={styles.neutral9}
          fillOpacity=".03"
          rx="12"
        />
        <rect
          width="89"
          height="102"
          x="6"
          y="7"
          fill="currentColor"
          className={styles.white}
          rx="10"
        />
        <rect
          width="61"
          height="10"
          x="14"
          y="19"
          fill="currentColor"
          className={styles.neutral4}
          rx="5"
        />
        <rect
          width="45"
          height="10"
          x="14"
          y="40.333"
          fill="currentColor"
          className={styles.neutral4}
          rx="5"
        />
        <rect
          width="71"
          height="10"
          x="14"
          y="61.667"
          fill="currentColor"
          className={styles.neutral4}
          rx="5"
        />
        <rect
          width="56"
          height="10"
          x="14"
          y="90"
          fill="currentColor"
          className={styles.neutral4}
          rx="5"
        />
        <rect
          width="74"
          height="2"
          x="14"
          y="80"
          fill="currentColor"
          className={styles.neutral3}
          rx="1"
        />
      </g>
      <rect
        width="40"
        height="40"
        x="157"
        y="19"
        fill="currentColor"
        className={styles.action}
        rx="8"
      />
      <rect
        width="21"
        height="10"
        x="166"
        y="34"
        fill="currentColor"
        className={styles.white}
        rx="5"
      />
    </g>
  );
};

export default ActionList;
