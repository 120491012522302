import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Environment, Level } from 'hudl-uniform-ui-components';
import {
  Footer,
  Layout,
  PageTitle,
  SubNav,
  SubNavVersion,
} from 'docComponents';

import styles from './PageWithSubNav.module.scss';

export default class PageWithSubNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageState: '',
    };
  }

  getChildContext() {
    return {
      pageSubnav: this.props.subnav,
    };
  }

  componentWillMount() {
    this.setState({ pageState: 'mounted' });
  }

  handleOnSwitch(currentPref, newPref) {
    this.setState({ pageState: 'unmounting' });
    setTimeout(() => {
      navigate(location.pathname.replace(currentPref, newPref));
    }, 200);
  }

  determineEnv(pageState, pageType) {
    let env = '';

    // Reverse the environment if the page is unmounting
    if (pageState === 'unmounting') {
      env = pageType === 'design' ? 'dark' : 'light';
    } else {
      env = pageType === 'design' ? 'light' : 'dark';
    }

    return env;
  }

  render() {
    const { children, pageType, title, designCode, isMobileOnly } = this.props;

    return (
      <Layout>
        <div className={this.state.pageState}>
          <Environment env={this.determineEnv(this.state.pageState, pageType)}>
            <Level level="0">
              <div className={styles.gridLayout}>
                <div className={'uni-bg--level1 ' + styles.gridSecondary}>
                  <SubNav />
                  <div className={'uni-bg--level2 ' + styles.gridBottom}>
                    <SubNavVersion />
                  </div>
                </div>

                <div className={'uni-bg--level0 ' + styles.gridMainWrapper}>
                  <div className={styles.gridMain}>
                    <PageTitle
                      pageTitle={title}
                      designCode={designCode}
                      isMobileOnly={isMobileOnly}
                      showMobileSubnav
                      onSwitch={this.handleOnSwitch.bind(this)}
                    />
                    <div className={styles.contentWrapper}>{children}</div>
                    <Footer />
                  </div>
                </div>
              </div>
            </Level>
          </Environment>
        </div>
      </Layout>
    );
  }
}

PageWithSubNav.childContextTypes = {
  pageSubnav: PropTypes.string,
};
