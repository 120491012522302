import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const FormSection = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral5}
        fill="currentColor"
        fillRule="nonzero"
        d="M68 81.8181818C66.2427303 81.8181818 64.8181818 83.2427303 64.8181818 85L64.8181818 99C64.8181818 100.75727
        66.2427303 102.181818 68 102.181818L82 102.181818C83.7572697 102.181818 85.1818182 100.75727 85.1818182
        99L85.1818182 85C85.1818182 83.2427303 83.7572697 81.8181818 82 81.8181818L68 81.8181818zM68 78L82
        78C85.8659932 78 89 81.1340068 89 85L89 99C89 102.865993 85.8659932 106 82 106L68 106C64.1340068 106 61
        102.865993 61 99L61 85C61 81.1340068 64.1340068 78 68 78zM68 121.818182C66.2427303 121.818182 64.8181818
        123.24273 64.8181818 125L64.8181818 139C64.8181818 140.75727 66.2427303 142.181818 68 142.181818L82
        142.181818C83.7572697 142.181818 85.1818182 140.75727 85.1818182 139L85.1818182 125C85.1818182 123.24273
        83.7572697 121.818182 82 121.818182L68 121.818182zM68 118L82 118C85.8659932 118 89 121.134007 89 125L89 139C89
        142.865993 85.8659932 146 82 146L68 146C64.1340068 146 61 142.865993 61 139L61 125C61 121.134007 64.1340068 118
        68 118z"
      />
      <rect
        className={styles.neutral8}
        width="130"
        height="16"
        x="60"
        y="46"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral4}
        width="143"
        height="14"
        x="99"
        y="126"
        fill="currentColor"
        rx="7"
      />
      <rect
        className={styles.neutral4}
        width="162"
        height="14"
        x="99"
        y="86"
        fill="currentColor"
        rx="7"
      />
    </g>
  );
};

export default FormSection;
