import React from 'react';

const Notice = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fill="#DFE5EB"
        d="M104,63 L252,63 C261.388841,63 269,70.6111593 269,80 L269,112 C269,121.388841 261.388841,129 252,129 L104,129 L104,63 Z"
      />
      <path
        fill="#FFF"
        d="M104,64 L252,64 C260.836556,64 268,71.163444 268,80 L268,112 C268,120.836556 260.836556,128 252,128 L104,128 L104,64 Z"
      />
      <rect width="46" height="20" x="115" y="101" fill="#DFE5EB" rx="6" />
      <rect width="26" height="6" x="125" y="108" fill="#717D8A" rx="3" />
      <path
        fill="#45525E"
        d="M53,63 L104,63 L104,129 L53,129 C44.163444,129 37,121.836556 37,113 L37,79 C37,70.163444 44.163444,63 53,63 Z"
      />
      <path
        fill="#FFF"
        d="M55,96 C55,87.1771429 62.1782857,80 71,80 C79.8228571,80 87,87.1771429 87,96 C87,104.821714 79.8228571,112 71,112 C62.1782857,112 55,104.821714 55,96 Z M68.7142857,87.4285714 C68.7142857,88.6948571 69.7337143,89.7142857 71,89.7142857 C72.2662857,89.7142857 73.2857143,88.6948571 73.2857143,87.4285714 C73.2857143,86.1622857 72.2662857,85.1428571 71,85.1428571 C69.7337143,85.1428571 68.7142857,86.1622857 68.7142857,87.4285714 Z M69.1428571,105.139429 C69.1428571,105.764571 69.6514286,106.285714 70.2777143,106.285714 L71.7222857,106.285714 C72.3405714,106.285714 72.8571429,105.772571 72.8571429,105.139429 L72.8571429,93.7165714 C72.8571429,93.0925714 72.3485714,92.5714286 71.7222857,92.5714286 L70.2777143,92.5714286 C69.6594286,92.5714286 69.1428571,93.0845714 69.1428571,93.7165714 L69.1428571,105.139429 Z"
      />
      <rect width="109" height="10" x="115" y="74" fill="#455666" rx="5" />
      <rect width="84" height="8" x="115" y="88" fill="#B7BFC7" rx="4" />
      <path
        fill="#DFE5EB"
        d="M252,75.1715729 L254.474874,72.6966991 C255.06066,72.1109127 256.010408,72.1109127 256.596194,72.6966991 C257.181981,73.2824856 257.181981,74.232233 256.596194,74.8180195 L254.12132,77.2928932 L256.596194,79.767767 C257.181981,80.3535534 257.181981,81.3033009 256.596194,81.8890873 C256.010408,82.4748737 255.06066,82.4748737 254.474874,81.8890873 L252,79.4142136 L249.525126,81.8890873 C248.93934,82.4748737 247.989592,82.4748737 247.403806,81.8890873 C246.818019,81.3033009 246.818019,80.3535534 247.403806,79.767767 L249.87868,77.2928932 L247.403806,74.8180195 C246.818019,74.232233 246.818019,73.2824856 247.403806,72.6966991 C247.989592,72.1109127 248.93934,72.1109127 249.525126,72.6966991 L252,75.1715729 Z"
      />
    </g>
  );
};

export default Notice;
