import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ItemTitle = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral2}
        width="204"
        height="8"
        x="48"
        y="98"
        fill="currentColor"
        rx="4"
      />
      <rect
        className={styles.neutral8}
        width="74"
        height="20"
        x="47"
        y="70"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral2}
        width="140"
        height="8"
        x="48"
        y="114"
        fill="currentColor"
        rx="4"
      />
    </g>
  );
};

export default ItemTitle;
