import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSquare = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M80,28.1385846 L87.1682388,24 M95.1732247,24 L80,32.7602653 M80,37.3805079 L103.17572,24 M111.180705,24
      L80,42.0021887 M80,46.6224312 L119.1832,24 M127.188186,24 L80,51.244112 M80,55.8643546 L135.190681,24
      M143.195667,24 L80,60.4860353 M80,65.1062779 L151.198162,24 M159.203148,24 L80,69.7279586 M80,74.3482012
      L167.205643,24 M175.210628,24 L80,78.969882 M80,83.5901245 L183.213123,24 M191.218109,24 L80,88.2118053
      M80,92.8320478 L199.220604,24 M207.22559,24 L80,97.4537286 M80,102.073971 L215.228085,24 M223.233071,24
      L80,106.695652 M80,111.315894 L224,28.1774557 M224,32.7991365 L80,115.937575 M80,120.557818 L224,37.4193791
      M224,42.0410598 L80,125.179499 M80,129.799741 L224,46.6613024 M224,51.2829831 L80,134.421422 M80,139.041664
      L224,55.9032257 M224,60.5249065 L80,143.663345 M80,148.283588 L224,65.145149 M224,69.7668298 L80,152.905269
      M80,157.525511 L224,74.3870724 M224,79.0087531 L80,162.147192 M80,166.767434 L224,83.6289957 M224,88.2506764
      L85.8701197,168 M93.8726146,168 L224,92.870919 M224,97.4925998 L101.8776,168 M109.880095,168 L224,102.112842
      M224,106.734523 L117.885081,168 M125.887576,168 L224,111.354766 M224,115.976446 L133.892562,168 M141.895057,168
      L224,120.596689 M224,125.21837 L149.900043,168 M157.902538,168 L224,129.838612 M224,134.460293 L165.907524,168
      M173.910018,168 L224,139.080536 M224,143.702216 L181.915004,168 M189.917499,168 L224,148.322459 M224,152.94414
      L197.922485,168 M205.92498,168 L224,157.564382 M224,162.186063 L213.929966,168 M221.932461,168 L224,166.806306"
    />
  );
};

export default HatchSquare;
