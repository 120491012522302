import React from 'react';
import { navigate } from 'gatsby';
import { Environment, Level } from 'hudl-uniform-ui-components';
import { Footer, Layout, PageTitle } from 'docComponents';
import styles from './PageWithoutSubNav.module.scss';

export default class PageWithoutSubNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageState: '',
    };
  }

  componentWillMount() {
    this.setState({ pageState: 'mounted' });
  }

  handleOnSwitch(currentPref, newPref) {
    this.setState({ pageState: 'unmounting' });
    setTimeout(() => {
      navigate(location.pathname.replace(currentPref, newPref));
    }, 200);
  }

  determineEnv(pageState, pageType) {
    let env = '';

    // Reverse the environment if the page is unmounting
    if (pageState === 'unmounting') {
      env = pageType === 'design' ? 'dark' : 'light';
    } else {
      env = pageType === 'design' ? 'light' : 'dark';
    }

    return env;
  }

  render() {
    const { children, pageType, title, designCode } = this.props;

    return (
      <Layout>
        <div className={this.state.pageState}>
          <Environment env={this.determineEnv(this.state.pageState, pageType)}>
            <Level level="0">
              <div className={styles.wrapper}>
                <div className={'uni-bg--level0 ' + styles.mainWrapper}>
                  <div className={styles.main}>
                    <PageTitle
                      pageTitle={title}
                      designCode={designCode}
                      showMobileSubnav={false}
                      onSwitch={this.handleOnSwitch.bind(this)}
                    />
                    <div className={styles.contentWrapper}>{children}</div>
                    <Footer />
                  </div>
                </div>
              </div>
            </Level>
          </Environment>
        </div>
      </Layout>
    );
  }
}
