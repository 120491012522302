import React from 'react';
import styles from './DotSeparator.module.scss';

const DotSeparator = ({ addRightSpace }) => {
  const rightStyle = addRightSpace === true ? { marginRight: '1rem' } : null;

  return (
    <span style={rightStyle} className={styles.dotSeparator}>
      &#9679;
    </span>
  );
};

export default DotSeparator;
