import React from 'react';
import { IconAndroid, IconApple, IconReactNative, IconWeb, List } from 'docComponents';
import styles from './PlatformTable.module.scss';

const PlatformTableRow = ({ platform, children }) => {
  let platformIconContent;
  let platformTextContent;
  const content = children ? (
    children
  ) : (
    <List>
      <li>Stick to the guidelines above.</li>
    </List>
  );

  switch (platform) {
    case 'web':
      platformIconContent = <IconWeb className={styles.iconWeb} />;
      platformTextContent = 'Web';
      break;
    case 'reactnative':
      platformIconContent = <IconReactNative className={styles.iconReactNative} />;
      platformTextContent = 'React Native';
      break;
    case 'android':
      platformIconContent = <IconAndroid className={styles.iconAndroid} />;
      platformTextContent = 'Android';
      break;
    case 'apple':
      platformIconContent = <IconApple className={styles.iconApple} />;
      platformTextContent = 'Apple';
      break;
    case 'other':
      platformTextContent = 'Other';
      break;
    default:
      platformIconContent = <IconWeb className={styles.iconWeb} />;
      platformTextContent = 'Web';
  }

  return (
    <tr className={styles.platformTableRow}>
      <td className={styles.platformWrapper}>
        {platformIconContent}
        <span className={styles.platformName}>{platformTextContent}</span>
      </td>
      <td className={styles.notes}>{content}</td>
    </tr>
  );
};

export default PlatformTableRow;
