import React from 'react';
import classNames from 'classnames';
import { Link as GatsbyLink } from 'gatsby';
import { DesignCodeLink } from 'docComponents';
import { Link as UniLink } from 'hudl-uniform-ui-components';
import { doesLocationContainHref } from '../../utility/js/location';

const Link = ({
  children,
  href,
  type,
  isDesignCodeLink,
  className,
  activeClassName,
}) => {
  const startsWithHttp = href.startsWith('http');
  const targetType = startsWithHttp ? '_blank' : '_self';
  const linkType = type ? type : 'article';
  const linkClasses = classNames(className, {
    [`uni-link--${linkType}`]: linkType && !className,
    [activeClassName]: doesLocationContainHref(href),
  });

  let linkEl;

  if (startsWithHttp) {
    linkEl = (
      <UniLink href={href} target={targetType} type={linkType}>
        {children}
      </UniLink>
    );
  } else if (isDesignCodeLink) {
    linkEl = (
      <DesignCodeLink className={linkClasses} to={href}>
        {children}
      </DesignCodeLink>
    );
  } else {
    linkEl = (
      <GatsbyLink className={linkClasses} to={href} target={targetType}>
        {children}
      </GatsbyLink>
    );
  }

  return linkEl;
};

export default Link;
