import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Diamond = () => {
  return (
    <path
      className={'shapeLayer ' + styles.shapeBg}
      fill="currentColor"
      fillRule="evenodd"
      d="M155.328427,5.69743879 L242.302561,92.6715729 C243.864658,94.23367 243.864658,96.76633 242.302561,98.3284271
      L155.328427,185.302561 C153.76633,186.864658 151.23367,186.864658 149.671573,185.302561 L62.6974388,98.3284271
      C61.1353416,96.76633 61.1353416,94.23367 62.6974388,92.6715729 L149.671573,5.69743879 C151.23367,4.13534162
      153.76633,4.13534162 155.328427,5.69743879 Z"
    />
  );
};

export default Diamond;
