import React from 'react';
import classNames from 'classnames';
import styles from './GoodBad.module.scss';

const GoodBadItem = ({ type, children }) => {
  const headingClasses = classNames(styles.heading, {
    [styles.good]: type === 'good',
    [styles.bad]: type === 'bad',
  });

  const heading = type === 'good' ? 'Good' : 'Bad';

  return (
    <div className={styles.itemContainer}>
      <div className={headingClasses}>{heading}</div>
      <div className={styles.textWrapper}>{children}</div>
    </div>
  );
};

export default GoodBadItem;
