import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Link = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral3}
        width="32"
        height="16"
        x="46"
        y="56"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral3}
        width="112"
        height="16"
        x="87"
        y="56"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral3}
        width="52"
        height="16"
        x="207"
        y="56"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.action}
        width="62"
        height="16"
        x="120"
        y="88"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral3}
        width="65"
        height="16"
        x="46"
        y="88"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral3}
        width="92"
        height="16"
        x="46"
        y="120"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.action20}
        width="62"
        height="4"
        x="120"
        y="107"
        fill="currentColor"
      />
      <rect
        className={styles.neutral3}
        width="62"
        height="16"
        x="190"
        y="88"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral3}
        width="62"
        height="16"
        x="147"
        y="120"
        fill="currentColor"
        rx="8"
      />
    </g>
  );
};

export default Link;
