import React from 'react';
import {
  DataTable,
  DataTableHeader,
  DataTableBody,
  DataTableRow,
  DataTableCodeBlock,
  DataTableColorBlock,
  DataTableValueBlock,
} from 'docComponents';
import styles from './DataTable.module.scss';

export default class ColorDataTableCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueSelection: 'rgba',
    };
  }

  changeValueSelection(e) {
    this.setState({ valueSelection: this.state.valueSelection === 'rgba' ? 'hex' : 'rgba' });
  }

  render() {
    const {
      data,
      colorsType,
      env,
      platform
    } = this.props;

    const {
      valueSelection,
    } = this.state;

    const dataColors = [];

    if (data.allColorsYaml) {
      data.allColorsYaml.edges.forEach(element => {
        switch (colorsType) {
          case 'dark':
            if (element.node.dark_environment_colors) {
              element.node.dark_environment_colors.forEach(color => {
                dataColors.push(color);
              });
            }
            break;
          case 'light':
            if (element.node.light_environment_colors) {
              element.node.light_environment_colors.forEach(color => {
                dataColors.push(color);
              });
            }
            break;
          default:
            if (element.node.colors) {
              element.node.colors.forEach(color => {
                dataColors.push(color);
              });
            }
            break;
        }
      });
    } else {
      switch (colorsType) {
        case 'dark':
          if (data[0].node.dark_environment_colors) {
            data[0].node.dark_environment_colors.forEach(color => {
              dataColors.push(color);
            });
          }
          break;
        case 'light':
          if (data[0].node.light_environment_colors) {
            data[0].node.light_environment_colors.forEach(color => {
              dataColors.push(color);
            });
          }
          break;
        default:
          if (data[0].node.colors) {
            data[0].node.colors.forEach(color => {
              dataColors.push(color);
            });
          }
          break;
      }
    }

    return (
      <DataTable env={env}>
        <DataTableHeader>
          <DataTableRow layout="colorThirds">
            <th>Color</th>
            <th className={styles.dataTableHeaderValue} onClick={this.changeValueSelection.bind(this)}>
              {valueSelection === 'rgba' ?
                <div><span className={styles.dataTableHeaderSelected}>RGBA</span> / HEX</div> :
                <div>RGBA / <span className={styles.dataTableHeaderSelected}>HEX</span></div>
              }
            </th>
            <th>Variable</th>
          </DataTableRow>
        </DataTableHeader>

        <DataTableBody>
          {dataColors.map(item => {
            let platformData;
            let value;
            switch (platform) {
              case 'web':
                platformData = item.sass_var;
                value = valueSelection === 'rgba' ?
                  item.value :
                  `#${item.hex_value.toUpperCase().substring(0, 6)}`;
                break;
              case 'reactnative':
                platformData = item.rn_var;
                value = valueSelection === 'rgba' ?
                  item.value :
                  `#${item.hex_value.toUpperCase().substring(0, 6)}`;
                break;
              case 'android':
                platformData = item.android_var;
                value = valueSelection === 'rgba' ?
                  item.value :
                  `#${item.hex_value.toUpperCase()}`;
                break;
              case 'apple':
                platformData = item.apple_var;
                value = valueSelection === 'rgba' ?
                  item.value :
                  `#${item.hex_value.toUpperCase().substring(0, 6)}`;
                break;
              default:
                platformData = item.sass_var;
                value = valueSelection === 'rgba' ?
                  item.value :
                  `#${item.hex_value.toUpperCase().substring(0, 6)}`;
            }

            return (
              <DataTableRow layout="colorThirds" key={platformData}>
                <DataTableColorBlock value={item.value} />
                <DataTableValueBlock>{value}</DataTableValueBlock>
                <DataTableCodeBlock>{platformData}</DataTableCodeBlock>
              </DataTableRow>
            );
          })}
        </DataTableBody>
      </DataTable>
    );
  }
}
