import React from 'react';
import classNames from 'classnames';
import { doesHashMatchName } from '../../utility/js/location';
import { Headline } from 'hudl-uniform-ui-components';
import { CopyUrlButton } from 'docComponents';
import styles from './SectionSubhead.module.scss';

const SectionSubhead = ({ children }) => {
  const subheadClasses = classNames('section-subhead', styles.sectionSubhead, {
    [styles.sectionSubheadActivate]: doesHashMatchName(children),
  });

  return (
    <div className={styles.wrapper}>
      <span id={encodeURI(children)} />
      <div className={subheadClasses}>
        <Headline level="3" className="uni-margin--quarter--btm">
          {children}
        </Headline>
        <CopyUrlButton url={children} />
      </div>
    </div>
  );
};

export default SectionSubhead;
