import React from 'react';

const IconDribbble = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M11.415 7.83c.446.838.83 1.706 1.16 2.597 1.34-.262 2.713-.4 4.114-.4.912 0 2.293.114 3.298.236a9.872 9.872
        0 0 0-2.251-6.598c-1.897 1.768-4.028 3.176-6.321 4.164zm5.275 3.067c-1.301 0-2.577.126-3.823.36a21.202 21.202 0
        0 1 1.047 6.569c0 .314-.023.876-.05 1.399a10.003 10.003 0 0 0
        6.069-8.092c-.987-.123-2.376-.236-3.243-.236zM.123 8.515c1.02.113 2.175.18 2.92.18 2.455 0 4.856-.449
        7.123-1.3-1.351-2.278-3.16-4.294-5.33-5.947A9.994 9.994 0 0 0 .123 8.515zM12.975
        19.55c.034-.595.07-1.358.07-1.724 0-2.196-.361-4.346-1.035-6.385-3.48.818-6.694 2.568-9.5 5.17A9.97 9.97 0 0 0
        10 20c1.036 0 2.034-.157 2.974-.45zm-1.256-8.936a20.527 20.527 0 0 0-1.116-2.456c-2.401.92-4.952 1.407-7.56
        1.407-.786 0-1.957-.07-3.01-.188C.018 9.584 0 9.789 0 10c0 2.224.735 4.27 1.962 5.93 2.884-2.66 6.185-4.46
        9.757-5.316zM5.674.994a21.78 21.78 0 0 1 5.308 6.076c2.24-.952 4.322-2.325 6.171-4.052A9.963 9.963 0 0 0
        5.673.995z"
      />
    </svg>
  );
};

export default IconDribbble;
