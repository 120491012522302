import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchParallelogram = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M162.460218,51 L6.57564548,141 M14.5781403,141 L170.462713,51 M178.467699,51 L22.5831262,141 M30.5856211,141
      L186.470194,51 M194.47518,51 L38.590607,141 M46.5931018,141 L202.477675,51 M210.48266,51 L54.5980877,141
      M62.6005826,141 L218.485155,51 M226.490141,51 L70.6055685,141 M78.6080634,141 L234.492636,51 M242.497622,51
      L86.6130493,141 M94.6155441,141 L250.500117,51 M258.505103,51 L102.62053,141 M110.623025,141 L266.507598,51
      M274.512583,51 L118.628011,141 M126.630506,141 L282.515078,51 M290.520064,51 L134.635492,141 M142.637986,141
      L298.522559,51"
    />
  );
};

export default HatchParallelogram;
