import React from 'react';

const SelectMark = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <circle cx="152" cy="96" r="64" fill="#009CE3" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M145.629409,106.11707 L176.548713,75.1977657 C178.291189,73.4552902 181.1163,73.4552902
        182.858776,75.1977657 L187.906825,80.2458155 C189.649301,81.988291 189.649301,84.8134021 187.906825,86.5558776
        L145.629409,128.833294 C143.886933,130.575769 141.061822,130.575769 139.319347,128.833294
        L117.234129,106.748076 C115.491654,105.005601 115.491654,102.18049 117.234129,100.438014 L122.282179,95.3899646
        C124.024655,93.647489 126.849766,93.647489 128.592241,95.3899646 L139.319347,106.11707 C141.061822,107.859546
        143.886933,107.859546 145.629409,106.11707 Z"
        transform="rotate(-5 152.57 102.016)"
      />
    </g>
  );
};

export default SelectMark;
