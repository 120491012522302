import React from 'react';
import { DesignCodeLink, SvgIllustration } from 'docComponents';
import styles from './ItemsList.module.scss';

const ItemVisual = ({ text, svgShape, svgHatching, svgIllustration, path }) => {
  let image;
  if (svgShape) {
    image = (
      <SvgIllustration
        className={styles.svgContainer}
        shapeType={svgShape}
        hatchingType={svgHatching}
        illustrationType={svgIllustration}
      />
    );
  }

  let fallback;
  if (!svgHatching || !svgIllustration || !svgShape) {
    fallback = (
      <div className={styles.itemFallback}>
        <h2 className={styles.itemFallbackText}>{text}</h2>
      </div>
    );
  }

  let label;
  if (!svgHatching || !svgIllustration || !svgShape) {
    label = <h4 className={styles.itemLabel} />;
  } else {
    label = <h4 className={styles.itemLabel}>{text}</h4>;
  }

  return (
    <div className={styles.itemContainer}>
      <DesignCodeLink className={styles.itemLink} to={path}>
        <div className={styles.itemMediaContainer}>
          <div className={styles.itemCanvas}>
            {image}
            {fallback}
          </div>
        </div>
        {label}
      </DesignCodeLink>
    </div>
  );
};

export default ItemVisual;
