import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Button = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.action}
        width="160"
        height="78"
        x="72"
        y="57"
        fill="currentColor"
        rx="20"
      />
      <rect
        className={styles.white}
        width="90"
        height="18"
        x="107"
        y="87"
        fill="currentColor"
        rx="9"
      />
    </g>
  );
};

export default Button;
