import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const KeyboardShortcut = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <g transform="translate(58 59)">
        <rect
          width="70"
          height="74"
          className={styles.neutral9}
          fillOpacity=".06"
          rx="10"
          fill="currentColor"
        />
        <rect
          width="70"
          height="69"
          className={styles.neutral9}
          fillOpacity=".03"
          rx="10"
          fill="currentColor"
        />
        <rect
          width="62.171"
          height="62.343"
          x="4"
          y="3"
          fill="currentColor"
          className={styles.white}
          rx="9.067"
          fill="currentColor"
        />
        <circle
          cx="35"
          cy="34"
          r="14"
          className={styles.neutral8}
          fillRule="nonzero"
          fill="currentColor"
        />
        <g transform="translate(90)">
          <rect
            width="98"
            height="74"
            className={styles.neutral9}
            fillOpacity=".06"
            rx="9.714"
            fill="currentColor"
          />
          <rect
            width="97"
            height="68"
            className={styles.neutral9}
            fillOpacity=".03"
            rx="10.362"
            fill="currentColor"
          />
          <rect
            width="92"
            height="62"
            x="3"
            y="3"
            fill="currentColor"
            className={styles.white}
            rx="9.067"
          />
        </g>
        <rect
          width="52"
          height="28"
          x="113"
          y="20"
          className={styles.neutral8}
          fillRule="nonzero"
          rx="14"
          fill="currentColor"
        />
      </g>
    </g>
  );
};

export default KeyboardShortcut;
