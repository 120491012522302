import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Legend = () => {
  return (
    <g className="illustrationLayer" fill="none" fillRule="evenodd">
      <path d="M0 0h304v192H0z" />
      <path
        d="M29 78.7a8.7 8.7 0 0 1 8.7-8.7h228.649a8.7 8.7 0 0 1 8.7 8.7v34.6a8.7 8.7 0 0 1-8.7 8.7H37.7a8.7 8.7 0 0 1-8.7-8.7V78.7z"
        fill="currentColor"
        className={styles.neutral2}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.268 79.268c0-4.418 3.592-8 8.01-8h227.493c4.418 0 8.009 3.582 8.009 8v33.464a8 8 0 0 1-8 8H38.268a8 8 0 0 1-8-8V79.268z"
        fill="currentColor"
        className={styles.whiteBlack}
      />
      <rect
        className={styles.neutral3}
        x="49.293"
        y="82.683"
        width="36.781"
        height="7.61"
        rx="3.805"
        fill="currentColor"
      />
      <rect
        x="49.293"
        y="100.439"
        width="44.39"
        height="7.687"
        rx="3.843"
        fill="currentColor"
        className={styles.neutral3}
      />
      <rect
        x="125.39"
        y="82.683"
        width="57.073"
        height="7.61"
        rx="3.805"
        fill="currentColor"
        className={styles.neutral8}
      />
      <rect
        x="125.39"
        y="100.439"
        width="44.39"
        height="7.687"
        rx="3.843"
        fill="currentColor"
        className={styles.neutral8}
      />
      <rect
        x="111.439"
        y="82.683"
        width="7.61"
        height="7.61"
        rx="3.805"
        fill="currentColor"
        className={styles.action}
      />
      <rect
        x="111.439"
        y="100.439"
        width="7.61"
        height="7.61"
        rx="3.805"
        fill="currentColor"
        className={styles.neutral3}
      />
      <rect
        x="214.171"
        y="82.683"
        width="25.366"
        height="7.61"
        rx="3.805"
        fill="currentColor"
        className={styles.neutral8}
      />
      <rect
        x="214.171"
        y="100.439"
        width="39.317"
        height="7.61"
        rx="3.805"
        fill="currentColor"
        className={styles.neutral8}
      />
      <rect
        x="200.22"
        y="82.683"
        width="7.61"
        height="7.61"
        rx="3.805"
        fill="currentColor"
        className={styles.neutral3}
      />
      <rect
        x="200.22"
        y="100.439"
        width="7.61"
        height="7.61"
        rx="3.805"
        fill="currentColor"
        className={styles.neutral3}
      />
    </g>
  );
};

export default Legend;
