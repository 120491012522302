import React from 'react';
import { Icon } from 'hudl-uniform-ui-components';

const IconCopy = ({ className }) => {
  return (
    <Icon
      className={className}>
      <path
        d="M22 24V13.29c0-1.073-.525-2.31-1.302-3.055l-5.259-5.027C14.685 4.488 13.467 4 12.418 4H6V1.626C6 .728
        6.763 0 7.701 0h10.717c.539 0 1.261.293 1.639.654l5.259 5.027c.382.366.684 1.082.684 1.609v15.084c0
        .898-.763 1.626-1.701 1.626H22zm-2.684-12.319c.382.366.684 1.082.684 1.609v15.084c0 .898-.763
        1.626-1.701 1.626H1.701C.762 30 0 29.272 0 28.374V7.626C0 6.728.763 6 1.701 6h10.717c.539 0
        1.261.293 1.639.654l5.259 5.027z"
      />
    </Icon>
  );
};

export default IconCopy;
