import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Type = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral5}
        width="173"
        height="32"
        x="48"
        y="40"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.neutral4}
        width="154"
        height="24"
        x="48"
        y="80"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.neutral6}
        width="204"
        height="8"
        x="48"
        y="112"
        fill="currentColor"
        rx="4"
      />
      <rect
        className={styles.neutral6}
        width="64"
        height="8"
        x="48"
        y="144"
        fill="currentColor"
        rx="4"
      />
      <rect
        className={styles.neutral6}
        width="214"
        height="8"
        x="48"
        y="128"
        fill="currentColor"
        rx="4"
      />
    </g>
  );
};

export default Type;
