import React from 'react';
import { Icon } from 'hudl-uniform-ui-components';
/* eslint-disable max-len */

const IconGitHub = ({ className }) => {
  return (
    <Icon
      className={className}
      label="View Source"
      labelPosition="top"
      labelParent="uni-icon">
      <path d="M16 0C7.163 0 0 7.341 0 16.397c0 7.248 4.592 13.397 10.944 15.578.8.13 1.056-.378 1.056-.82v-2.771c-4.432.983-5.376-2.198-5.376-2.198-.736-1.902-1.776-2.41-1.776-2.41-1.456-1.017.112-.984.112-.984 1.6.115 2.448 1.69 2.448 1.69 1.392 2.491 3.744 1.754 4.656 1.36.144-1.066.56-1.787 1.008-2.197-3.552-.41-7.28-1.82-7.28-8.068 0-1.82.608-3.28 1.648-4.443-.16-.41-.72-2.116.16-4.33 0 0 1.344-.442 4.4 1.673 1.264-.36 2.64-.54 4-.54 1.36 0 2.736.18 4 .54 3.056-2.115 4.4-1.672 4.4-1.672.88 2.213.32 3.919.16 4.329 1.04 1.164 1.648 2.623 1.648 4.443 0 6.264-3.744 7.641-7.312 8.051.576.509 1.104 1.509 1.104 3.034v4.493c0 .442.256.967 1.072.82C27.424 29.777 32 23.645 32 16.397c0-4.349-1.686-8.52-4.686-11.594C24.314 1.728 20.244 0 16 0z" />
    </Icon>
  );
};

export default IconGitHub;
