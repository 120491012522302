import React from 'react';
import {
  DataTable,
  DataTableHeader,
  DataTableBody,
  DataTableRow,
  DataTableCodeBlock,
  DataTableColorBlock,
  DataTableValueBlock,
} from 'docComponents';

const ColorDataTableDesign = ({ data, colorsType, env }) => {
  const dataColors = [];
  data.allColorsYaml.edges.forEach(element => {
    switch (colorsType) {
      case 'dark':
        if (element.node.dark_environment_colors) {
          element.node.dark_environment_colors.forEach(color => {
            dataColors.push(color);
          });
        }
        break;
      case 'light':
        if (element.node.light_environment_colors) {
          element.node.light_environment_colors.forEach(color => {
            dataColors.push(color);
          });
        }
        break;
      default:
        if (element.node.colors) {
          element.node.colors.forEach(color => {
            dataColors.push(color);
          });
        }
        break;
    }
  });

  return (
    <DataTable env={env}>
      <DataTableHeader>
        <DataTableRow layout="colorThirds">
          <th>Color</th>
          <th>Name</th>
          <th>RGBA</th>
        </DataTableRow>
      </DataTableHeader>
      <DataTableBody>
        {dataColors.map(item => {
          return (
            <DataTableRow layout="colorThirds" key={item.common_name}>
              <DataTableColorBlock value={item.value} />
              <DataTableValueBlock>{item.common_name}</DataTableValueBlock>
              <DataTableCodeBlock>{item.value}</DataTableCodeBlock>
            </DataTableRow>
          );
        })}
      </DataTableBody>
    </DataTable>
  );
};

export default ColorDataTableDesign;
