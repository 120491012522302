import React from 'react';

const IconWeb = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32">
      <path
        d="M6.832 20h3.404a22.212 22.212 0 0 1-.327-3h-3.86a9.936 9.936 0 0 0 .783 3zM8 22a10.034 10.034 0 0 0
        4.081 3.203c-.547-.9-1.014-1.983-1.373-3.203H7.999zm17.169-2a9.936 9.936 0 0 0 .783-3h-3.86a22.212 22.212 0 0
        1-.327 3h3.404zM24 22h-2.708c-.36 1.22-.826 2.303-1.373 3.203A10.034 10.034 0 0 0 24
        22zm-11.729-2h7.456c.185-.929.312-1.936.36-3h-8.177c.05 1.064.176 2.071.361 3zm.527 2c.818 2.45 2.064 3.993
        3.201 3.993S18.383 24.45 19.201 22H12.8zM6.832 12a9.936 9.936 0 0 0-.783
        3h3.86c.044-1.044.156-2.05.327-3H6.832zM8 10h2.708c.36-1.22.826-2.303 1.373-3.203A10.034 10.034 0 0 0 8
        10zm17.169 2h-3.404c.171.95.283 1.956.327 3h3.86a9.936 9.936 0 0 0-.783-3zM24 10a10.034 10.034 0 0
        0-4.081-3.203c.547.9 1.013 1.983 1.373 3.203h2.708zm-11.729 2a20.124 20.124 0 0 0-.36 3h8.177a20.124 20.124 0 0
        0-.361-3h-7.456zm.527-2H19.2C18.383 7.55 17.137 6.007 16 6.007S13.617 7.55 12.799 10zM16 29C8.82 29 3 23.18 3
        16S8.82 3 16 3s13 5.82 13 13-5.82 13-13 13z"
      />
    </svg>
  );
};

export default IconWeb;
