import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchRectangle = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M62.5322085,44 L56,47.7713723 M56,52.3916149 L70.5347034,44 M78.5396893,44 L56,57.0132957 M56,61.6335382
      L86.5421841,44 M94.54717,44 L56,66.255219 M56,70.8754616 L102.549665,44 M110.554651,44 L56,75.4971423
      M56,80.1173849 L118.557146,44 M126.562132,44 L56,84.7390656 M56,89.3593082 L134.564626,44 M142.569612,44
      L56,93.980989 M56,98.6012315 L150.572107,44 M158.577093,44 L56,103.222912 M56,107.843155 L166.579588,44
      M174.584574,44 L56,112.464836 M56,117.085078 L182.587069,44 M190.592055,44 L56,121.706759 M56,126.327001
      L198.594549,44 M206.599535,44 L56,130.948682 M56,135.568925 L214.60203,44 M222.607016,44 L56,140.190606
      M56,144.810848 L230.609511,44 M238.614497,44 L58.4812129,148 M66.4837077,148 L246.616992,44 M248,47.8232005
      L74.4886936,148 M82.4911885,148 L248,52.4434431 M248,57.0651239 L90.4961744,148 M98.4986692,148 L248,61.6853664
      M248,66.3070472 L106.503655,148 M114.50615,148 L248,70.9272898 M248,75.5489705 L122.511136,148 M130.513631,148
      L248,80.1692131 M248,84.7908938 L138.518617,148 M146.521111,148 L248,89.4111364 M248,94.0328172 L154.526097,148
      M162.528592,148 L248,98.6530597 M248,103.27474 L170.533578,148 M178.536073,148 L248,107.894983 M248,112.516664
      L186.541059,148 M194.543554,148 L248,117.136906 M248,121.758587 L202.54854,148 M210.551035,148 L248,126.37883
      M248,131.00051 L218.55602,148 M226.558515,148 L248,135.620753 M248,140.242434 L234.563501,148 M242.565996,148
      L248,144.862676"
    />
  );
};

export default HatchRectangle;
