import React from 'react';
import classNames from 'classnames';
import styles from './DontDo.module.scss';

const DontDo = ({ children, gridLayout }) => {
  const DontDoClass = classNames('uni-bg--level1 ' + styles.container, {
    [styles.grid2]: gridLayout === '2',
    [styles.grid3]: gridLayout === '3',
  });

  return <div className={DontDoClass}>{children}</div>;
};

export default DontDo;
