import React from 'react';
import {
  IconAndroid,
  IconCode,
  IconApple,
  IconReactNative,
  IconWeb,
  Section,
} from 'docComponents';
import styles from './PlatformTabEmptyState.module.scss';

const PlatformTabEmptyState = ({ platform, pageType, children }) => {
  let platformIconContent;
  let platformMessage;
  let platformText = '';

  switch (platform) {
    case 'web':
      platformIconContent = <IconWeb className={styles.icon} />;
      platformText = 'Web';
      break;
    case 'reactnative':
      platformIconContent = <IconReactNative className={styles.icon} />;
      platformText = 'React Native';
      break;
    case 'android':
      platformIconContent = <IconAndroid className={styles.icon} />;
      platformText = 'Android';
      break;
    case 'apple':
      platformIconContent = <IconApple className={styles.icon} />;
      platformText = 'Apple';
      break;
    case 'other':
      platformIconContent = <IconCode className={styles.icon} />;
      platformText = 'Other';
      break;
    default:
      platformIconContent = <IconCode className={styles.icon} />;
      platformText = 'Other';
  }

  if (pageType === 'guidelines') {
    platformMessage =
      platform === 'other'
        ? "It doesn't look like we have these guidelines for any additional platforms."
        : `These guidelines don't currently exist for ${platformText}.`;
  } else if (pageType === 'resources') {
    platformMessage =
      platform === 'other'
        ? "It doesn't look like we have these resources for any additional platforms."
        : `Additional resources for ${platformText} don't currently exist.`;
  } else {
    platformMessage =
      platform === 'other'
        ? 'It doesn’t look like we have this Uniform component for any additional platforms.'
        : `This Uniform component doesn't exist for ${platformText}.`;
  }

  return (
    <Section>
      <div className={styles.platformTabEmptyState}>
        {platformIconContent}
        <div className={styles.message}>{platformMessage}</div>
        {children && <div className={styles.divider} />}
        {children && <div className={styles.cta}>{children}</div>}
      </div>
    </Section>
  );
};

export default PlatformTabEmptyState;
