import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ColorsTagging = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect width="44" height="44" x="182" y="74" fill="#DF4953" rx="8" />
      <rect width="44" height="44" x="130" y="74" fill="#ED9827" rx="8" />
      <rect width="44" height="44" x="78" y="74" fill="#FFDD30" rx="8" />
      <rect width="44" height="44" x="182" y="126" fill="#9F62B1" rx="8" />
      <rect width="44" height="44" x="130" y="126" fill="#3985BE" rx="8" />
      <rect width="44" height="44" x="78" y="126" fill="#42BE39" rx="8" />
      <rect width="44" height="44" x="182" y="22" fill="#404040" rx="8" />
      <rect width="44" height="44" x="130" y="22" fill="#8C9196" rx="8" />
      <rect width="44" height="44" x="78" y="22" fill="#E9E9E9" rx="8" />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="182.5"
        y="74.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="130.5"
        y="74.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="78.5"
        y="74.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="182.5"
        y="126.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="130.5"
        y="126.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="78.5"
        y="126.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="182.5"
        y="22.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="130.5"
        y="22.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
      <rect
        className={styles.outline}
        width="43"
        height="43"
        x="78.5"
        y="22.5"
        fill="none"
        stroke="currentColor"
        rx="8"
      />
    </g>
  );
};

export default ColorsTagging;
