import React from 'react';
import { Icon, ItemTitle } from 'hudl-uniform-ui-components';
import styles from './IconItem.module.scss';

const IconItem = ({ reactName, displayName }) => {
  const iconSize = 'large';
  const iconName = reactName;
  const title = displayName;

  return (
    <div className={styles.iconItem}>
      <div className={styles.iconItemWrapper}>
        <Icon type={iconName} size={iconSize} />
      </div>
      <ItemTitle>{title}</ItemTitle>
    </div>
  );
};

export default IconItem;
