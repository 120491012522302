import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSashDouble = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M29,114.3331 L178.533316,28 M178.533316,32.6216808 L29,118.954781 M29,123.575024 L178.533316,37.2419233
      M178.533316,41.8636041 L29,128.196704 M29,132.816947 L178.533316,46.4838466 M178.533316,51.1055274 L29,137.438628
      M125,142.3331 L274.533316,56 M274.533316,60.6216808 L125,146.954781 M125,151.575024 L274.533316,65.2419233
      M274.533316,69.8636041 L125,156.196704 M125,160.816947 L274.533316,74.4838466 M274.533316,79.1055274
      L125,165.438628"
    />
  );
};

export default HatchSashDouble;
