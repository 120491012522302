import { copyTextToClipboard } from './clipboard';
import { getPlatformTabPreference } from './platformTabPreference';

export const copyUrl = (url, includePlatform, el) => {
  if (!window) return;

  if (window) {
    const location = window.location;
    let searchQuery = '';

    if (includePlatform) {
      searchQuery =
        location.search === '' ? '?' + getPlatformTabPreference() : '';
    }

    const urlToCopy =
      location.href.split('#')[0] + searchQuery + '#' + encodeURI(url);
    copyTextToClipboard(urlToCopy, el);
  }
};

export const doesHashMatchName = url => {
  let returnVal = false;
  if (typeof window !== 'undefined') {
    if (decodeURI(window.location.hash.replace('#', '')) === url) {
      returnVal = true;
    }
    returnVal = false;
  }

  return returnVal;
};

export const doesLocationContainHref = url => {
  let returnVal = false;
  if (typeof window !== 'undefined') {
    if (window.location.href.indexOf(url) > -1) {
      returnVal = true;
    }
  }

  return returnVal;
};
