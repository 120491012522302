import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchHexagonSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M154.397188,37.171346 L100,68.5775771 M100,73.1978197 L158.392706,39.4847753 M162.389467,41.7989248
      L100,77.8195004 M100,82.439743 L166.384985,44.1123542 M170.381746,46.4265037 L100,87.0614238 M100,91.6816663
      L174.377264,48.739933 M178.374025,51.0540825 L100,96.3033471 M100,100.92359 L182.369543,53.3675118
      M186.366304,55.6816613 L100,105.54527 M100,110.165513 L190.361822,57.9950907 M194.358584,60.3092402
      L100,114.787194 M100,119.407436 L198.354101,62.6226695 M202.25376,64.8805961 C202.275194,64.8930065
      168.190607,84.6091801 100,124.029117 M102.197453,127.380659 L204,68.6049316 M204,73.2266124
      L106.194215,129.694809 M110.189732,132.008238 L204,77.846855 M204,82.4685357 L114.186494,134.322388
      M118.182012,136.635817 L204,87.0887783 M204,91.710459 L122.178773,138.949966 M126.174291,141.263396
      L204,96.3307016 M204,100.952382 L130.171052,143.577545 M134.16657,145.890975 L204,105.572625 M204,110.194306
      L138.163331,148.205124 M142.158849,150.518553 L204,114.814548 M204,119.436229 L146.15561,152.832703
      M150.151128,155.146132 L204,124.056472"
    />
  );
};

export default HatchHexagonSmall;
