import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Parallelogram = () => {
  return (
    <path
      className={'shapeLayer ' + styles.shapeBg}
      fill="currentColor"
      fillRule="evenodd"
      d="M8.99807059,137.269065 L156.874603,51.6726614 C157.635844,51.2320265 158.499853,51 159.379426,51
      L294.073388,51 C295.177957,51 296.073388,51.8954305 296.073388,53 C296.073388,53.713735 295.693032,54.3733794
      295.075317,54.7309354 L147.198785,140.327339 C146.437544,140.767973 145.573535,141 144.693962,141 L10,141
      C8.8954305,141 8,140.104569 8,139 C8,138.286265 8.38035596,137.626621 8.99807059,137.269065 Z"
    />
  );
};

export default Parallelogram;
