import React from 'react';

const IconUniformLogo = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="94"
      height="20"
      viewBox="0 0 94 20">
      <path
        fill="currentColor"
        d="M0 12.75V2.275h4.275V12.75c0 2.075 1.025 3.275 3.05 3.275s3.1-1.2 3.1-3.275V2.275H14.7V12.75c0 4.85-3.675
        7.25-7.45 7.25C3.45 20 0 17.6 0 12.75zm20.57-6.875v2.05c.8-1.325 2.275-2.2 4.225-2.2 3.125 0 5.375 2.2 5.375
        5.95v8.15h-4.25V12.25c0-1.825-1.05-2.825-2.675-2.825-1.625 0-2.675 1-2.675
        2.825v7.575h-4.275V5.875h4.275zm11.27 13.95V5.875h4.275v13.95H31.84zm2.125-14.8c-1.4 0-2.5-1.1-2.5-2.5 0-1.425
        1.1-2.525 2.5-2.525s2.5 1.1 2.5 2.525c0 1.4-1.1 2.5-2.5
        2.5zm10.845.85v3.55h-1.775v10.4H38.76v-10.4h-1.3v-3.55h1.3V5.35c0-3.55 1.85-5.1 5.85-5.1v3.625c-1.125
        0-1.575.35-1.575 1.475v.525h1.775zm.445 6.95c0-4.4 3.275-7.1 7.3-7.1 4 0 7.275 2.7 7.275 7.1 0 4.4-3.225
        7.175-7.25 7.175s-7.325-2.775-7.325-7.175zm10.225.025c0-2.425-1.5-3.475-2.925-3.475-1.475 0-2.95 1.05-2.95
        3.475s1.525 3.5 2.975 3.5c1.45 0 2.9-1.075 2.9-3.5zm9.72-6.975v2.6c.9-1.65 2.275-2.75 4-2.75v4.35h-1.05c-1.875
        0-2.95.725-2.95 3.15v6.6h-4.275V5.875H65.2zm9.195 0v2.05c.8-1.325 2.275-2.2 4.225-2.2 2.075 0 3.75.975 4.65
        2.7.975-1.55 2.65-2.7 4.775-2.7 3.375 0 5.55 2.2 5.55
        5.95v8.15h-4.25V12.25c0-1.825-1.05-2.825-2.675-2.825-1.625 0-2.675 1-2.675
        2.825v7.575h-4.25V12.25c0-1.825-1.05-2.825-2.675-2.825-1.625 0-2.675 1-2.675 2.825v7.575H70.12V5.875h4.275z"
      />
    </svg>
  );
};

export default IconUniformLogo;
