import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ButtonRow = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral5}
        width="80"
        height="40"
        x="112"
        y="76"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral5}
        width="80"
        height="40"
        x="200"
        y="76"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral5}
        width="80"
        height="40"
        x="24"
        y="76"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.white}
        width="42"
        height="10"
        x="131"
        y="91"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.white}
        width="42"
        height="10"
        x="219"
        y="91"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.white}
        width="42"
        height="10"
        x="43"
        y="91"
        fill="currentColor"
        rx="5"
      />
    </g>
  );
};

export default ButtonRow;
