import React from 'react';

const Themes = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fill="#13293F"
        fillOpacity=".03"
        fillRule="nonzero"
        d="M163.880389,37 L224,37 C239.463973,37 252,49.536027 252,65 L252,142 C252,157.463973 239.463973,170 224,170
        L137.19415,170 C128.357594,170 121.19415,162.836556 121.19415,154 C121.19415,152.620391 121.372583,151.246576
        121.725009,149.912741 L148.411248,48.9127408 C150.266457,41.891288 156.617978,37 163.880389,37 Z"
      />
      <path
        fill="#13293F"
        fillOpacity=".03"
        fillRule="nonzero"
        d="M163.880389,45 L224,45 C235.045695,45 244,53.954305 244,65 L244,142 C244,153.045695 235.045695,162 224,162
        L137.19415,162 C132.775872,162 129.19415,158.418278 129.19415,154 C129.19415,153.310196 129.283367,152.623288
        129.45958,151.95637 L156.145818,50.9563704 C157.073423,47.445644 160.249183,45 163.880389,45 Z"
      />
      <path
        fill="#101417"
        d="M163.880389,49 C162.064786,49 160.476906,50.222822 160.013103,51.9781852 L133.326865,152.978185
        C133.238758,153.311644 133.19415,153.655098 133.19415,154 C133.19415,156.209139 134.985011,158 137.19415,158
        L224,158 C232.836556,158 240,150.836556 240,142 L240,65 C240,56.163444 232.836556,49 224,49 L163.880389,49 Z"
      />
      <path
        fill="#E6F2FF"
        fillOpacity=".4"
        d="M154.987156,71 L198.5,71 C202.089851,71 205,73.9101491 205,77.5 C205,81.0898509 202.089851,84 198.5,84
        L151.552294,84 L154.987156,71 Z M148.645872,95 L208.5,95 C212.089851,95 215,97.9101491 215,101.5
        C215,105.089851 212.089851,108 208.5,108 L145.211009,108 L148.645872,95 Z M142.304587,119 L185.5,119
        C189.089851,119 192,121.910149 192,125.5 C192,129.089851 189.089851,132 185.5,132 L138.869725,132
        L142.304587,119 Z"
      />
      <path
        fill="#E6F2FF"
        fillOpacity=".1"
        fillRule="nonzero"
        d="M163.880389,50 C162.518687,50 161.327776,50.9171165 160.979925,52.2336389 L134.293686,153.233639
        C134.227606,153.483733 134.19415,153.741323 134.19415,154 C134.19415,155.656854 135.537296,157 137.19415,157
        L224,157 C232.284271,157 239,150.284271 239,142 L239,65 C239,56.7157288 232.284271,50 224,50 L163.880389,50 Z
        M163.880389,49 L224,49 C232.836556,49 240,56.163444 240,65 L240,142 C240,150.836556 232.836556,158 224,158
        L137.19415,158 C134.985011,158 133.19415,156.209139 133.19415,154 C133.19415,153.655098 133.238758,153.311644
        133.326865,152.978185 L160.013103,51.9781852 C160.476906,50.222822 162.064786,49 163.880389,49 Z"
      />
      <path
        fill="#13293F"
        fillOpacity=".03"
        fillRule="nonzero"
        d="M81,22 L171.023452,22 C179.860008,22 187.023452,29.163444 187.023452,38 C187.023452,39.1570007
        186.897955,40.3105884 186.649162,41.4405232 L164.41063,142.440523 C162.795556,149.775649 156.295747,155
        148.78492,155 L81,155 C65.536027,155 53,142.463973 53,127 L53,50 C53,34.536027 65.536027,22 81,22 Z"
      />
      <path
        fill="#13293F"
        fillOpacity=".03"
        fillRule="nonzero"
        d="M81,30 L171.023452,30 C175.44173,30 179.023452,33.581722 179.023452,38 C179.023452,38.5785004
        178.960703,39.1552942 178.836307,39.7202616 L156.597775,140.720262 C155.790238,144.387824 152.540334,147
        148.78492,147 L81,147 C69.954305,147 61,138.045695 61,127 L61,50 C61,38.954305 69.954305,30 81,30 Z"
      />
      <path
        fill="#FFF"
        d="M81,34 L171.023452,34 C173.232591,34 175.023452,35.790861 175.023452,38 C175.023452,38.2892502
        174.992078,38.5776471 174.929879,38.8601308 L152.691347,139.860131 C152.287579,141.693912 150.662627,143
        148.78492,143 L81,143 C72.163444,143 65,135.836556 65,127 L65,50 C65,41.163444 72.163444,34 81,34 Z"
      />
      <path
        fill="#13293F"
        fillOpacity=".4"
        d="M171.155963,56 L168.293578,69 L97.5,69 C93.9101491,69 91,66.0898509 91,62.5 C91,58.9101491 93.9101491,56
        97.5,56 L171.155963,56 Z M165.87156,80 L163.009174,93 L97.5,93 C93.9101491,93 91,90.0898509 91,86.5
        C91,82.9101491 93.9101491,80 97.5,80 L165.87156,80 Z M160.587156,104 L157.724771,117 L97.5,117 C93.9101491,117
        91,114.089851 91,110.5 C91,106.910149 93.9101491,104 97.5,104 L160.587156,104 Z"
      />
      <path
        fill="#13293F"
        fillOpacity=".1"
        fillRule="nonzero"
        d="M81,35 C72.7157288,35 66,41.7157288 66,50 L66,127 C66,135.284271 72.7157288,142 81,142 L148.78492,142
        C150.1932,142 151.411914,141.020434 151.71474,139.645098 L173.953273,38.6450981 C173.999921,38.4332353
        174.023452,38.2169376 174.023452,38 C174.023452,36.3431458 172.680306,35 171.023452,35 L81,35 Z M81,34
        L171.023452,34 C173.232591,34 175.023452,35.790861 175.023452,38 C175.023452,38.2892502 174.992078,38.5776471
        174.929879,38.8601308 L152.691347,139.860131 C152.287579,141.693912 150.662627,143 148.78492,143 L81,143
        C72.163444,143 65,135.836556 65,127 L65,50 C65,41.163444 72.163444,34 81,34 Z"
      />
    </g>
  );
};

export default Themes;
