import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchRhombusSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M59.5165206,96.5709674 L153.64369,42.2266208 M157.63945,44.5413483 L63.5122809,98.8856949
      M67.5067978,101.199702 L161.633967,46.8553555 M165.629727,49.170083 L71.5025581,103.51443 M75.497075,105.828437
      L169.624244,51.4840901 M173.620005,53.7988176 L79.4928353,108.143164 M83.4873521,110.457171
      L177.614521,56.1128248 M181.610282,58.4275523 L87.4831124,112.771899 M91.4776293,115.085906
      L185.604799,60.7415595 M189.600559,63.0562869 L95.4733896,117.400633 M99.4679065,119.714641
      L193.595076,65.3702941 M197.590836,67.6850216 L103.463667,122.029368 M107.458184,124.343375
      L201.585353,69.9990288 M205.581113,72.3137562 L111.453944,126.658103 M115.448461,128.97211 L209.57563,74.6277634
      M213.57139,76.9424909 L119.444221,131.286837 M123.438738,133.600845 L217.565907,79.2564981 M221.561668,81.5712256
      L127.434498,135.915572 M131.429015,138.229579 L225.556184,83.8852327 M229.551945,86.1999602
      L135.424775,140.544307 M139.419292,142.858314 L233.546462,88.5139674 M237.542222,90.8286949
      L143.415053,145.173041 M147.409569,147.487049 L241.536739,93.142702 M245.532499,95.4574295 L151.40533,149.801776"
    />
  );
};

export default HatchRhombusSmall;
