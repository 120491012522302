import React from 'react';
import Helmet from 'react-helmet';
import { GlobalNav } from 'docComponents';
import faviconApple from '../imgs/favicons/apple-touch-icon.png';
import favicon32 from '../imgs/favicons/favicon-32x32.png';
import favicon16 from '../imgs/favicons/favicon-16x16.png';
import safariPinned from '../imgs/favicons/safari-pinned-tab.svg';
import faviconICO from '../imgs/favicons/favicon.ico';

class TemplateWrapper extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div>
        <Helmet>
          <title>Hudl Uniform</title>
          <link rel="apple-touch-icon" sizes="180x180" href={faviconApple} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="mask-icon" href={safariPinned} color="#ff6500" />
          <link rel="shortcut icon" href={faviconICO} />
        </Helmet>
        <GlobalNav />
        <div>{children}</div>
      </div>
    );
  }
}

export default TemplateWrapper;
