import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Rectangle = () => {
  return (
    <rect
      className={'shapeLayer ' + styles.shapeBg}
      fill="currentColor"
      width="192"
      height="104"
      x="56"
      y="44"
      fillRule="evenodd"
    />
  );
};

export default Rectangle;
