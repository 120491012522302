import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Colors = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect width="64" height="64" x="44" y="26" fill="#FF6300" rx="16" />
      <rect width="64" height="64" x="44" y="102" fill="#FFF" rx="16" />
      <rect width="64" height="64" x="120" y="102" fill="#45525E" rx="16" />
      <rect width="64" height="64" x="196" y="102" fill="#F2B600" rx="16" />
      <rect width="64" height="64" x="120" y="26" fill="#009CE3" rx="16" />
      <rect width="64" height="64" x="196" y="26" fill="#78A100" rx="16" />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="44.5"
        y="26.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="44.5"
        y="102.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="120.5"
        y="102.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="196.5"
        y="102.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="120.5"
        y="26.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="196.5"
        y="26.5"
        stroke="currentColor"
        rx="16"
      />
    </g>
  );
};

export default Colors;
