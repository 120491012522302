import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSashLeft = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M36,111.516366 L162.533316,38.4623218 M162.533316,43.0840026 L36,116.138047 M36,120.758289
      L162.533316,47.7042452 M162.533316,52.3259259 L36,125.37997 M36,130.000213 L162.533316,56.9461685
      M162.533316,61.5678493 L36,134.621893 M36,139.242136 L162.533316,66.1880918 M162.533316,70.8097726 L36,143.863817
      M162.533316,75.4300151 L36,148.274199"
    />
  );
};

export default HatchSashLeft;
