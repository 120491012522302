import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSashThin = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M274.533316,14.0203367 L29,155.779063 M29,160.399305 L274.533316,18.6405793 M274.533316,23.2622601
      L29,165.020986 M29,169.641229 L274.533316,27.8825026 M274.533316,32.5041834 L29,174.262909 M29,178.883152
      L274.533316,37.1244259"
    />
  );
};

export default HatchSashThin;
