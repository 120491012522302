import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Oval = () => {
  return (
    <ellipse
      className={'shapeLayer ' + styles.shapeBg}
      cx="152"
      cy="96"
      fill="currentColor"
      fillRule="evenodd"
      rx="115"
      ry="64"
    />
  );
};

export default Oval;
