import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchTriangleInverted = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M68.0905685,26.9272753 L73.160758,24 M81.1657439,24 L69.9585591,30.4704712 M71.8259684,34.0125645
      L89.1682388,24 M97.1732247,24 L73.693959,37.5557604 M75.5613683,41.0978536 L105.17572,24 M113.180705,24
      L77.4293589,44.6410495 M79.2967682,48.1831428 L121.1832,24 M129.188186,24 L81.1647588,51.7263387
      M83.0321682,55.268432 L137.190681,24 M145.195667,24 L84.9001588,58.8116279 M86.7675681,62.3537212 L153.198162,24
      M161.203148,24 L88.6355587,65.896917 M90.502968,69.4390103 L169.205643,24 M177.210628,24 L92.3709586,72.9822062
      M94.2383679,76.5242995 L185.213123,24 M193.218109,24 L96.1063586,80.0674954 M97.9737679,83.6095887 L201.220604,24
      M209.22559,24 L99.8417585,87.1527846 M101.709168,90.6948778 L217.228085,24 M225.233071,24 L103.577158,94.2380737
      M105.444568,97.780167 L233.235566,24 M235.794821,27.1440942 L107.312558,101.323363 M109.179968,104.865456
      L232.293165,33.7860187 M228.790419,40.4300107 L111.047958,108.408652 M112.915368,111.950745
      L225.288763,47.0719352 M221.786017,53.7159273 L114.783358,115.493941 M116.650768,119.036035
      L218.284361,60.3578518 M214.781615,67.0018438 L118.518758,122.57923 M120.386167,126.121324 L211.27996,73.6437683
      M207.777214,80.2877604 L122.254158,129.66452 M124.121567,133.206613 L204.275558,86.9296849 M200.772812,93.5736769
      L125.989558,136.749809 M127.856967,140.291902 L197.271156,100.215601 M193.76841,106.859593 L129.724958,143.835098
      M131.592367,147.377191 L190.266754,113.501518 M186.764009,120.14551 L133.460358,150.920387 M135.327767,154.46248
      L183.262353,126.787435 M179.759607,133.431427 L137.195758,158.005676 M139.063167,161.54777 L176.257951,140.073351
      M172.755205,146.717343 L140.931158,165.090965 M142.798567,168.633059 L169.253549,153.359268 M165.750803,160.00326
      L144.666558,172.176255 M146.533967,175.718348 L162.249147,166.645184 M158.746402,173.289176
      L148.401958,179.261544 M150.269367,182.803637 L155.244746,179.931101"
    />
  );
};

export default HatchTriangleInverted;
