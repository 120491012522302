import React from 'react';

const IconCode = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32">
      <path
        fillRule="evenodd"
        d="M13.297 7.839a1.034 1.034 0 0 1-.001 1.462l-5.71 5.71a1.034 1.034 0 0 0-.001 1.463l5.72 5.72a1.034 1.034
        0 0 1 0 1.463l-2.18 2.18a1.034 1.034 0 0 1-1.463.001l-9.36-9.36a1.034 1.034 0 0
        1 .001-1.461l9.354-9.354a1.034 1.034 0 0 1 1.463-.001l2.177 2.177zm5.406 0l2.177-2.177a1.034 1.034 0 0 1
        1.463 0l9.354 9.355c.404.404.404 1.058 0 1.462l-9.359 9.36a1.034 1.034 0 0 1-1.462-.002l-2.18-2.18a1.034
        1.034 0 0 1-.002-1.463l5.721-5.72a1.034 1.034 0 0 0-.001-1.463l-5.71-5.71a1.034 1.034 0 0 1-.001-1.462z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCode;
