import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Rhombus = () => {
  return (
    <path
      className={'shapeLayer ' + styles.shapeBg}
      fill="currentColor"
      fillRule="evenodd"
      d="M22.3889872,93.4036103 L149.495138,19.8283556 C151.044613,18.9314438 152.955387,18.9314438
      154.504862,19.8283556 L281.611013,93.4036103 C283.044959,94.2336486 283.534524,96.0689709 282.704485,97.5029173
      C282.441813,97.9567011 282.064797,98.3337175 281.611013,98.5963897 L154.504862,172.171644 C152.955387,173.068556
      151.044613,173.068556 149.495138,172.171644 L22.3889872,98.5963897 C20.9550408,97.7663514 20.4654765,95.9310291
      21.2955148,94.4970827 C21.5581871,94.0432989 21.9352034,93.6662825 22.3889872,93.4036103 Z"
    />
  );
};

export default Rhombus;
