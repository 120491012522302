import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Circle = () => {
  return (
    <circle
      className={'shapeLayer ' + styles.shapeBg}
      cx="152"
      cy="96"
      r="80"
      fill="currentColor"
      fillRule="evenodd"
    />
  );
};

export default Circle;
