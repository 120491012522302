import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Triangle = () => {
  return (
    <path
      className={'shapeLayer ' + styles.shapeBg}
      fill="currentColor"
      fillRule="evenodd"
      d="M154.65363,10.9476736 L235.133182,163.600913 C235.905877,165.066556 235.344131,166.881087
      233.878488,167.653781 C233.447188,167.881164 232.966968,168 232.479401,168 L71.5202963,168 C69.8634421,168
      68.5202963,166.656854 68.5202963,165 C68.5202963,164.512433 68.6391318,164.032212 68.8665149,163.600913
      L149.346067,10.9476736 C150.118761,9.48203061 151.933292,8.92028471 153.398935,9.69297905 C153.934114,9.97512797
      154.371481,10.4124949 154.65363,10.9476736 Z"
    />
  );
};

export default Triangle;
