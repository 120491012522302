import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Checkbox = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral5}
        fill="currentColor"
        fillRule="nonzero"
        d="M69,85.8181818 C67.2427303,85.8181818 65.8181818,87.2427303 65.8181818,89 L65.8181818,103
        C65.8181818,104.75727 67.2427303,106.181818 69,106.181818 L83,106.181818 C84.7572697,106.181818
        86.1818182,104.75727 86.1818182,103 L86.1818182,89 C86.1818182,87.2427303 84.7572697,85.8181818 83,85.8181818
        L69,85.8181818 Z M69,82 L83,82 C86.8659932,82 90,85.1340068 90,89 L90,103 C90,106.865993 86.8659932,110 83,110
        L69,110 C65.1340068,110 62,106.865993 62,103 L62,89 C62,85.1340068 65.1340068,82 69,82 Z"
      />
      <path
        className={styles.neutral9}
        fill="currentColor"
        fillRule="nonzero"
        d="M69,42 L83,42 C86.8659932,42 90,45.1340068 90,49 L90,63 C90,66.8659932 86.8659932,70 83,70 L69,70
        C65.1340068,70 62,66.8659932 62,63 L62,49 C62,45.1340068 65.1340068,42 69,42 Z M74.3753574,57.8382379
        C73.9235369,58.3766966 73.1207571,58.4469307 72.5822984,57.9951103 L69.2674152,55.213593 C68.7289565,54.7617725
        67.9261767,54.8320066 67.4743562,55.3704653 L66.165407,56.9304103 C65.7135865,57.4688689 65.7838207,58.2716488
        66.3222793,58.7234692 L73.1470389,64.4501225 C73.6854976,64.901943 74.4882774,64.8317088 74.9400979,64.2932502
        L85.9025481,51.2287108 C86.3543685,50.6902521 86.2841344,49.8874722 85.7456757,49.4356518
        L84.1857307,48.1267025 C83.647272,47.674882 82.8444922,47.7451161 82.3926717,48.2835748 L74.3753574,57.8382379
        Z"
      />
      <rect
        className={styles.neutral4}
        width="130"
        height="14"
        x="100"
        y="50"
        fill="currentColor"
        rx="7"
      />
      <path
        className={styles.neutral9}
        fill="currentColor"
        fillRule="nonzero"
        d="M69,122 L83,122 C86.8659932,122 90,125.134007 90,129 L90,143 C90,146.865993 86.8659932,150 83,150 L69,150
        C65.1340068,150 62,146.865993 62,143 L62,129 C62,125.134007 65.1340068,122 69,122 Z M74.3753574,137.838238
        C73.9235369,138.376697 73.1207571,138.446931 72.5822984,137.99511 L69.2674152,135.213593 C68.7289565,134.761773
        67.9261767,134.832007 67.4743562,135.370465 L66.165407,136.93041 C65.7135865,137.468869 65.7838207,138.271649
        66.3222793,138.723469 L73.1470389,144.450122 C73.6854976,144.901943 74.4882774,144.831709 74.9400979,144.29325
        L85.9025481,131.228711 C86.3543685,130.690252 86.2841344,129.887472 85.7456757,129.435652
        L84.1857307,128.126702 C83.647272,127.674882 82.8444922,127.745116 82.3926717,128.283575 L74.3753574,137.838238
        Z"
      />
      <rect
        className={styles.neutral4}
        width="143"
        height="14"
        x="100"
        y="130"
        fill="currentColor"
        rx="7"
      />
      <rect
        className={styles.neutral4}
        width="122"
        height="14"
        x="100"
        y="90"
        fill="currentColor"
        rx="7"
      />
    </g>
  );
};

export default Checkbox;
