import React from 'react';
import {
  DotSeparator,
  IconAndroid,
  IconCode,
  IconApple,
  IconFigma,
  IconPlatformDesign,
  IconReactNative,
  IconWeb,
} from 'docComponents';
import { Button, Headline, Icon, Link, Text } from 'hudl-uniform-ui-components';
import styles from './ReleaseNotes.module.scss';

const PlatformHeader = ({ libName, upgradeUrl, version, versionUrl }) => {
  let libIcon;

  switch (libName.toLowerCase()) {
    case 'components':
    case 'css':
      libIcon = <IconWeb className={styles.platformIcon} />;
      break;
    case 'react native':
      libIcon = <IconReactNative className={styles.platformIcon} />;
      break;
    case 'android':
      libIcon = <IconAndroid className={styles.platformIcon} />;
      break;
    case 'ios':
    case 'macos':
    case 'apple':
      libIcon = <IconApple className={styles.platformIcon} />;
      break;
    case 'sketch library':
      libIcon = <IconPlatformDesign className={styles.platformIcon} />;
      break;
    case 'figma library':
      libIcon = <IconFigma className={styles.platformIcon} />;
      break;
    case 'code':
      libIcon = <IconCode className={styles.platformIcon} />;
      break;
    case 'docs':
      libIcon = <Icon type="filetypeother" className={styles.platformIcon} />;
      break;
    default:
      libIcon = <IconWeb className={styles.platformIcon} />;
  }

  return (
    <div className={styles.platformHeader}>
      <div className={styles.platformInfo}>
        {libIcon}
        <Headline level="3">{libName}</Headline>

        {upgradeUrl && (
          <div className={styles.upgradeWrapper}>
            <DotSeparator />

            <Link type="wrapper" href={upgradeUrl} target="_blank">
              <Button type="primary" style="minimal" size="small">
                Upgrade Instructions
              </Button>
            </Link>
          </div>
        )}
      </div>

      {version && (
        <div className={styles.platformVersion}>
          <Text level="micro">
            <Link href={versionUrl} type="implied" target="_blank">
              v{version}
            </Link>
          </Text>
        </div>
      )}
    </div>
  );
};

export default PlatformHeader;
