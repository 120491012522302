import React from 'react';
import { Link } from 'hudl-uniform-ui-components';

const PageNavigationLink = ({ children }) => {
  return (
    <Link type="article" href={'#' + children}>
      {children}
    </Link>
  );
};

export default PageNavigationLink;
