import React from 'react';

const IconPlatformDesign = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32">
      <path
        d="M21.9 12.45h4.05L19.2 21.9l2.7-9.45zm-8.1 0h5.4l-2.7 10.8-2.7-10.8zm-6.75 0h4.05l2.7
        9.45-6.75-9.45zm13.5-6.75h2.7l2.7 4.05H21.9L20.55 5.7zm-5.4 0h2.7l1.35 4.05h-5.4l1.35-4.05zm-5.4 0h2.7L11.1
        9.75H7.05l2.7-4.05zM8.4 3L3 11.1 16.5 30 30 11.1 24.6 3H8.4z"
      />
    </svg>
  );
};

export default IconPlatformDesign;
