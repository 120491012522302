import React from 'react';
import styles from './PageNavigation.module.scss';

let scrolling = false;

export default class PageWithSubNav extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (!scrolling) {
      window.requestAnimationFrame(() => {
        const nav = document.querySelector('.jsPageNav').offsetTop;
        if (window.scrollY >= nav) {
          document.body.classList.add('navIsFixed');
        } else {
          document.body.classList.remove('navIsFixed');
        }
        scrolling = false;
      });

      scrolling = true;
    }
  }

  render() {
    return (
      <div className={'jsPageNav ' + styles.pageNavContainer}>
        <nav className={styles.pageNav} id="pageNavigation">
          {this.props.children}
        </nav>
      </div>
    );
  }
}
