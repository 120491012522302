import React from 'react';
import styles from './PlatformTable.module.scss';

const PlatformTable = ({ children, footerMessage }) => {
  const footer = footerMessage ? footerMessage : 'No guidelines exist for other platforms (yet).';

  return (
    <table className={styles.platformTable}>
      <thead className={styles.platformTableHeader}>
        <tr>
          <th scope="col">platform</th>
          <th scope="col">notes</th>
        </tr>
      </thead>
      <tbody>
        {children}
        <tr className={styles.platformTableRow}>
          <td />
          <td className={styles.footer}>{footer}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PlatformTable;
