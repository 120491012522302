import React from 'react';
import { Headline } from 'hudl-uniform-ui-components';
import { Link, Paragraph, SvgIllustration } from 'docComponents';
import styles from './PageHero.module.scss';

const PageHero = ({ heroData, tierTwo, tierThree, hideHeader }) => {
  let itemData;

  const tierTwoData = heroData.tierTwo.find(item => {
    return item.name === tierTwo;
  });

  if (tierThree) {
    itemData = tierTwoData.tierThree.find(item => {
      return item.name === tierThree;
    });
  } else {
    itemData = tierTwoData;
  }

  let descriptionText;

  // If the description contains links, we need to split them out into the
  // appropriate Link react component
  if (RegExp(/(\[[^)]+\])(\([^)]+\))/g).test(itemData.pageDescription)) {
    let i = 0; // holds the iteration of the link
    const linksArray = []; // contains all of the links in the description

    const linksReplacedString = itemData.pageDescription.replace(
      /\[(.*?)\]\((.*?)\)/g,
      (p1, p2, p3) => {
        const linkDescription = p2;
        const href = p3;

        // We need to make sure we use the design code link and we do that by figuring out global
        // nav page they are going to.
        const isDesignCode =
          href.indexOf('/guidelines') === 0 || href.indexOf('/components') === 0
            ? true
            : false;

        linksArray.push(
          <Link href={href} isDesignCodeLink={isDesignCode} key={href}>
            {linkDescription}
          </Link>
        );

        // This gives us a unique string we can replace with our link
        return '!' + i++ + '!';
      }
    );

    // We need to split the string up by the Links above
    const splitDescription = linksReplacedString.split(/(!\d!)/g);
    descriptionText = [];

    for (let j = 0; j < splitDescription.length; j++) {
      // Starts with a ! means it's a Link that needs replacing.
      if (splitDescription[j].indexOf('!') === 0) {
        // Pull out the number because that's the index of the link in the linksArray
        const number = splitDescription[j].replace('!', '').replace('!', '');
        descriptionText.push(linksArray[number]);
      } else {
        // Just text, so push it on to the description
        descriptionText.push(splitDescription[j]);
      }
    }
  } else {
    descriptionText = itemData.pageDescription;
  }

  return (
    <div>
      {itemData.heroText && !hideHeader && (
        <div className={styles.container}>
          <div className={styles.text}>
            <Headline level="2">{itemData.heroText}</Headline>
          </div>
          <div className={styles.media}>
            <SvgIllustration
              className={styles.svgContainer}
              shapeType={itemData.svgShape}
              hatchingType={itemData.svgHatching}
              illustrationType={itemData.svgIllustration}
            />
          </div>
        </div>
      )}

      <Paragraph>{descriptionText}</Paragraph>
    </div>
  );
};

export default PageHero;
