import React from 'react';

const Note = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fill="#45525E"
        d="M67,95 C67,82.3171429 77.3187857,72 90,72 C102.682857,72 113,82.3171429 113,95 C113,107.681214 102.682857,118 90,118 C77.3187857,118 67,107.681214 67,95 Z M86.7142857,82.6785714 C86.7142857,84.4988571 88.1797143,85.9642857 90,85.9642857 C91.8202857,85.9642857 93.2857143,84.4988571 93.2857143,82.6785714 C93.2857143,80.8582857 91.8202857,79.3928571 90,79.3928571 C88.1797143,79.3928571 86.7142857,80.8582857 86.7142857,82.6785714 Z M87.3303571,108.137929 C87.3303571,109.036571 88.0614286,109.785714 88.9617143,109.785714 L91.0382857,109.785714 C91.9270714,109.785714 92.6696429,109.048071 92.6696429,108.137929 L92.6696429,91.7175714 C92.6696429,90.8205714 91.9385714,90.0714286 91.0382857,90.0714286 L88.9617143,90.0714286 C88.0729286,90.0714286 87.3303571,90.8090714 87.3303571,91.7175714 L87.3303571,108.137929 Z"
      />
      <rect fill="#B7BFC7" x="129" y="89" width="109" height="12" rx="6" />
      <rect fill="#B7BFC7" x="129" y="109" width="69" height="12" rx="6" />
    </g>
  );
};

export default Note;
