import React from 'react';

const TeamAvatar = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <circle cx="152" cy="96" r="64" fill="#009CE3" />
      <path
        fill="#80CDF1"
        d="M152,63.5 C134.050654,63.5 119.5,78.0506536 119.5,96 C119.5,113.949346 134.050654,128.5 152,128.5
        C169.949346,128.5 184.5,113.949346 184.5,96 C184.5,78.0506536 169.949346,63.5 152,63.5 L152,63.5 Z
        M180.44281,96 C180.44281,97.3169935 180.347222,98.6021242 180.177288,99.8660131 C177.88317,98.1879085
        171.871732,93.5996732 168.770425,92.1658497 C169.089052,89.744281 169.843137,76.5106209 156.322712,67.8970588
        C169.970588,69.9681373 180.44281,81.7573529 180.44281,96 L180.44281,96 Z M152,67.5571895 L152.11683,67.5571895
        C152.223039,67.620915 152.339869,67.6952614 152.446078,67.7589869 C148.951797,68.9060458 135.505719,74.5563725
        124.629902,88.2679739 C127.996732,76.3300654 138.968137,67.5571895 152,67.5571895 L152,67.5571895 Z
        M123.67402,93.5147059 C127.593137,88.1511438 140.455065,73.6323529 155.122549,69.6176471 C157.533497,71.4763072
        159.986928,73.9509804 161.98366,77.126634 C158.053922,78.4436275 140.74183,85.0923203 126.382353,108.373366
        C124.576797,104.634804 123.55719,100.439542 123.55719,96 C123.55719,95.1609477 123.610294,94.3325163
        123.67402,93.5147059 L123.67402,93.5147059 Z M127.688725,110.752451 C131.129902,105.038399 144.384804,85.995098
        163.045752,78.9746732 C164.798203,82.3308824 165.987745,86.377451 166.147059,91.1887255 C149.589052,99.1331699
        139.021242,114.384804 136.153595,119.610294 C132.73366,117.316176 129.83415,114.289216 127.688725,110.752451
        L127.688725,110.752451 Z M172.041667,116.179739 C169.29085,118.909314 165.977124,121.086601
        162.291667,122.509804 C162.249183,122.477941 162.206699,122.435458 162.153595,122.403595 C155.759804,119.769608
        149.599673,113.099673 146.965686,109.679739 C149.461601,106.918301 151.989379,104.517974 154.379085,102.457516
        C158.680556,106.928922 167.740196,114.10866 172.041667,116.179739 L172.041667,116.179739 Z
        M138.553922,121.065359 C140.518791,117.730392 142.71732,114.69281 144.990196,111.963235 C148.218954,116.487745
        154.389706,121.437092 158.276961,123.731209 C156.258987,124.187908 154.156046,124.43219 152,124.43219
        C147.135621,124.44281 142.558007,123.210784 138.553922,121.065359 L138.553922,121.065359 Z
        M173.94281,114.087418 C169.301471,111.952614 159.933824,103.647059 156.630719,100.588235 C161.080882,97.0196078
        164.745098,94.8104575 166.221405,93.9607843 C168.430556,95.0972222 175.897059,99.7173203 179.518791,103.179739
        C178.46732,107.247549 176.534314,110.954248 173.94281,114.087418 L173.94281,114.087418 Z"
      />
      <circle
        cx="152"
        cy="96"
        r="62.5"
        stroke="#E6F2FF"
        strokeOpacity=".06"
        strokeWidth="3"
      />
    </g>
  );
};

export default TeamAvatar;
