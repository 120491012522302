import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ColorsTheme = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.level0}
        width="64"
        height="64"
        x="82"
        y="26"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.level1}
        width="64"
        height="64"
        x="158"
        y="26"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.level2}
        width="64"
        height="64"
        x="82"
        y="102"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.level3}
        width="64"
        height="64"
        x="158"
        y="102"
        fill="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="82.5"
        y="26.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="82.5"
        y="102.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="158.5"
        y="102.5"
        stroke="currentColor"
        rx="16"
      />
      <rect
        className={styles.outline}
        width="63"
        height="63"
        x="158.5"
        y="26.5"
        stroke="currentColor"
        rx="16"
      />
    </g>
  );
};

export default ColorsTheme;
