import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { ItemVisual, ItemNonVisual } from 'docComponents';
import styles from './ItemsList.module.scss';

const ItemsListIndexPage = ({ data, tierTwo, listType, listLayout }) => {
  const tierOnePath = data.allPagesYaml.edges[0].node.path;
  let indexData = data.allPagesYaml.edges[0].node.tierTwo;

  if (tierTwo) {
    indexData = data.allPagesYaml.edges[0].node.tierTwo
      .find(item => {
        return item.path === tierTwo;
      })
      .tierThree.filter(item => item.path !== '__index__');
  }

  const listClasses = classNames(styles.listContainer, {
    [styles.listLayoutTwo]: listLayout === '2',
    [styles.listLayoutThree]: listLayout === '3',
  });

  let listItems;
  if (listType === 'visual') {
    listItems = indexData.map((item, i) => (
      <ItemVisual
        key={i}
        text={item.name}
        svgShape={item.svgShape}
        svgHatching={item.svgHatching}
        svgIllustration={item.svgIllustration}
        path={
          tierTwo
            ? '/' + tierOnePath + '/' + tierTwo + '/' + item.path
            : '/' + tierOnePath + '/' + item.path
        }
      />
    ));
  } else {
    listItems = indexData.map((item, i) => (
      <ItemNonVisual
        key={i}
        text={item.name}
        description={item.pageDescription}
        path={
          tierTwo
            ? '/' + tierOnePath + '/' + tierTwo + '/' + item.path
            : '/' + tierOnePath + '/' + item.path
        }
      />
    ));
  }

  return <div className={listClasses}>{listItems}</div>;
};

export default ItemsListIndexPage;

export const query = graphql`
  fragment indexList on Query {
    allPagesYaml(filter: { tierOne: { eq: $tierOne } }) {
      edges {
        node {
          path

          tierTwo {
            name
            path
            svgShape
            svgHatching
            svgIllustration
            pageDescription

            tierThree {
              name
              path
              svgShape
              svgHatching
              svgIllustration
              pageDescription
            }
          }
        }
      }
    }
  }
`;
