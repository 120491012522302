import React from 'react';

const Level = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M54,47 L136,47 C140.418278,47 144,50.581722 144,55 L144,137 C144,141.418278 140.418278,145 136,145 L54,145
        C49.581722,145 46,141.418278 46,137 L46,55 C46,50.581722 49.581722,47 54,47 Z"
      />
      <path
        fill="#13293F"
        fillOpacity=".1"
        fillRule="nonzero"
        d="M54,48 C50.1340068,48 47,51.1340068 47,55 L47,137 C47,140.865993 50.1340068,144 54,144 L136,144
        C139.865993,144 143,140.865993 143,137 L143,55 C143,51.1340068 139.865993,48 136,48 L54,48 Z M54,47 L136,47
        C140.418278,47 144,50.581722 144,55 L144,137 C144,141.418278 140.418278,145 136,145 L54,145 C49.581722,145
        46,141.418278 46,137 L46,55 C46,50.581722 49.581722,47 54,47 Z"
      />
      <path
        fill="#101417"
        d="M168,47 L250,47 C254.418278,47 258,50.581722 258,55 L258,137 C258,141.418278 254.418278,145 250,145 L168,145
        C163.581722,145 160,141.418278 160,137 L160,55 C160,50.581722 163.581722,47 168,47 Z"
      />
      <path
        fill="#E6F2FF"
        fillOpacity=".1"
        fillRule="nonzero"
        d="M168,48 C164.134007,48 161,51.1340068 161,55 L161,137 C161,140.865993 164.134007,144 168,144 L250,144
        C253.865993,144 257,140.865993 257,137 L257,55 C257,51.1340068 253.865993,48 250,48 L168,48 Z M168,47 L250,47
        C254.418278,47 258,50.581722 258,55 L258,137 C258,141.418278 254.418278,145 250,145 L168,145 C163.581722,145
        160,141.418278 160,137 L160,55 C160,50.581722 163.581722,47 168,47 Z"
      />
      <rect width="96" height="24" x="47" y="72" fill="#F9FAFB" />
      <rect width="96" height="24" x="47" y="96" fill="#F2F5F7" />
      <path
        fill="#EBEFF2"
        d="M47,120 L143,120 L143,137 C143,140.865993 139.865993,144 136,144 L54,144 C50.1340068,144 47,140.865993
        47,137 L47,120 Z"
      />
      <rect width="96" height="24" x="161" y="72" fill="#191F24" />
      <rect width="96" height="24" x="161" y="96" fill="#232A31" />
      <path
        fill="#353F4A"
        d="M161,120 L257,120 L257,137 C257,140.865993 253.865993,144 250,144 L168,144 C164.134007,144 161,140.865993
        161,137 L161,120 Z"
      />
    </g>
  );
};

export default Level;
