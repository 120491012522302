import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchDiamondSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M145.283089,10.0859229 L154.433766,4.8027772 M157.363794,7.7328054 L134.348075,21.020937
      M123.416463,31.9525483 L160.29291,10.6619218 M163.222938,13.59195 L112.481449,42.8875623 M101.549838,53.8191736
      L166.152055,16.5210664 M169.082083,19.4510946 L90.614824,64.7541877 M79.6832127,75.685799 L172.011199,22.380211
      M174.941228,25.3102392 L68.7481986,86.6208131 M60.4189571,96.0499454 L177.870344,28.2393556
      M180.800372,31.1693838 L63.3489853,98.9799736 M66.2781017,101.90909 L183.729489,34.0985002 M186.659517,37.0285284
      L69.2081299,104.839118 M72.1372463,107.768235 L189.588633,39.9576448 M192.518661,42.887673 L75.0672745,110.698263
      M77.9963909,113.627379 L195.447778,45.8167895 M198.377806,48.7468176 L80.9264191,116.557407
      M83.8555355,119.486524 L201.306922,51.6759341 M204.236951,54.6059623 L86.7855637,122.416552
      M89.7146801,125.345668 L207.166067,57.5350787 M210.096095,60.4651069 L92.6447083,128.275697
      M95.5738248,131.204813 L213.025212,63.3942233 M215.95524,66.3242515 L98.503853,134.134841 M101.432969,137.063958
      L218.884356,69.2533679 M221.814384,72.1833961 L104.362998,139.993986 M107.292114,142.923102
      L224.743501,75.1125125 M227.673529,78.0425407 L110.222142,145.853131 M113.151259,148.782247
      L230.602645,80.9716571 M233.532674,83.9016853 L116.081287,151.712275 M119.010403,154.641392 L236.46179,86.8308017
      M239.391818,89.7608299 L121.940431,157.57142 M124.869548,160.500536 L242.320935,92.6899463 M245.130988,95.5
      L244.683237,95.947751 L127.799576,163.430564 M130.728692,166.359681 L233.751626,106.879362 M222.816612,117.814376
      L133.658721,169.289709 M136.587837,172.218825 L211.885001,128.745988 M200.949987,139.681002
      L139.517865,175.148854 M142.446982,178.07797 L190.018375,150.612613 M179.083361,161.547627 L145.37701,181.007998
      M148.306126,183.937115 L168.15175,172.479238 M157.216736,183.414252 L151.236154,186.867143"
    />
  );
};

export default HatchDiamondSmall;
