import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSquareSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M107.090549,46 L102,48.9390299 M102,53.5592725 L115.093044,46 M123.09803,46 L102,58.1809533 M102,62.8011958
      L131.100525,46 M139.105511,46 L102,67.4228766 M102,72.0431191 L147.108006,46 M155.112991,46 L102,76.6647999
      M102,81.2850425 L163.115486,46 M171.120472,46 L102,85.9067232 M102,90.5269658 L179.122967,46 M187.127953,46
      L102,95.1486466 M102,99.7688891 L195.130448,46 M203,46.0781927 L102,104.39057 M102,109.010812 L203,50.6984352
      M203,55.320116 L102,113.632493 M102,118.252736 L203,59.9403586 M203,64.5620393 L102,122.874417 M102,127.494659
      L203,69.1822819 M203,73.8039627 L102,132.11634 M102,136.736582 L203,78.4242052 M203,83.045886 L102,141.358263
      M102,145.978506 L203,87.6661285 M203,92.2878093 L108.235706,147 M116.238201,147 L203,96.9080519 M203,101.529733
      L124.243187,147 M132.245682,147 L203,106.149975 M203,110.771656 L140.250667,147 M148.253162,147 L203,115.391899
      M203,120.013579 L156.258148,147 M164.260643,147 L203,124.633822 M203,129.255503 L172.265629,147 M180.268124,147
      L203,133.875745 M203,138.497426 L188.27311,147 M196.275605,147 L203,143.117668"
    />
  );
};

export default HatchSquareSmall;
