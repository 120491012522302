import React from 'react';

const OrgAvatar = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <circle cx="152" cy="96" r="64" fill="#353F4A" />
      <circle
        cx="152"
        cy="96"
        r="62.5"
        stroke="#E6F2FF"
        strokeOpacity=".06"
        strokeWidth="3"
      />
      <rect
        width="83.2"
        height="51.2"
        x="110.4"
        y="70.4"
        fill="#7B8692"
        rx="25.6"
      />
    </g>
  );
};

export default OrgAvatar;
