import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchCircle = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M102.936051,32.8072647 L121.915752,21.8493293 M137.909801,17.2368416 L90.8643562,44.3985418
      M84.8575089,52.4868392 L147.873408,16.104593 M155.897954,16.0932997 L80.8256091,59.4363384 M77.9243844,65.7316039
      L162.808214,16.7239017 M168.950563,17.7992925 L75.7946993,71.5828589 M74.246295,77.0970731 L174.509253,19.210227
      M179.604393,20.8902278 L73.1605751,82.3455945 M72.4600306,87.3702967 L184.310742,22.7932583
      M188.686108,24.8888204 L72.0883529,92.2065656 M72.0046938,96.8751088 L192.767843,27.1524722
      M196.588571,29.5682484 L72.1791128,101.396089 M72.591592,105.778186 L200.168926,32.1213781 M203.528937,34.8031557
      L73.2267295,110.03317 M74.0714212,114.16573 L206.680356,37.6039259 M209.635905,40.5192196 L75.1159045,118.184378
      M76.3512938,122.091368 L212.401992,43.5424611 M214.986515,46.6719667 L77.7718939,125.892865 M79.371936,129.589323
      L217.392259,49.9032523 M219.623544,53.2366999 L81.1489706,133.185032 M83.0998047,136.67896 L221.680371,56.669433
      M223.564056,60.2035674 L85.2248843,140.073726 M87.5234438,143.366894 L225.272148,63.8376425
      M226.803101,67.5754275 L89.9988488,146.559399 M92.6529727,149.647283 L228.151968,71.4169011
      M229.314109,75.3676199 L95.4925267,152.629546 M98.5226851,155.500326 L230.281673,79.4292386
      M231.046266,83.6094819 L101.754502,158.256116 M105.197837,160.888349 L231.596232,87.9122011
      M231.918127,92.3480356 L108.870112,163.38984 M112.788687,165.747693 L231.994773,96.9240265 M231.80313,101.656352
      L116.981499,167.948652 M121.479482,169.971983 L231.30899,106.561887 M230.465903,111.670337 L126.330576,171.792884
      M131.594338,173.374092 L229.211257,117.014937 M227.45315,122.651662 L137.364582,174.66432 M143.776718,175.582514
      L225.052222,128.658081 M221.767449,135.176226 L151.067811,175.994681 M159.69359,175.634827 L217.120248,142.479531
      M209.762049,151.34947 L170.995882,173.731127"
    />
  );
};

export default HatchCircle;
