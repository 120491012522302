import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Elevation = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.action}
        fill="currentColor"
        d="M74,135.5 L74,154 C74,155.104569 73.1045695,156 72,156 C70.8954305,156 70,155.104569 70,154 L70,135.5
        L65.4021238,135.5 C65.1259814,135.5 64.9021238,135.276142 64.9021238,135 C64.9021238,134.90629
        64.9284585,134.814466 64.9781247,134.735001 L71.5760008,124.178399 C71.722356,123.94423 72.0308312,123.873044
        72.2649995,124.019399 C72.329384,124.059639 72.3837588,124.114014 72.4239992,124.178399 L79.0218753,134.735001
        C79.1682305,134.969169 79.0970439,135.277644 78.8628756,135.423999 C78.7834098,135.473665 78.691586,135.5
        78.5978762,135.5 L74,135.5 Z"
      />
      <rect
        className={styles.neutral10}
        width="80"
        height="4"
        x="112"
        y="76"
        fill="currentColor"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M154,93.5 L154,154 C154,155.104569 153.104569,156 152,156 C150.895431,156 150,155.104569 150,154 L150,93.5
        L145.402124,93.5 C145.125981,93.5 144.902124,93.2761424 144.902124,93 C144.902124,92.9062901
        144.928459,92.8144663 144.978125,92.7350005 L151.576001,82.1783986 C151.722356,81.9442304 152.030831,81.8730438
        152.264999,82.019399 C152.329384,82.0596393 152.383759,82.1140141 152.423999,82.1783986 L159.021875,92.7350005
        C159.16823,92.9691688 159.097044,93.277644 158.862876,93.4239992 C158.78341,93.4736653 158.691586,93.5
        158.597876,93.5 L154,93.5 Z"
      />
      <rect
        className={styles.neutral10}
        width="80"
        height="4"
        x="32"
        y="118"
        fill="currentColor"
      />
      <rect
        className={styles.neutral10}
        width="240"
        height="4"
        x="32"
        y="158"
        fill="currentColor"
      />
      <rect
        className={styles.neutral10}
        width="80"
        height="4"
        x="192"
        y="34"
        fill="currentColor"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M234,51.5 L234,154 C234,155.104569 233.104569,156 232,156 C230.895431,156 230,155.104569 230,154 L230,51.5
        L225.402124,51.5 C225.125981,51.5 224.902124,51.2761424 224.902124,51 C224.902124,50.9062901
        224.928459,50.8144663 224.978125,50.7350005 L231.576001,40.1783986 C231.722356,39.9442304 232.030831,39.8730438
        232.264999,40.019399 C232.329384,40.0596393 232.383759,40.1140141 232.423999,40.1783986 L239.021875,50.7350005
        C239.16823,50.9691688 239.097044,51.277644 238.862876,51.4239992 C238.78341,51.4736653 238.691586,51.5
        238.597876,51.5 L234,51.5 Z"
      />
    </g>
  );
};

export default Elevation;
