import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const FormModifier = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral3}
        width="132"
        height="28"
        x="78"
        y="75"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.level0}
        width="128"
        height="24"
        x="80"
        y="77"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.neutral5}
        width="46"
        height="8"
        x="78"
        y="64"
        fill="currentColor"
        rx="4"
      />
      <rect
        className={styles.neutral10}
        width="2"
        height="14"
        x="86"
        y="82"
        fill="currentColor"
      />
      <rect
        className={styles.neutral3}
        width="112"
        height="24"
        x="78"
        y="27"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.level0}
        width="108"
        height="20"
        x="80"
        y="29"
        fill="currentColor"
        rx="6"
      />
      <rect
        className={styles.neutral5}
        width="28"
        height="6"
        x="78"
        y="19"
        fill="currentColor"
        rx="3"
      />
      <rect
        className={styles.neutral10}
        width="2"
        height="11"
        x="86"
        y="34"
        fill="currentColor"
      />
      <rect
        className={styles.neutral3}
        width="156"
        height="38"
        x="78"
        y="131"
        fill="currentColor"
        rx="10"
      />
      <rect
        className={styles.level0}
        width="152"
        height="34"
        x="80"
        y="133"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral5}
        width="63"
        height="10"
        x="78"
        y="117"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral10}
        width="2"
        height="22"
        x="86"
        y="138"
        fill="currentColor"
      />
    </g>
  );
};

export default FormModifier;
