import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchRhombus = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M153.464365,19.2260649 L19.194105,96.7470357 M23.1901662,99.0601513 L157.460426,21.5391805
      M161.457731,23.8530161 L27.1874712,101.373987 M31.1835324,103.687102 L165.453792,26.1661317
      M169.451097,28.4799673 L35.1808374,106.000938 M39.1768986,108.314054 L173.447159,30.7930829
      M177.444464,33.1069185 L43.1742036,110.627889 M47.1702648,112.941005 L181.440525,35.4200341 M185.43783,37.7338697
      L51.1675699,115.25484 M55.163631,117.567956 L189.433891,40.0469853 M193.431196,42.3608209 L59.1609361,119.881792
      M63.1569972,122.194907 L197.427257,44.6739365 M201.424562,46.9877721 L67.1543023,124.508743
      M71.1503634,126.821858 L205.420623,49.3008877 M209.417928,51.6147233 L75.1476685,129.135694 M79.1437296,131.44881
      L213.41399,53.9278389 M217.411295,56.2416745 L83.1410347,133.762645 M87.1370959,136.075761 L221.407356,58.55479
      M225.404661,60.8686257 L91.1344009,138.389596 M95.1304621,140.702712 L229.400722,63.1817412
      M233.398027,65.4955768 L99.1277671,143.016548 M103.123828,145.329663 L237.394088,67.8086924 M241.391393,70.122528
      L107.121133,147.643499 M111.117194,149.956614 L245.387454,72.4356436 M249.38476,74.7494792 L115.1145,152.27045
      M119.110561,154.583566 L253.380821,77.0625948 M257.378126,79.3764304 L123.107866,156.897401
      M127.103927,159.210517 L261.374187,81.689546 M265.371492,84.0033816 L131.101232,161.524352 M135.097293,163.837468
      L269.367553,86.3164972 M273.364858,88.6303328 L139.094598,166.151304 M143.090659,168.464419
      L277.360919,90.9434484 M281.358224,93.257284 L147.087964,170.778255 M151.084026,173.09137 L285.354286,95.5703996"
    />
  );
};

export default HatchRhombus;
