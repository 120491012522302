import React from 'react';
import {
  DataTable,
  DataTableHeader,
  DataTableBody,
  DataTableRow,
  DataTableCodeBlock,
  DataTableColorBlock,
} from 'docComponents';

const DeprecatedColorDataTableCode = ({ data, colorsType, env, platform }) => {
  const dataColors = [];

  if (data.allColorsYaml) {
    data.allColorsYaml.edges.forEach(element => {
      switch (colorsType) {
        case 'dark':
          if (element.node.dark_environment_colors) {
            element.node.dark_environment_colors.forEach(color => {
              dataColors.push(color);
            });
          }
          break;
        case 'light':
          if (element.node.light_environment_colors) {
            element.node.light_environment_colors.forEach(color => {
              dataColors.push(color);
            });
          }
          break;
        default:
          if (element.node.colors) {
            element.node.colors.forEach(color => {
              dataColors.push(color);
            });
          }
          break;
      }
    });
  } else {
    switch (colorsType) {
      case 'dark':
        if (data[0].node.dark_environment_colors) {
          data[0].node.dark_environment_colors.forEach(color => {
            dataColors.push(color);
          });
        }
        break;
      case 'light':
        if (data[0].node.light_environment_colors) {
          data[0].node.light_environment_colors.forEach(color => {
            dataColors.push(color);
          });
        }
        break;
      default:
        if (data[0].node.colors) {
          data[0].node.colors.forEach(color => {
            dataColors.push(color);
          });
        }
        break;
    }
  }

  return (
    <DataTable env={env}>
      <DataTableHeader>
        <DataTableRow layout="colorThirds">
          <th>Color</th>
          <th>Deprecated</th>
          <th>Replacement</th>
        </DataTableRow>
      </DataTableHeader>

      <DataTableBody>
        {dataColors.map(item => {
          let platformData;
          switch (platform) {
            case 'web':
              platformData = item.sass_var;
              break;
            case 'reactnative':
              platformData = item.rn_var;
              break;
            case 'android':
              platformData = item.android_var;
              break;
            case 'apple':
              platformData = item.apple_var;
              break;
            default:
              platformData = item.sass_var;
          }

          let platformDataReplacement;
          switch (platform) {
            case 'web':
              platformDataReplacement = item.sass_var_replacement;
              break;
            case 'reactnative':
              platformDataReplacement = item.rn_var_replacement;
              break;
            case 'android':
              platformDataReplacement = item.android_var_replacement;
              break;
            case 'apple':
              platformDataReplacement = item.apple_var_replacement;
              break;
            default:
              platformDataReplacement = item.sass_var_replacement;
          }

          return (
            <DataTableRow layout="colorThirds" key={platformData}>
              <DataTableColorBlock value={item.value} />
              <DataTableCodeBlock>{platformData}</DataTableCodeBlock>
              <DataTableCodeBlock>{platformDataReplacement}</DataTableCodeBlock>
            </DataTableRow>
          );
        })}
      </DataTableBody>
    </DataTable>
  );
};

export default DeprecatedColorDataTableCode;
