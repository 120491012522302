import React from 'react';
import { Text } from 'hudl-uniform-ui-components';
import styles from './ComponentWithLabel.module.scss';

export default class ComponentWithLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: props.labelText,
    };
  }

  hoverState() {
    if (this.state.label !== 'Focus') {
      this.setState({ label: 'Hover' });
    }
  }

  hoverStateRemove() {
    if (this.state.label !== 'Focus') {
      this.setState({ label: this.props.labelText });
    }
  }

  activeState(event) {
    // We want to listen for mouse click (undefined) or space bar
    if (
      event.code === undefined ||
      event.code === 'Space' ||
      event.code === 'Tab'
    ) {
      this.setState({ label: 'Active' });
    }
  }

  activeStateRemove() {
    if (this.state.label !== 'Focus' && this.state.label !== 'Active') {
      this.setState({ label: this.props.labelText });
    } else {
      this.setState({ label: 'Focus' });
    }
  }

  focusState() {
    if (this.state.label !== 'Active') {
      this.setState({ label: 'Focus' });
    }
  }
  focusStateRemove() {
    this.setState({ label: this.props.labelText });
  }

  render() {
    let wrapperComponent;
    if (this.props.showStates) {
      wrapperComponent = (
        <div
          onMouseEnter={this.hoverState.bind(this)}
          onMouseLeave={this.hoverStateRemove.bind(this)}
          onMouseDown={this.activeState.bind(this)}
          onMouseUp={this.activeStateRemove.bind(this)}
          onFocus={this.focusState.bind(this)}
          onBlur={this.focusStateRemove.bind(this)}
          onKeyDown={this.activeState.bind(this)}
          onKeyUp={this.activeStateRemove.bind(this)}>
          {this.props.children}
        </div>
      );
    } else {
      wrapperComponent = <div>{this.props.children}</div>;
    }

    return (
      <div className={styles.container}>
        {wrapperComponent}
        {this.props.labelText && (
          <Text level="small" className="uni-margin--half--top">
            {this.state.label}
          </Text>
        )}
      </div>
    );
  }
}
