import React from 'react';
import classNames from 'classnames';
import { LiveProvider, LiveEditor, LivePreview } from 'react-live';
import { copyTextToClipboard } from '../../utility/js/clipboard';
import { Link } from 'hudl-uniform-ui-components';
import { IconCopy, IconGitHub } from 'docComponents';
import styles from './CodeSnippet.module.scss';

export default class CodeSnippet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      codeSnippet: this.props.code,
      codeBlockClass: '',
    };
  }

  componentDidMount() {
    if (document) {
      let codeEditor;
      if (this.props.id) {
        codeEditor = document.getElementById(this.props.id);
      } else {
        codeEditor = document.getElementsByClassName('prism-code')[0];
      }

      if (this.props.disableCodeEditing) {
        codeEditor.setAttribute('contenteditable', 'false');
      } else {
        codeEditor.focus();
      }
    }
  }

  copyCodeBlock(e) {
    this.setState({ codeBlockClass: 'active' });

    const el = e.currentTarget;
    copyTextToClipboard(this.state.codeSnippet, el);
  }

  editorChange(e) {
    this.setState({ codeSnippet: e });
  }

  removeCodeBlockActiveClass() {
    this.setState({ codeBlockClass: '' });
  }

  buildGitHubUrl(platform, gitHubLink) {
    let gitHubUrl = '';
    switch (platform) {
      case 'react':
        gitHubUrl =
          'https://github.com/hudl/uniform-ui-components/tree/master/src/';
        break;
      case 'css':
        gitHubUrl =
          'https://github.com/hudl/uniform-ui-css/tree/master/source/scss/';
        break;
      case 'react-native':
        gitHubUrl =
          'https://github.com/hudl/rn-uniform-ui/tree/master/src/components/';
        break;
      case 'apple':
        gitHubUrl =
          'https://github.com/hudl/ios-uniform-ui/tree/master/UniformUI/Classes/';
        break;
      case 'android':
        gitHubUrl =
          'https://github.com/hudl/android-framework/tree/master/uniform-ui/src/';
        break;
      default:
        gitHubUrl =
          'https://github.com/hudl/uniform-ui-components/tree/master/src/';
    }

    return gitHubUrl + gitHubLink;
  }

  getLanguage(platform) {
    let language = '';
    switch (platform) {
      case 'react':
        language = 'jsx';
        break;
      case 'css':
        language = 'css';
        break;
      case 'react-native':
        language = 'jsx';
        break;
      case 'apple':
        language = 'swift';
        break;
      case 'android':
        language = 'java';
        break;
      default:
        language = platform;
    }

    return language;
  }

  render() {
    const {
      code,
      scope,
      platform,
      gitHubLink,
      id,
      disableCodeEditing,
      className,
      layout,
    } = this.props;

    const { codeBlockClass } = this.state;

    const snippetClasses = classNames(styles.codeSnippet, {
      [className]: className,
    });

    const codeBlockClasses = classNames(styles.snippetCodeBlock, {
      [styles.snippetCodeBlockActive]: codeBlockClass === 'active',
      [styles.snippetCodeBlockNoPreview]: disableCodeEditing,
      [styles.snippetCodeBlockAllowEditing]: !disableCodeEditing,
    });

    const snippetBarClasses = classNames(styles.snippetBar, {
      [styles.snippetBarBothIcons]: gitHubLink !== undefined,
    });

    const codePreviewClasses = classNames(styles.codePreview, {
      [styles.snippetCodeBlockReactNative]: layout === 'rn',
    });

    return (
      <div className={snippetClasses}>
        <LiveProvider
          code={code}
          scope={scope}
          mountStylesheet={false}
          noInline={disableCodeEditing}>
          {!disableCodeEditing && (
            <LivePreview className={codePreviewClasses} />
          )}
          <LiveEditor
            id={id}
            className={codeBlockClasses}
            onChange={this.editorChange.bind(this)}
          />
        </LiveProvider>
        <div className={snippetBarClasses}>
          {gitHubLink && (
            <Link
              type="wrapper"
              displayType="block"
              href={this.buildGitHubUrl(platform, gitHubLink)}
              target="_blank">
              <div className={styles.snippetControl}>
                <IconGitHub />
              </div>
            </Link>
          )}
          <button
            className={styles.button}
            onClick={this.copyCodeBlock.bind(this)}>
            <div className={styles.snippetControl}>
              <IconCopy />
            </div>
          </button>
        </div>
      </div>
    );
  }
}
