import React from 'react';
import styles from '../..//SvgIllustration.module.scss';

const HatchCircleSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M111.518272,57.305819 L138.770347,41.5718262 M149.394707,40.05953 L104.845907,65.7797913
      M101.134847,72.5426156 L157.103007,40.2293832 M163.469199,41.1755393 L98.7810004,78.5232902 M97.2874439,84.005838
      L168.972803,42.6182768 M173.851206,44.4234101 L96.4171996,89.1299545 M96.0361016,93.9702242 L178.2348,46.5127836
      M182.210019,48.839365 L96.0583351,98.5790684 M96.4312635,102.984001 L185.830025,51.3696015 M189.135328,54.0829636
      L97.1216427,107.207091 M98.1043584,111.259962 L192.150554,56.9623658 M194.896026,59.9989473
      L99.3618956,115.155603 M100.880632,118.899003 L197.382256,63.1837646 M199.618009,66.5146328
      L102.653144,122.497324 M104.673991,125.950829 L201.604821,69.9877888 M203.342922,73.6059767
      L106.943954,129.261946 M109.465108,132.426601 L204.826354,77.3697593 M206.047093,81.286646 L112.246874,135.442227
      M115.30051,138.299453 L206.990871,85.3619979 M207.639338,89.6092865 L118.647353,140.988833 M122.313344,143.492514
      L207.966366,94.040719 M207.937031,98.6793366 L126.341008,145.788822 M130.786516,147.842449 L207.494739,103.554936
      M206.548375,108.723031 L135.741524,149.603355 M141.346079,150.987806 L204.954205,114.263638 M202.453208,120.32927
      L147.859054,151.849219 M155.822842,151.871567 L198.481739,127.242441 M191.008669,136.1787 L167.240914,149.901021"
    />
  );
};

export default HatchCircleSmall;
