import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchParallelogramStretch = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="#EDF0F2"
      strokeLinecap="round"
      d="M199.671332,48 L34.4299576,143.402152 M42.4324525,143.402152 L207.673827,48 M215.678813,48
      L50.4374384,143.402152 M58.4399332,143.402152 L223.681308,48 M231.686294,48 L66.4449191,143.402152
      M74.447414,143.402152 L239.688788,48 M247.693774,48 L82.4523999,143.402152 M90.4548948,143.402152 L255.696269,48
      M263.701255,48 L98.4598807,143.402152 M106.462376,143.402152 L271.70375,48 M106.612582,143.402152
      L106.462376,143.402152"
    />
  );
};

export default HatchParallelogramStretch;
