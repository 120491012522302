import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const ContentSelection = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        fill="#13293F"
        fillOpacity=".03"
        d="M61,23 L243,23 C251.284271,23 258,29.7157288 258,38 L258,154 C258,162.284271 251.284271,
        169 243,169 L61,169 C52.7157288,169 46,162.284271 46,154 L46,38 C46,29.7157288 52.7157288,
        23 61,23 Z"
      />
      <rect
        width="200"
        height="132"
        x="52"
        y="29"
        fill="#13293F"
        fillOpacity=".03"
        rx="12"
      />
      <rect
        className={styles.whiteBlack}
        width="192"
        height="124"
        x="56"
        y="33"
        fill="currentColor"
        rx="8"
      />
      <rect
        className={styles.neutral5}
        width="62"
        height="8"
        x="114"
        y="50"
        fill="currentColor"
        rx="4"
      />
      <rect
        className={styles.neutral3}
        width="146"
        height="8"
        x="71"
        y="87"
        fill="currentColor"
        rx="4"
      />
      <rect
        className={styles.neutral3}
        width="46"
        height="8"
        x="71"
        y="101"
        fill="currentColor"
        rx="4"
      />
      <rect
        className={styles.neutral3}
        width="25"
        height="4"
        x="114"
        y="62"
        fill="currentColor"
        rx="2"
      />
      <rect
        className={styles.neutral3}
        width="13"
        height="4"
        x="145"
        y="62"
        fill="currentColor"
        rx="2"
      />
      <rect
        className={styles.neutral2}
        width="62"
        height="8"
        x="81"
        y="136"
        fill="currentColor"
        rx="4"
      />
      <rect width="62" height="8" x="162" y="136" fill="#009CE3" rx="4" />
      <rect
        className={styles.neutral2}
        width="162"
        height="2"
        x="71"
        y="120"
        fill="currentColor"
      />
      <g transform="translate(71 43)">
        <circle cx="15" cy="15" r="15" fill="#353F4A" />
        <path
          fill="#7B8692"
          d="M3.95907832,25.1538412 C5.00445436,23.5376999 6.70243738,22.2568645 8.80729876,
          21.4167295 C10.5586764,22.6776609 12.7071688,23.4190845 15.0277442,23.4190845 C17.3473013,
          23.4190845 19.4947754,22.6776609 21.2471713,21.4167295 C23.3363553,22.2509336 25.0253758,
          23.5194081 26.0726895,25.1192078 C23.3299842,28.118677 19.3846954,30 15,30 C10.6322049,
          30 6.70043565,28.1331517 3.95907832,25.1538412 Z M6.88180158,12.6812257 C6.88180158,
          8.16314382 10.5281291,4.5 15.0277442,4.5 C19.526341,4.5 23.1726685,8.16314382 23.1726685,
          12.6812257 C23.1726685,17.2003303 19.526341,20.8624515 15.0277442,20.8624515 C10.5281291,
          20.8624515 6.88180158,17.2003303 6.88180158,12.6812257 Z"
        />
      </g>
    </g>
  );
};

export default ContentSelection;
