import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchSashSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M89,115.516366 L215.533316,42.4623218 M215.533316,47.0840026 L89,120.138047 M89,124.758289
      L215.533316,51.7042452 M215.533316,56.3259259 L89,129.37997 M89,134.000213 L215.533316,60.9461685
      M215.533316,65.5678493 L89,138.621893 M89,143.242136 L215.533316,70.1880918 M215.533316,74.8097726 L89,147.863817
      M89,152.484059 L215.533316,79.4300151"
    />
  );
};

export default HatchSashSmall;
