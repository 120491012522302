import React from 'react';
import classNames from 'classnames';
import { doesHashMatchName } from '../../utility/js/location';
import { CopyUrlButton } from 'docComponents';
import { Headline, ItemTitle } from 'hudl-uniform-ui-components';
import styles from './PropListItem.module.scss';

const PropListItem = ({ children, name, types }) => {
  const headerClasses = classNames(styles.propListItemHeader, {
    [styles.propListItemHeaderActivate]: doesHashMatchName(name),
  });

  const headlineWrapperClasses = classNames(
    'propList-headline-wrapper',
    styles.propListHeadlineWrapper
  );

  return (
    <div className={styles.propListItem}>
      <div id={name} className={headerClasses}>
        <div className={headlineWrapperClasses}>
          <Headline level="3">{name}</Headline>
          <CopyUrlButton url={name} includePlatform />
        </div>
        <div className={styles.propListDataTypes}>
          {types.map((type, key) => (
            <ItemTitle isCaps className="uni-pad--one--right" key={key}>
              {type}
            </ItemTitle>
          ))}
        </div>
      </div>

      <div className={styles.propListItemDescription}>{children}</div>
    </div>
  );
};

export default PropListItem;
