import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const LookupSelect = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <rect
        className={styles.neutral3}
        width="202"
        height="122"
        x="51"
        y="36"
        fill="currentColor"
        rx="12"
      />
      <rect
        className={styles.level0}
        width="198"
        height="118"
        x="53"
        y="38"
        fill="currentColor"
        rx="10"
      />
      <rect
        className={styles.neutral3}
        width="118"
        height="10"
        x="63"
        y="74"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral3}
        width="108"
        height="10"
        x="63"
        y="94"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral3}
        width="148"
        height="10"
        x="63"
        y="114"
        fill="currentColor"
        rx="5"
      />
      <rect
        className={styles.neutral3}
        width="126"
        height="10"
        x="63"
        y="134"
        fill="currentColor"
        rx="5"
      />
      <path
        className={styles.neutral5}
        fill="currentColor"
        d="M233,54.9061637 L237.720663,50.3753453 C238.242092,49.8748849 239.087498,49.8748849 239.608928,50.3753453
        C240.130357,50.8758057 240.130357,51.6872123 239.608928,52.1876726 L233.944133,57.6246547
        C233.422703,58.1251151 232.577297,58.1251151 232.055867,57.6246547 L226.391072,52.1876726
        C225.869643,51.6872123 225.869643,50.8758057 226.391072,50.3753453 C226.912502,49.8748849 227.757908,49.8748849
        228.279337,50.3753453 L233,54.9061637 Z"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M68 49L81.4361148 49C82.3276335 49 82.6509198 49.0928256 82.9768457 49.2671327 83.3027716 49.4414398
        83.5585602 49.6972284 83.7328673 50.0231543 83.9071744 50.3490802 84 50.6723665 84 51.5638852L84 56.4361148C84
        57.3276335 83.9071744 57.6509198 83.7328673 57.9768457 83.5585602 58.3027716 83.3027716 58.5585602 82.9768457
        58.7328673 82.6509198 58.9071744 82.3276335 59 81.4361148 59L68 59C65.2385763 59 63 56.7614237 63 54L63 54C63
        51.2385763 65.2385763 49 68 49zM68 74L81.4361148 74C82.3276335 74 82.6509198 74.0928256 82.9768457 74.2671327
        83.3027716 74.4414398 83.5585602 74.6972284 83.7328673 75.0231543 83.9071744 75.3490802 84 75.6723665 84
        76.5638852L84 81.4361148C84 82.3276335 83.9071744 82.6509198 83.7328673 82.9768457 83.5585602 83.3027716
        83.3027716 83.5585602 82.9768457 83.7328673 82.6509198 83.9071744 82.3276335 84 81.4361148 84L68 84C65.2385763
        84 63 81.7614237 63 79L63 79C63 76.2385763 65.2385763 74 68 74zM68 94L81.4361148 94C82.3276335 94 82.6509198
        94.0928256 82.9768457 94.2671327 83.3027716 94.4414398 83.5585602 94.6972284 83.7328673 95.0231543 83.9071744
        95.3490802 84 95.6723665 84 96.5638852L84 101.436115C84 102.327634 83.9071744 102.65092 83.7328673 102.976846
        83.5585602 103.302772 83.3027716 103.55856 82.9768457 103.732867 82.6509198 103.907174 82.3276335 104
        81.4361148 104L68 104C65.2385763 104 63 101.761424 63 99L63 99C63 96.2385763 65.2385763 94 68 94zM68
        114L81.4361148 114C82.3276335 114 82.6509198 114.092826 82.9768457 114.267133 83.3027716 114.44144 83.5585602
        114.697228 83.7328673 115.023154 83.9071744 115.34908 84 115.672366 84 116.563885L84 121.436115C84 122.327634
        83.9071744 122.65092 83.7328673 122.976846 83.5585602 123.302772 83.3027716 123.55856 82.9768457 123.732867
        82.6509198 123.907174 82.3276335 124 81.4361148 124L68 124C65.2385763 124 63 121.761424 63 119L63 119C63
        116.238576 65.2385763 114 68 114zM68 134L81.4361148 134C82.3276335 134 82.6509198 134.092826 82.9768457
        134.267133 83.3027716 134.44144 83.5585602 134.697228 83.7328673 135.023154 83.9071744 135.34908 84 135.672366
        84 136.563885L84 141.436115C84 142.327634 83.9071744 142.65092 83.7328673 142.976846 83.5585602 143.302772
        83.3027716 143.55856 82.9768457 143.732867 82.6509198 143.907174 82.3276335 144 81.4361148 144L68
        144C65.2385763 144 63 141.761424 63 139L63 139C63 136.238576 65.2385763 134 68 134z"
      />
      <rect
        className={styles.neutral10}
        width="2"
        height="20"
        x="85"
        y="44"
        fill="currentColor"
      />
    </g>
  );
};

export default LookupSelect;
