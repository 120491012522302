import React from 'react';

const IconAndroid = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32">
      <path
        d="M19.45 7.859H18.3V6.688h1.15v1.17zm-5.75 0h-1.15V6.688h1.15v1.17zm6.36-3.326l1.506-1.535a.61.61 0 0 0
        0-.831.583.583 0 0 0-.816 0L19.047 3.9A6.844 6.844 0 0 0 16 3.174c-1.104
        0-2.139.27-3.059.738l-1.714-1.745a.569.569 0 0 0-.805 0 .587.587 0 0 0 0 .831l1.507 1.535A7.05 7.05 0 0 0 9.1
        10.2h13.8c0-2.342-1.15-4.392-2.84-5.668zm5.715 6.84c-.953 0-1.725.786-1.725 1.756v8.198c0 .97.772 1.757 1.725
        1.757.953 0 1.725-.786 1.725-1.757V13.13c0-.97-.772-1.757-1.725-1.757zm-19.55 0c-.953 0-1.725.786-1.725
        1.756v8.198c0 .97.772 1.757 1.725 1.757.953 0 1.725-.786 1.725-1.757V13.13c0-.97-.772-1.757-1.725-1.757zM9.1
        23.083a1.16 1.16 0 0 0 1.15 1.171h1.15v4.1c0 .97.772 1.756 1.725 1.756.953 0 1.725-.786
        1.725-1.757v-4.099h2.3v4.1c0 .97.772 1.756 1.725 1.756.953 0 1.725-.786 1.725-1.757v-4.099h1.15a1.16 1.16 0 0 0
        1.15-1.17V11.371H9.1v11.712z"
      />
    </svg>
  );
};

export default IconAndroid;
