import React from 'react';
import styles from './ReleaseNotes.module.scss';

const Removed = ({ children }) => {
  return (
    <div className={styles.listItemWrapper}>
      <div className={styles.listItem + ' ' + styles.listItemRemoved}>
        REMOVED
      </div>
      <div className="uni-text">{children}</div>
    </div>
  );
};

export default Removed;
