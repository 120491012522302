import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Icon = () => {
  return (
    <g className={'illustrationLayer'} fill="none" fillRule="evenodd">
      <path
        className={styles.neutral7}
        fill="currentColor"
        fillRule="nonzero"
        d="M67.6376184,82.4323541 C66.0221151,82.444565 65.3048452,82.453375 64.4086691,82.477082
        C63.8214033,82.4912329 63.19478,82.5512687 62.4525144,82.6581991 C62.0878246,82.6967721 61.7123913,82.7440975
        61.2257438,82.8110997 C60.9611026,82.8478097 60.9611026,82.8478097 60.6952105,82.8852214 C60.3395943,82.9352241
        60.1818615,82.957159 59.9734407,82.9851579 C59.296811,83.0660592 58.6050131,83.1570354 57.8021475,83.2689855
        C56.606376,83.475946 55.9857085,83.576435 55.2743739,83.670265 C55.0785862,83.7003862 54.8746129,83.7305339
        54.6465459,83.7632327 C54.3339724,83.8076708 54.3339724,83.8076708 54.0220074,83.8512005 C53.3455778,83.9455104
        53.1158878,83.9781275 52.7689709,84.0304891 C52.4632533,84.0755422 52.2011754,84.1136054 51.6414995,84.1945584
        C51.5297532,84.210739 51.5297532,84.210739 51.4180932,84.2269289 C50.9716867,84.2918145 50.7904242,84.3183634
        50.5196332,84.3586797 C49.8678629,84.4508492 49.477502,84.4974151 48.1375226,84.6492819 C47.9552843,84.6665466
        47.8115034,84.6817969 47.4734798,84.7188907 C47.2371674,84.7448164 47.2371674,84.7448164 46.9981635,84.7698189
        C46.531951,84.8174165 46.168375,84.8438627 45.8242705,84.8509845 C44.9843292,84.8868794 44.150326,84.8654947
        42.9759937,84.7951167 C42.5356396,84.77818 42.0748797,84.7194983 41.6044491,84.6195519 C40.8903615,84.4381963
        40.3755227,84.0021306 40.1713007,83.3178376 C40.136268,83.2057327 40.0962082,83.1005757 40.0526325,83.0071992
        L40.0036341,82.786336 L40.0036341,82.1228315 L40.0514274,81.9045691 C40.1016715,81.7953429
        40.1426701,81.7014411 40.233181,81.4910614 C40.3924443,81.1220133 40.4863708,80.922302 40.6237962,80.6834592
        C41.4752092,79.289553 42.6339012,78.1211729 44.2848237,76.866154 C45.4610579,75.9984402 46.3593178,75.3448283
        47.2342336,74.7240398 C48.2651748,73.9620397 48.9509752,73.5634401 49.7550893,73.342668 C49.863615,73.3028752
        49.9758858,73.2701479 50.1045309,73.2389055 C50.1800219,73.220572 50.2462359,73.2059991 50.3746627,73.1785537
        C50.4616596,73.1597205 50.4616596,73.1597205 50.6623423,73.1268818 L51.7815066,73.1268818
        C51.9528567,73.1470456 51.9528567,73.1470456 52.0421167,73.1624547 C52.1267269,73.1770233 52.1267269,73.1770233
        52.2198669,73.191482 C52.8230511,73.3025949 53.1552733,73.4542616 53.3678095,73.9032251 C53.5949913,74.3575887
        53.5203056,74.8251859 53.2470967,75.2505403 C52.8200507,76.0322517 52.3387773,76.498135 51.1788487,77.4132677
        C50.5241121,77.9210307 50.0057779,78.3004743 49.0047511,79.0179069 C48.7787496,79.1798413 48.7787496,79.1798413
        48.5530729,79.3417345 C48.3814603,79.4649471 48.2281687,79.5753832 48.0854911,79.6787024 C48.1807085,79.6641285
        48.2875966,79.64779 48.4096688,79.6291411 C49.2701212,79.4976907 49.7024637,79.4297374 50.1986212,79.3457113
        C50.5870469,79.2793138 50.8961689,79.2251343 51.5906871,79.1023962 C52.2871304,78.9793179 52.5975458,78.9249117
        52.9859824,78.8585188 C54.6896668,78.5585743 55.4457639,78.4265574 56.3816357,78.2672824 C57.4357876,78.0778645
        58.4851689,77.902295 59.5029167,77.7457202 C60.8876035,77.5289866 61.5028926,77.4378326 62.2678352,77.3438968
        C62.4988984,77.3143433 62.4988984,77.3143433 62.7301818,77.2843021 C63.2839586,77.2123315 63.4873271,77.1864582
        63.7753115,77.1524761 C64.352481,77.0843702 64.8389193,77.0389791 65.3078938,77.0129311 C65.8519383,76.9800693
        66.414124,76.9633682 67.1239096,76.9550595 C67.3575027,76.952325 67.5445858,76.9509013 67.9631559,76.9482875
        C68.439007,76.9453146 68.6506075,76.9436131 68.9207093,76.9400346 C69.1423611,76.9267223 69.1949846,76.9240466
        69.2081504,76.9270902 C70.0507786,76.8357209 70.7237223,76.8731066 71.3698861,77.0939883 C72.5597719,77.4845614
        73.4722824,78.265059 74.0197841,79.3780536 C74.7024022,80.7951343 74.6956585,82.2989703 74.0415168,83.8160511
        C73.5697445,84.864434 72.9037846,85.7735815 71.8575558,86.9111306 C70.5682596,88.2690878 69.0701492,89.4949951
        67.2744329,90.706854 C65.5352995,91.9048208 64.1191238,92.8535197 60.9702759,94.9431314 C60.708678,95.116731
        60.5317435,95.2341629 60.3667652,95.3436827 C60.4139206,95.3233463 60.4608982,95.3031023 60.5077268,95.2829385
        C64.3472259,93.5862121 67.8563852,92.2273211 71.2805727,91.1459998 C75.341603,89.8547499 78.3771809,89.153814
        81.4709711,88.9246473 C82.7968985,88.8219347 83.8847168,88.7818572 84.9480185,88.811394 C86.4414028,88.8550601
        87.6841939,89.5800216 88.594822,90.8677973 C89.2972979,91.8477945 89.582863,92.9677448 89.4820827,94.2227977
        C89.4171358,94.9743262 89.2252998,95.6304927 88.8729625,96.2492238 C88.3358708,97.2006435 87.5893371,97.9962913
        86.5224311,98.8556434 C85.832309,99.4315068 85.1842756,99.9562396 84.0713877,100.846228 C83.8255998,101.04274
        83.8255998,101.04274 83.5801137,101.239007 C83.2122935,101.53325 83.0615936,101.653985 82.8444742,101.828528
        C82.3197918,102.247546 81.7645907,102.684523 81.0930423,103.208239 C80.7000245,103.514739 79.3253287,104.582572
        79.3478384,104.565057 C79.0887834,104.7686 78.843133,104.960721 78.3401909,105.35369 C77.4359912,106.060596
        76.9099811,106.476826 76.3493928,106.933434 C75.138029,107.920848 74.3284836,108.598607 73.5444977,109.29319
        C73.2183566,109.579833 73.2183566,109.579833 72.8953902,109.863614 C72.054747,110.602834 71.5286559,111.073937
        70.9975139,111.569321 C70.366423,112.16372 69.7088739,112.823221 68.9982713,113.56885 C68.5120924,114.065832
        68.0660073,114.550619 67.3279883,115.373663 C67.1496999,115.572629 67.1242873,115.600989 66.9890601,115.751875
        C67.7122868,115.654302 68.4352319,115.565771 69.1313748,115.489963 C70.1819032,115.382522 70.6438949,115.332219
        71.2038137,115.259759 C71.5645938,115.212992 71.8529051,115.176619 72.501207,115.095581 C73.1479382,115.01474
        73.4351972,114.9785 73.7990161,114.931364 C74.1105303,114.894118 74.3592153,114.868543 74.9176166,114.814212
        C75.4683864,114.760623 75.7120018,114.735569 76.016517,114.69917 C76.259063,114.671665 76.472437,114.645583
        76.8813893,114.594241 C77.6408785,114.49889 77.9325606,114.464273 78.3213458,114.42709 C78.6296855,114.393656
        78.9566753,114.362074 79.3387217,114.328165 C79.6005613,114.304925 79.8477466,114.283989 80.3482263,114.242033
        C80.5692574,114.222332 80.7454892,114.205669 81.1455571,114.167097 C81.2256806,114.159368 81.2256806,114.159368
        81.3058909,114.151644 C81.9608731,114.08863 82.3469901,114.054641 82.7649275,114.025989 C83.0934562,113.999857
        83.4360051,113.984176 83.8563482,113.97355 C84.0224622,113.96935 84.1561794,113.96675 84.452592,113.961559
        C84.6882431,113.957421 84.6882431,113.957421 84.9013483,113.953344 C85.8092148,113.898653 86.5089866,114.065592
        87.0967833,114.590094 C87.1473513,114.635605 87.1950885,114.672891 87.3110145,114.759162 C87.452896,114.864748
        87.5177417,114.915397 87.5853761,114.976777 C87.7205232,115.088834 87.7205232,115.088834 87.8581735,115.201754
        C89.0934857,116.214815 89.5905511,116.740311 90.0036341,117.840162 L90.0036341,118.287828
        L89.8926528,118.609712 C89.8142824,118.711987 89.8142824,118.711987 89.7914916,118.742057
        C89.6206098,118.967255 89.5173722,119.086515 89.3608412,119.21456 C89.2615202,119.294371 89.1744651,119.370751
        89.0103115,119.519684 C88.5200195,119.964525 88.2745792,120.153344 87.8971555,120.330875 C86.7197918,120.861078
        85.4530702,121.210588 84.0094229,121.433355 C83.4216864,121.529033 82.9164882,121.598461 81.9475787,121.722287
        C81.3024464,121.818159 80.9464271,121.871064 80.6600584,121.913216 C80.1706521,121.985255 79.7631,122.043918
        79.3721381,122.098207 C79.1652586,122.128299 78.9488787,122.157571 78.7028298,122.189094 C78.520426,122.212463
        78.3699407,122.231114 78.0395718,122.271628 C77.4039891,122.349631 77.0998075,122.388959 76.739655,122.4423
        C76.3240711,122.500742 76.0805694,122.536272 75.2021693,122.665649 C74.4852281,122.771245 74.0874111,122.828844
        73.643281,122.890336 C73.1987406,122.9505 72.8576533,122.992835 72.0419219,123.091153 C71.2640075,123.184913
        70.9000562,123.230256 70.4593067,123.290351 C69.9464753,123.357387 69.5013304,123.419203 68.6403567,123.541387
        C67.664758,123.679838 67.3217489,123.727786 66.8229511,123.793852 C66.3250419,123.86024 65.8007346,123.926382
        65.1904448,124.000425 C64.7678576,124.051695 64.3735621,124.098531 63.5894945,124.190488 C63.4884591,124.207908
        63.4062798,124.220847 63.2277818,124.248067 C63.0525418,124.274807 62.9781728,124.286516 62.7972816,124.309998
        L61.205782,124.303737 C61.0889011,124.285475 61.0069579,124.271464 60.817735,124.237887 C60.6338844,124.204737
        60.6338844,124.204737 60.4803923,124.177305 C60.3729964,124.160692 60.295563,124.150937 60.105807,124.128824
        C59.7429274,124.086537 59.573195,124.060889 59.3463485,124.002707 C57.9076328,123.598068 56.8885858,122.690728
        56.3274657,121.310152 C56.0271672,120.587559 55.8854726,119.867841 55.9281682,119.178105 C55.9688705,118.168685
        56.3016516,117.212466 56.902293,116.201895 C57.9453582,114.415005 59.0637356,112.829966 60.3066975,111.408358
        C61.3626042,110.179352 62.3659625,109.082797 63.3636282,108.077682 C63.9775015,107.437574 64.5173975,106.924454
        65.6331785,105.898676 L65.7791608,105.764476 C66.0498886,105.515487 66.1646968,105.40965 66.3234624,105.262451
        C67.2660571,104.407313 67.9935599,103.76983 68.7155596,103.176508 C69.5669387,102.471678 70.4060151,101.803145
        71.6800625,100.806534 C72.4296334,100.230198 73.0312293,99.7681747 74.3863342,98.7278619 C75.2169703,98.0901833
        75.7644428,97.6698221 76.2375612,97.3063618 C75.6268629,97.4442407 75.2448335,97.5376558 74.8135344,97.6555647
        C71.6760544,98.5092511 68.4628599,99.6268009 64.7753581,101.105363 C63.9518665,101.437599 63.1075756,101.785702
        62.0042678,102.2461 C61.6687324,102.386115 61.3689688,102.511499 60.7329756,102.777676 C59.3853766,103.341566
        58.6531192,103.646024 57.8338151,103.981055 C56.1615109,104.677043 54.8999073,105.196954 53.6655385,105.696358
        C52.8659005,106.007328 50.0509961,107.130998 49.4360425,107.370975 C49.276163,107.433289 49.276163,107.433289
        49.1158657,107.495429 C47.5494772,108.100625 45.9803285,108.191905 44.4572665,107.72327 C42.6482608,107.166653
        41.7750173,105.425892 42.3572772,103.659742 C42.568443,103.033526 42.8469426,102.497576 43.3988417,101.570043
        C44.1099737,100.416428 44.9951565,99.3222442 46.0482064,98.2691943 C46.8388145,97.4785862 47.4241889,96.9203126
        48.0155226,96.4073603 C49.1111414,95.4456502 49.6140153,95.0313584 50.2981328,94.5683702 C51.4441302,93.794577
        52.4217193,93.16325 54.4526757,91.8723274 C54.5976782,91.7801634 54.5976782,91.7801634 54.7426527,91.6880118
        C55.3007086,91.3332672 55.6515647,91.1097516 56.0168947,90.8758796 C56.4390368,90.6061082 56.8802298,90.3256348
        57.370173,90.0153858 C57.8872756,89.6879384 58.3647926,89.3865593 59.3579298,88.7602246 C61.5519937,87.3760176
        62.7639239,86.6029829 64.0925029,85.7318512 C66.0233175,84.4581202 67.160145,83.5659543 68.1482542,82.4284127
        C68.0039065,82.4295648 67.8379479,82.43084 67.6376184,82.4323541 Z"
      />
      <path
        className={styles.action}
        fill="currentColor"
        d="M146.230769,69 L162.384615,69 C173.216923,69 182,77.7830769 182,88.6153846 C182,96.51 177.336154,103.315385
        170.613846,106.428462 L173.290769,115.405385 C173.473077,116.014615 173.124615,116.656154 172.515385,116.838462
        C172.169231,116.942308 171.8,116.877692 171.509231,116.67 L159.806923,108.230769 L146.230769,108.230769
        C135.398462,108.230769 126.615385,99.45 126.615385,88.6153846 C126.615385,77.7830769 135.398462,69
        146.230769,69 Z M122,108.230769 C122,105.103846 123.036154,102.219231 124.783077,99.9023077
        C128.844615,107.598462 136.923846,112.846154 146.230769,112.846154 L158.318462,112.846154
        L161.636923,115.239231 C159.23,119.330769 154.783077,122.076923 149.692308,122.076923 L137.493846,122.076923
        L128.422308,128.286923 C128.129231,128.487692 127.762308,128.543077 127.423077,128.436923
        C126.816154,128.245385 126.479231,127.596923 126.668462,126.99 L128.814615,120.159231 C124.734615,117.752308
        122,113.31 122,108.230769 Z"
      />
      <path
        className={styles.neutral6}
        fill="currentColor"
        d="M211,81.4157143 C211,77.8714286 213.877857,75 217.445714,75 L264.554286,75 C268.113571,75 271,77.8778571
        271,81.4157143 L271,111.441429 C271,114.983571 268.122143,117.857143 264.554286,117.857143
        L217.445714,117.857143 C213.886429,117.857143 211,114.977143 211,111.441429 L211,81.4157143 Z
        M232.428571,86.9353822 C232.428571,85.7546679 233.261074,85.3710965 234.278831,86.0782394
        L248.836189,96.1603822 C249.85852,96.8696679 249.805917,97.9518108 248.73327,98.5710965 L234.38175,106.855382
        C233.304529,107.478954 232.428571,107.016096 232.428571,105.843954 L232.428571,86.9353822 Z"
      />
    </g>
  );
};

export default Icon;
