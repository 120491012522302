import React from 'react';
import { Text } from 'hudl-uniform-ui-components';

const Paragraph = ({ children, className }) => {
  const paragraphClass = className ? className : 'uni-margin--one--btm';

  return <Text className={paragraphClass}>{children}</Text>;
};

export default Paragraph;
