import React from 'react';
import classNames from 'classnames';
import { Text } from 'hudl-uniform-ui-components';
import styles from './DontDo.module.scss';

const DontDoItem = ({ type, text, img }) => {
  const introClasses = classNames(styles.intro, {
    [styles.dont]: type === 'dont',
    [styles.do]: type === 'do',
  });

  const textContainer = classNames(styles.textContainer, {
    [styles.borderDont]: type === 'dont',
    [styles.borderDo]: type === 'do',
  });

  const intro = type === 'dont' ? "Don't" : 'Do';

  return (
    <div className={styles.itemContainer}>
      <div className={'uni-bg--level0 ' + styles.imageContainer}>
        <img
          src={
            img && type
              ? require('../../imgs/' + type + '/' + img + '.png')
              : ''
          }
          alt={img}
        />
      </div>

      <div className={textContainer}>
        <Text level="small">
          <span className={introClasses}>{intro}</span>
          {text}
        </Text>
      </div>
    </div>
  );
};

export default DontDoItem;
