import React from 'react';
import { Location } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { DesignCodeLink, SubNavSection } from 'docComponents';
import styles from './SubNav.module.scss';

const getSubnavDataForRoute = (subnavData, route) => {
  const routeNode = subnavData.allPagesYaml.edges.find(
    subnavItem => subnavItem.node.path === route
  );

  return routeNode ? routeNode.node : null;
};

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allPagesYaml {
          edges {
            node {
              tierOne
              path
              designCode

              tierTwo {
                name
                path
                designCode

                tierThree {
                  name
                  path
                  designCode
                  isMobileOnly
                }
              }
            }
          }
        }
      }
    `}
    render={subnavData => {
      return (
        <Location>
          {({ location }) => {
            const route = location.pathname.split('/')[1];

            const routeSubnavData = getSubnavDataForRoute(subnavData, route);

            if (!routeSubnavData) return <div className={styles.subNav} />;

            const TierOneLink = routeSubnavData.designCode
              ? DesignCodeLink
              : Link;

            return (
              <div className={styles.subNav}>
                <div className={styles.subNavInner}>
                  <h4>
                    <TierOneLink
                      className={styles.subNavTierOne}
                      to={'/' + routeSubnavData.path}>
                      {routeSubnavData.tierOne}
                    </TierOneLink>
                  </h4>

                  {routeSubnavData.tierTwo &&
                    routeSubnavData.tierTwo.map(tierTwo => {
                      return (
                        <SubNavSection
                          navData={tierTwo}
                          baseRoute={route}
                          key={route + tierTwo.path}
                        />
                      );
                    })}
                </div>
              </div>
            );
          }}
        </Location>
      );
    }}
  />
);
