import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchRectangleShort = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M27.8911924,64 L25,65.6692307 M25,70.2894732 L35.8936872,64 M43.8986731,64 L25,74.911154 M25,79.5313966
      L51.901168,64 M59.9061539,64 L25,84.1530773 M25,88.7733199 L67.9086487,64 M75.9136346,64 L25,93.3950007
      M25,98.0152432 L83.9161295,64 M91.9211154,64 L25,102.636924 M25,107.257167 L99.9236102,64 M107.928596,64
      L25,111.878847 M25,116.49909 L115.931091,64 M123.936077,64 L25,121.120771 M25,125.741013 L131.938572,64
      M139.943558,64 L29.092306,128 M37.0948008,128 L147.946053,64 M155.951038,64 L45.0997867,128 M53.1022816,128
      L163.953533,64 M171.958519,64 L61.1072675,128 M69.1097623,128 L179.961014,64 M187.966,64 L77.1147482,128
      M85.1172431,128 L195.968495,64 M203.973481,64 L93.122229,128 M101.124724,128 L211.975976,64 M219.980961,64
      L109.12971,128 M117.132205,128 L227.983456,64 M235.988442,64 L125.137191,128 M133.139685,128 L243.990937,64
      M251.995923,64 L141.144671,128 M149.147166,128 L259.998418,64 M268.003404,64 L157.152152,128 M165.154647,128
      L276.005899,64 M280,66.3156852 L173.159633,128 M181.162128,128 L280,70.9359278 M280,75.5576086 L189.167114,128
      M197.169608,128 L280,80.1778511 M280,84.7995319 L205.174594,128 M213.177089,128 L280,89.4197744 M280,94.0414552
      L221.182075,128 M229.18457,128 L280,98.6616978 M280,103.283379 L237.189556,128 M245.192051,128 L280,107.903621
      M280,112.525302 L253.197037,128 M261.199531,128 L280,117.145544 M280,121.767225 L269.204517,128 M277.207012,128
      L280,126.387468"
    />
  );
};

export default HatchRectangleShort;
