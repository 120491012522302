import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchRectangleSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M85,63.3742271 L90.8443327,60 M98.8493186,60 L85,67.9959078 M85,72.6161504 L106.851813,60 M114.856799,60
      L85,77.2378312 M85,81.8580737 L122.859294,60 M130.86428,60 L85,86.4797545 M85,91.099997 L138.866775,60
      M146.871761,60 L85,95.7216778 M85,100.34192 L154.874256,60 M162.879242,60 L85,104.963601 M85,109.583844
      L170.881737,60 M178.886722,60 L85,114.205524 M85,118.825767 L186.889217,60 M194.894203,60 L85,123.447448
      M85,128.06769 L202.896698,60 M210.901684,60 L85,132.689371 M92.4644698,133 L218.904179,60 L219,60 M219,64.5663584
      L100.469456,133 M108.471951,133 L219,69.1866009 M219,73.8082817 L116.476936,133 M124.479431,133 L219,78.4285242
      M219,83.050205 L132.484417,133 M140.486912,133 L219,87.6704476 M219,92.2921283 L148.491898,133 M156.494393,133
      L219,96.9123709 M219,101.534052 L164.499379,133 M172.501874,133 L219,106.154294 M219,110.775975 L180.50686,133
      M188.509354,133 L219,115.396218 M219,120.017898 L196.51434,133 M204.516835,133 L219,124.638141 M219,129.259822
      L212.521821,133"
    />
  );
};

export default HatchRectangleSmall;
