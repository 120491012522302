import React from 'react';
import classNames from 'classnames';
import styles from './GoodBad.module.scss';

const GoodBad = ({ children, gridLayout }) => {
  const GoodBadClass = classNames('uni-bg--level1 ' + styles.container, {
    [styles.grid2]: gridLayout === '2',
    [styles.grid3]: gridLayout === '3',
  });

  return <div className={GoodBadClass}>{children}</div>;
};

export default GoodBad;
