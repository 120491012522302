import React from 'react';
import classNames from 'classnames';
import { Environment } from 'hudl-uniform-ui-components';
import styles from './DataTable.module.scss';

const DataTable = ({ children, env }) => {
  const dataTableStyles = classNames(styles.dataTable, {
    [styles.dataTable__light]: env === 'light',
    [styles.dataTable__dark]: env === 'dark',
    'uni-bg--level0': env,
  });

  return env ? (
    <Environment env={env}>
      <table className={dataTableStyles}>{children}</table>
    </Environment>
  ) : (
    <table className={dataTableStyles}>{children}</table>
  );
};

export default DataTable;
