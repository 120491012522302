import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const Square = () => {
  return (
    <rect
      className={'shapeLayer ' + styles.shapeBg}
      fill="currentColor"
      width="144"
      height="144"
      x="80"
      y="24"
      fillRule="evenodd"
    />
  );
};

export default Square;
