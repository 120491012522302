import React from 'react';

import iconList from './svgs';

const iconTypes = Object.keys(iconList);

const iconMap = iconTypes.reduce((prev, curr) => {
  prev[curr.toLowerCase()] = iconList[curr];

  return prev;
}, {});

const SvgIllustration = ({
  className,
  style,
  hatchingType,
  shapeType,
  illustrationType,
}) => {
  const svgViewBox = '0 0 304 192';
  const Hatching = hatchingType ? iconMap[hatchingType.toLowerCase()] : null;
  const Shape = shapeType ? iconMap[shapeType.toLowerCase()] : null;
  const Illustration = illustrationType
    ? iconMap[illustrationType.toLowerCase()]
    : null;

  return (
    <svg
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={svgViewBox}>
      {shapeType && <Shape />}
      {hatchingType && <Hatching />}
      {illustrationType && <Illustration />}
    </svg>
  );
};

export default SvgIllustration;
