import React from 'react';

const IconFigma = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/1999/xlink"
      className={className}
      viewBox="0 0 32 32"
      version="1.1">
      <g
        id="figma"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="figma-1"
          transform="translate(7.000000, 3.000000)"
          fill="rgba(19, 41, 63, 0.4)"
          fillRule="nonzero">
          <path
            d="M4.5,27 C6.984,27 9,24.984 9,22.5 L9,18 L4.5,18 C2.016,18
              0,20.016 0,22.5 C0,24.984 2.016,27 4.5,27 Z"
          />
          <path d="M0,13.5 C0,11.016 2.016,9 4.5,9 L9,9 L9,18 L4.5,18 C2.016,18 0,15.984 0,13.5 Z" />
          <path d="M0,4.5 C0,2.016 2.016,0 4.5,0 L9,0 L9,9 L4.5,9 C2.016,9 0,6.984 0,4.5 Z" />
          <path
            d="M9,0 L13.5,0 C15.984,0 18,2.016 18,4.5 C18,6.984 15.984,9 13.5,9 L9,9 L9,0 Z"
            fillOpacity="0.70"
          />
          <path
            d="M18,13.5 C18,15.984 15.984,18 13.5,18 C11.016,18 9,15.984 9,13.5 C9,11.016
              11.016,9 13.5,9 C15.984,9 18,11.016 18,13.5 Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconFigma;
