import React from 'react';
import classNames from 'classnames';
import { Icon } from 'hudl-uniform-ui-components';
import styles from './SubNav.module.scss';

const SubNavTrigger = ({ triggerText, onClick, isOpen }) => {
  const SubNavTriggerIconClasses = classNames(styles.subNavTierTwoIcon, {
    [styles.subNavTierTwoIconActive]: isOpen,
  });

  return (
    <button className={styles.subNavTierTwo} onClick={onClick}>
      {triggerText}
      <span className={SubNavTriggerIconClasses}>
        <Icon type="uiexpandregionvertical" />
      </span>
    </button>
  );
};

export default SubNavTrigger;
