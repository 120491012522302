import React from 'react';
import styles from '../../SvgIllustration.module.scss';

const HatchParallelogramSmall = () => {
  return (
    <path
      className={'hatchingLayer ' + styles.hatchingBg}
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      d="M53.1022816,128 L162.221482,65 M170.226468,65 L61.1072675,128 M69.1097623,128 L178.228963,65 M186.233949,65
      L77.1147482,128 M85.1172431,128 L194.236444,65 M202.24143,65 L93.122229,128 M101.124724,128 L210.243925,65
      M218.248911,65 L109.12971,128 M117.132205,128 L226.251405,65 M234.256391,65 L125.137191,128 M133.139685,128
      L242.258886,65 M250.263872,65 L141.144671,128"
    />
  );
};

export default HatchParallelogramSmall;
